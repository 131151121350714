import { DatePipe } from "@angular/common";

import { Component, OnInit, ViewChild ,ElementRef} from "@angular/core";
import { Router } from "@angular/router";
import { ServiceService } from "src/app/service.service";
declare var $;
@Component({
  selector: "app-anouncement-management",
  templateUrl: "./anouncement-management.component.html",
  styleUrls: ["./anouncement-management.component.css"],
})
export class AnouncementManagementComponent implements OnInit {
  @ViewChild('status') status : ElementRef
  @ViewChild('startDate') sDate : ElementRef
  @ViewChild('endDate') eDate : ElementRef
  itemsPerPage: any = 10;
  currentPage: any = 1;
  totalItems: any;
  bannerId: any;
  anoncementData: any = [];
  _anoncementData: any = [];
  constructor(
    public service: ServiceService,
    private router: Router,
    private date: DatePipe
  ) {}

  ngOnInit(): void {
    this.getAnnouncementList();
  }
  pagination(e) {
    this.currentPage = e;
  }
  getAnnouncementList() {
    let url = "static/admin/get-Announcement-list";
    this.service.showSpinner();
    this.service.get(url).subscribe((res) => {
      console.log(res);
      if (res["status"] == 200) {
        this.anoncementData = res["data"][0]["promotionDetails"];
        this._anoncementData = res["data"][0]["promotionDetails"];
        this.totalItems = res["data"][0]["promotionDetails"].length;
        this.service.toasterSucc(res["message"]);
        this.service.hideSpinner();
      } else {
        this.service.toasterErr(res["message"]);
        this.service.hideSpinner();
      }
    });
  }
  viewAnouncement(id) {
    this.router.navigate(["/view-anouncemant"], { queryParams: { id: id } });
  }
  editAnouncement(id){
    
    this.router.navigate(["/edit-anouncemant"], { queryParams: { id: id } });
  }
  getBannerID(id,modal) {
    this.bannerId = id;
    switch (modal) {
      case 1:
        
        $("#blockModal").modal("show");
        break;
    case 2:
      $("#activeModal").modal("show");
       break;
     
      default:
        $("#deleteModal").modal("show");
      
        break;
    }
   
  }
  activeAnnouncement(){
    let url =
      "static/admin/Active-Announcement?announcementId=" + this.bannerId;

    this.service.showSpinner();
    this.service.post(url, {}).subscribe((res) => {
      if (res["status"] == 200) {
        this.service.toasterSucc(res["message"]);
        this.service.hideSpinner();
        this.getAnnouncementList();
        $("#activeModal").modal("hide");
      } else {
        this.service.toasterErr(res["message"]);
        this.service.hideSpinner();
      }
    });
  }
  
  blockAnnouncement(){
    let url =
      "static/admin/block-announcement?announcementId=" + this.bannerId;

    this.service.showSpinner();
    this.service.post(url, {}).subscribe((res) => {
      if (res["status"] == 200) {
        this.service.toasterSucc(res["message"]);
        this.service.hideSpinner();
        this.getAnnouncementList();
        $("#blockModal").modal("hide");
      } else {
        this.service.toasterErr(res["message"]);
        this.service.hideSpinner();
      }
    });
  }
  deleteAnnouncement() {
    var total = this.totalItems / 10;
    total = Math.floor(total);

    total = total * 10;

    if (this.totalItems - total == 1) {
      this.currentPage--;
    }

    let url =
      "static/admin/delete-Announcement?announcementId=" + this.bannerId;

    this.service.showSpinner();
    this.service.post(url, {}).subscribe((res) => {
      if (res["status"] == 200) {
        this.service.toasterSucc(res["message"]);
        this.service.hideSpinner();
        this.getAnnouncementList();
        $("#deleteModal").modal("hide");
      } else {
        this.service.toasterErr(res["message"]);
        this.service.hideSpinner();
      }
    });
  }
  
  getStartDate(startDate) {}
  search(status, startDate, endDate) {
    this.anoncementData = this._anoncementData;
    console.log(status, startDate, endDate);
    if (!status && !startDate && !endDate) {
      return;
    }
    this.currentPage = 1
    if (status && !startDate && !endDate) {
      this.anoncementData = this.anoncementData.filter((res) => {
        return res.coinName === status;
      });
    } else if (!status && startDate && !endDate) {
      this.anoncementData = this.anoncementData.filter((res) => {
        return this.date.transform(res.createdTime, "yyyy-MM-dd") === startDate;
      });
    } else if (status && startDate && !endDate) {
      this.anoncementData = this.anoncementData.filter((res) => {
        return (
          res.coinName === status &&
          this.date.transform(res.createdTime, "yyyy-MM-dd") === startDate
        );
      });
    } else if (!status && startDate && endDate) {
      this.anoncementData = this.anoncementData.filter((res) => {
        return (
          this.date.transform(res.createdTime, "yyyy-MM-dd") >= startDate &&
          this.date.transform(res.createdTime, "yyyy-MM-dd") <= endDate
        );
      });
    } else {
      this.anoncementData = this.anoncementData.filter((res) => {
        return (
          this.date.transform(res.createdTime, "yyyy-MM-dd") >= startDate &&
          this.date.transform(res.createdTime, "yyyy-MM-dd") <= endDate &&
          res.coinName === status
        );
      });
    }
    // this.anoncementData = this.anoncementData.filter((res)=>{
    //  return res.coinName === status

    // })
    this.totalItems = this.anoncementData.length;
  }
  reset() {
    console.log(this.sDate.nativeElement.value)
    this.status.nativeElement.value = ""
    this.sDate.nativeElement.value = ""
    this.eDate.nativeElement.value = ""
    this.currentPage = 1
    this.getAnnouncementList();
  }
}
