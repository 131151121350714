import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-referal-management',
  templateUrl: './referal-management.component.html',
  styleUrls: ['./referal-management.component.css']
})
export class ReferalManagementComponent implements OnInit {
  itemsPerPage = 10
  currentPage = 1
  lengthTotal : any 
  email : any 
  fromDate : any
  toDate : any

  referalData : any = [{
    referToUserName : "Tanveer Haider",
    referToEmail : "tanveer@mailinator.com",
    registraionDate : new Date(),
    referByUserName : "Gourav",
    referByEmailId : "gourav@mailinator.com",
    referalId : "VPQHCW",
    id: 1

  }]
  constructor(private router : Router) { }

  ngOnInit(): void {
  }
pagination(e){
  this.currentPage = e
}
viewReferal(id){
this.router.navigate(['/view-referal'],{queryParams : {id : id}})
}
format() {
 let number = this.email
 console.log(number);
 var l = ""
 for(let i = 0 ; i < number?.length ; i++){
   if(i == 0 ){
     l += '('+ number[i]
   }
   else if(i ==3){
     l += ') ' + number[i]
   }
   else if(i == 6){
     l += " " + number[i]
   }
   else{
     l += number[i]
   }
 }
  this.email = l
 console.log(l);
 
 
  // console.log(this.email);
 
  
}
}
