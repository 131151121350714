<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title">User Management</h1>
    </div>
    <div class="content-section">
        <div class="outer-box">
            <div class="custom_tabs common-tabs">
                <div class="tab-content">
                    <div class="tab-pane active show" id="btc">
                        <div class="tab-pane1">
                            <div class="row trassction-mainbox form-group">
                                <div [formGroup]="userForm" class="col-md-6">
                                    <div class="input-group filter_search_group">
                                        <input type="text" class="form-control" placeholder="Search by email"
                                            formControlName="searchText">
                                        <div class="input-group-append">
                                            <button class="btn btn_search_group" type="button" (click)="searchby()"
                                                style="height: 88%;"><img src="assets/img/icon-search.png"
                                                    alt="Search"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="tras-threebtn" style="float: right; margin-top: 0px;">
                                        <div class="row" style="position: relative;
                                        left: -6%;">
                                            <div class="comman-btn">
                                                <button type="submit" class="btn  btn-theme" (click)="searchby()">Search
                                                </button>
                                            </div>
                                            <div class="comman-btn">
                                                <button type="submit" class="btn  btn-theme"
                                                    (click)="reset()">Reset</button>
                                            </div>
                                        </div>
<!-- 
                                        <div class="comman-btn">
                                            <button type="submit" class="btn  btn-theme" (click)="goAdmin()">ADMIN
                                            </button>
                                        </div> -->
                                        <div class="comman-btn">
                                            <button type="submit" class="btn  btn-theme" (click)="exportAsXLSX()">EXPORT
                                                AS EXCEL
                                            </button>
                                        </div>

                                    </div>
                                </div>


                            </div>
                            <div class="table-responsive">
                                <table class="table table-bordered" aria-describedby="">
                                    <thead>
                                        <tr class="no_wrap_th">
                                            <th id="">ID</th>
                                            <th id="">User Name</th>
                                            <th id="">Email</th>
                                            <th id="">Verification Status</th>
                                            <th id="">Account Status</th>
                                            <th id="">Last Logged In Date & Time</th>
                                            <th id="">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let data of userList | paginate:{itemsPerPage: 10, currentPage: pageNumber,totalItems: staffLength}; let i=index; ">
                                            <td>{{10 * (pageNumber - 1) + i+1}}</td>
                                            <td>{{data?.firstName }} {{data?.lastName }}</td>
                                            <td>{{data?.email || 'N/A'}}</td>
                                            <td>
                                                <span>
                                                    {{data?.twoFaType || 'N/A'}}
                                                </span>
                                            </td>
                                            <td>
                                                <span>
                                                    {{data?.userStatus || 'N/A'}}
                                                </span>
                                            </td>
                                            <td>{{data?.createTime |date:"medium"}}</td>
                                            <td class="action_td_btn3">
                                                <a data-toggle="modal" class="pointer"
                                                    (click)="viewStaff(data?.userId)"><em class="fa fa-eye"></em></a>

                                                <a data-toggle="modal" class="pointer"
                                                    *ngIf="data?.userStatus=='ACTIVE'"
                                                    (click)="openModal('BLOCK', data?.userId)"><em class="fa fa-ban"
                                                        style="color: green;"></em></a>
                                                <a data-toggle="modal" class="pointer"
                                                    *ngIf="data?.userStatus=='BLOCK'"
                                                    (click)="openModal('ACTIVE', data?.userId)"><em class="fa fa-ban"
                                                        style="color: red"></em></a>
                                                <a *ngIf="data?.userStatus == 'UNVERIFIED'" data-toggle="modal">
                                                    <em class="fa fa-times text-danger border border-warning"></em></a>
                                                <a data-toggle="modal" class="pointer"
                                                    (click)="openModal('DELETE', data?.userId)"><em
                                                        class="fa fa-trash"></em></a>
                                            </td>
                                        </tr>
                                        <tr *ngIf="userList?.length==0">
                                            <td colspan="7" vertical-align="middle">
                                                <div class="no-record">
                                                    <div class="no-recordin">
                                                        <H5>No record found</H5>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            <div class="custom-pagination mt20 text-right" *ngIf="userList.length > 10 ">
                                <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- Table Responsive End -->
    </div>
</main>

<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h5 style="text-align: center; ">Delete User </h5>
                        <div class="modal-inner-content">
                            <div class="modal-body">
                                <div
                                    class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                                    <div class="w-100">
                                        <p>Are you sure you want to delete this user?</p>
                                        <div>
                                            <button type="submit" class="btn btn-info mr-2"
                                                (click)="deleteFunction()">Yes</button>
                                            <button type="button" class="btn btn-danger"
                                                data-dismiss="modal">No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- delete_modal End -->
<!-- block_modal Start -->
<div class="modal fade global-modal reset-modal" id="block">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h5 style="text-align: center; ">Block User </h5>
                        <div class="modal-inner-content">
                            <div class="modal-body">
                                <div
                                    class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                                    <div class="w-100">
                                        <p>Are you sure you want to block this user?</p>
                                        <div>
                                            <button type="submit" class="btn btn-success mr-2"
                                                (click)="performAction()">Yes</button>
                                            <button type="button" class="btn btn-danger"
                                                data-dismiss="modal">No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- active Start -->
<div class="modal fade global-modal reset-modal" id="active">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h5 style="text-align: center; ">Active User </h5>
                        <div class="modal-inner-content">
                            <div class="modal-body">
                                <div
                                    class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                                    <div class="w-100">
                                        <p>Are you sure you want to active this user?</p>
                                        <div>
                                            <button type="submit" class="btn btn-success mr-2"
                                                (click)="performAction()">Yes</button>
                                            <button type="button" class="btn btn-danger"
                                                data-dismiss="modal">No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </form>
    </div>
</div>