<main class="middle-content">
    <!-- Page Title Start -->
    <div class="page_title_block">
        <h1 class="page_title">VIEW FEEDBACK</h1>
    </div>
    <!-- Page Title End -->
    <div class="content-section">
        <div class="outer-box">
            <div class="custom_tabs common-tabs">
                <div class="tab-content">
                    <div class="form-group row">

                        <span class="col-md-3"></span>
                        <label class="col-md-3">Trade ID:</label>
                        <span class="col-md-3">{{bindData.feedBackId}}</span>
                        <span class="col-md-3"></span>
                    </div>
                    <div class="form-group row">

                        <span class="col-md-3"></span>
                        <label class="col-md-3">Date:</label>
                        <span class="col-md-3">{{bindData.createTime | date}}</span>
                        <span class="col-md-3"></span>
                    </div>
                    <div class="form-group row">
                        <span class="col-md-3"></span>
                        <label class="col-md-3">Feedback:</label>
                        <span class="col-md-3">{{bindData.feedbackStatus}}</span>
                        <span class="col-md-3"></span>
                    </div>
                    <div class="form-group row">

                        <span class="col-md-3"></span>
                        <label class="col-md-3">Customer Name:</label>
                        <span class="col-md-3">{{bindData.feedBackId}}</span>
                        <span class="col-md-3"></span>
                    </div>
                    <div class="form-group row">

                        <span class="col-md-3"></span>
                        <label class="col-md-3">Message:</label>
                        <span class="col-md-3">{{bindData.feedbackMessage}}</span>
                        <span class="col-md-3"></span>
                    </div>
                    <div class="form-group row">

                        <span class="col-md-3"></span>
                        <label class="col-md-3">Trade:</label>
                        <span class="col-md-3">{{bindData.tradeId}}</span>
                        <span class="col-md-3"></span>
                    </div>
                    &nbsp;
                </div>
            </div>
        </div>
        <!-- Table Responsive End -->
    </div>
</main>