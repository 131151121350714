<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title">Edit Institutional</h1>
       
    </div>
    <div class="content-section">
        <!-- <div class="page_title_block" style="padding-left: 21%;padding-top: 7%;">
            <h1 class="page_title">Add Banner</h1>
        </div> -->
        <div class="order-view setting-page">
            <div class="  mb40">
                <form [formGroup]="addAdvertisementForm" >
                    <div class="input-style mb20 mt20 ">
                        <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-4 textalignright">Institutional Title
                                <span>:</span></label>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group ">
                                    <input type="text" class="form-control" placeholder="Enter Institutional title"
                                        formControlName="title" (keypress)="mainService.preventSpace($event)"
                                        maxlength="50">
                                    <div class="text-danger">
                                        <span
                                            *ngIf="addAdvertisementForm.get('title').hasError('required') && (addAdvertisementForm.get('title').dirty || addAdvertisementForm.get('title').touched )">
                                            *Please enter Institutional title.
                                        </span>
  
                                    </div>
                                </div>
                            </div>
                        </div>
  
                        <div class="form-group row view-label-align">
                            <label class="col-md-4 textalignright">Upload Image
                                <span>:</span></label>
                            <div class="col-md-4 textaligncenter">
                                <div class="user-profile hoverable ">
                                    <div  *ngIf="imgSrc" class="image-boxs" > 
                                        <img  class="image-boxs" src= {{imgSrc}} id="sub-admin-image" alt="" > 
                                    </div>
                                <label class="upload-label">
                                    <input type="file" formControlName="image" name="image"
                                        accept="image/jpg,image/jpeg,image/png" id="image"
                                        (change)="ValidateFileUpload($event)" >
                                </label>
                            
                                </div>
                            </div>
                        </div>
                        <!-- <div class="form-group row align-items-baseline view-label-align" style="margin-top: 6%;">
                            <label class="col-md-4 textalignright">Activate
                                <span>:</span></label>
                            <div class="col-md-8 textaligncenter">
                                <div>
                                    <input type="checkbox" formControlName = "isActive">
  
                                </div>
                            </div>
                        </div> -->
  
                        <!-- <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-4 textalignright ">Description
                            </label>
                        </div> -->
  
                        <!-- <div class="form-group row  view-label-align">
  
                            <div class="col-md-12 textaligncenter">
                                <div class="form-group " *ngIf="this.addAdvertisementForm.value.description">
                                 
  
                                    <ck-editor formControlName="description"></ck-editor>
                                    <div
                                      *ngIf="addAdvertisementForm.get('description').invalid && (addAdvertisementForm.get('description').dirty || addAdvertisementForm.get('description').touched)"
                                      class="text-danger">
                                      <span *ngIf="addAdvertisementForm.get('description').hasError('required')">
                                        *Please enter description.</span>
                                    </div>
                                  </div>
                            </div>
                        </div> -->
                    </div>
  
                    
                    <div class="mt40 mb40 text-center">
                        <button class="btn btn-large  max-WT-150 btn-secondary" routerLink="/institutional-list">Cancel</button>
                        <button class="btn btn-large  max-WT-150 btn-primary ml-2"
                        [disabled]="!addAdvertisementForm.valid" (click)="updateAdvertisement()">Update</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
  </main>