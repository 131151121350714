import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-view-institutional',
  templateUrl: './view-institutional.component.html',
  styleUrls: ['./view-institutional.component.css']
})
export class ViewInstitutionalComponent implements OnInit {
  promId : number
  description: any;
  userData: any=[];
 
  constructor(private mainService : ServiceService,private activatedRoute : ActivatedRoute) { 
    this.activatedRoute.queryParams.subscribe((res)=>{
      this.promId = res.id
    })
  }

  ngOnInit(): void {
    this.getPromotion()
    
  }

  getPromotion(){
    let url="static/admin/get-promotion-By-Id?promotionId="+ this.promId
    this.mainService.showSpinner()
     this.mainService.get(url).subscribe((res)=>{
       if(res['status']==200){
         this.mainService.hideSpinner()
         this.userData=res['data']
         this.mainService.toasterSucc(res['message'])

       }
       else{
        this.mainService.hideSpinner()
        this.mainService.toasterErr(res['message'])
       }
     })
    
  }

}