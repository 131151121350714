
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title" style="text-transform: uppercase;">VIEW announcement</h1>
            <hr style="background: black; opacity: 0.5;">
        </div>
        <div class="content-section">
            <div class="order-view  setting-page text-center">
                <div class="  mb40">
                    <form>
                        <div class="input-style mb20 mt20 ">
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-4 text-left ">Anouncement Id
                                    </label>
                                    <div class="col-md-1">
                                        <span>:</span>
                                    </div>
                                    
                                <div class="col-md-7 text-left">
                                    <div class="form-group">

                                        <label style="font-weight: 400;">{{anouncement?.announcementId}}</label>
                                    </div>
                                </div>
                            </div>


                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-4 text-left ">Anouncement Title
                                    </label>
                                    <div class="col-md-1">
                                        <span>:</span>
                                    </div>
                                    
                                <div class="col-md-7 text-left">
                                    <div class="form-group">

                                        <label style="font-weight: 400;">{{anouncement?.title}}</label>
                                    </div>
                                </div>
                            </div>


                            <div class="form-group row view-label-align">
                                <label class="col-md-4 text-left ">Anouncement Description
                                   </label>
                                    <div class="col-md-1">
                                        <span>:</span>
                                    </div>
                                <div class="col-md-7 text-left">
                                    <div class="form-group">
                                        <label style="font-weight: 400;">{{anouncement?.description}}</label>

                                    </div>
                                </div>
                            </div>
                            <div class="form-group row view-label-align">
                                <label class="col-md-4 text-left ">Anouncement Tag
                                   </label>
                                    <div class="col-md-1">
                                        <span>:</span>
                                    </div>
                                <div class="col-md-7 text-left">
                                    <div class="form-group">
                                        <label style="font-weight: 400;">{{anouncement?.coinName}}</label>

                                    </div>
                                </div>
                            </div>
                            <div class="form-group row view-label-align">
                                <label class="col-md-4 text-left ">Anouncement Status
                                   </label>
                                    <div class="col-md-1">
                                        <span>:</span>
                                    </div>
                                <div class="col-md-7 text-left">
                                    <div class="form-group">
                                        <label style="font-weight: 400;">{{anouncement?.announcementStatus}}</label>

                                    </div>
                                </div>
                            </div>


                            <div class="form-group row align-items-baseline view-label-align">
                              <label class="col-md-4 text-left ">Created At
                                 </label>
                                 <div class="col-md-1">
                                    <span>:</span>
                                </div>
                              <div class="col-md-7 text-left">
                                  <div class="form-group">

                                      <label style="font-weight: 400;">{{anouncement?.createdTime }}</label>
                                  </div>
                              </div>
                          </div>
                          
                        </div>

                        <div class="text-center mt40 mb40">
                            <button class="btn btn-large  max-WT-150 btn-secondary"
                                routerLink="/anouncement-management">Back</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </main>



