<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Advertisement Details</h1>
        </div>
        <div class="content-section">
            <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block input-style">

                        <div class="form-group row align-items-baseline">
                            <label class="col-md-5">User Id </label>
                            <div class="col-md-1"> </div>
                            <div class="col-md-6">
                                <span>{{advertisementData?.userId}}</span>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-5">Advertisement Id </label>
                            <div class="col-md-1"> </div>
                            <div class="col-md-6">
                                <span>{{advertisementData?.peerToPeerExchangeId}}</span>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-5">Username </label>
                            <div class="col-md-1"> </div>
                            <div class="col-md-6">
                                <span><a>{{advertisementData?.userName}}</a></span>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-5">Created On</label>
                            <div class="col-md-1"> </div>
                            <div class="col-md-6">
                                <span><a>{{advertisementData?.creationTime | date:"d MMM, y h:mm a"}}</a></span>
                            </div>
                        </div>

                        <div class="form-group row align-items-baseline">
                            <label class="col-md-5">Payment Method </label>
                            <div class="col-md-1"> </div>
                            <div class="col-md-6">
                                <span>{{advertisementData?.paymentType}}</span>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-5">Location </label>
                            <div class="col-md-1"> </div>
                            <div class="col-md-6">
                                <span>{{advertisementData?.country}}</span>
                            </div>
                        </div>

                        <div class="form-group row align-items-baseline">
                            <label class="col-md-5">Price </label>
                            <div class="col-md-1"> </div>
                            <div class="col-md-6">
                                <span class="word-break"> {{advertisementData?.price}}</span>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-5">Ad. Limit </label>
                            <div class="col-md-1"> </div>
                            <div class="col-md-6">
                                <span class="word-break"> Min- {{advertisementData?.minValue}} <br> Max-
                                    {{advertisementData?.maxValue}}</span>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-5">Margin </label>
                            <div class="col-md-1"> </div>
                            <div class="col-md-6">
                                <span class="word-break"> {{advertisementData?.margin || 'N/A'}}</span>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-5">Terms of Trade </label>
                            <div class="col-md-1"> </div>
                            <div class="col-md-6">
                                <span class="word-break">
                                    {{advertisementData?.termsOfTrade || 'N/A'}}</span>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-5">Security Options </label>
                            <div class="col-md-1"> </div>
                            <div class="col-md-6">
                                <div>
                                    <input type="checkbox" checked disabled>
                                    Identified Only</div>
                                <div>
                                    <input type="checkbox" checked disabled>
                                    SMS Verificaition
                                    required</div>
                            </div>
                        </div>
                        <div class="text-left mt40">
                            <button class="btn  btn-theme" type="button" style="margin-left: 39%;"
                                (click)="cancel()">Back</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>