<main class="middle-content">
    <!-- Page Title Start -->
    <div class="page_title_block">
        <h1 class="page_title">Withdraw Limit Management</h1>
    </div>
    <!-- Page Title End -->
    <div class="content-section">

        <div class="order-view">
            <div class="custom_tabs common-tabs">
               

                <div class="tab-content" style="margin-left: 13px;">


                    <div class="tab-pane active show" >
                        <div class="global-table no-radius p0">
                            <div class="table-responsive">
                                <div id="table-scroll" class="table-scroll">
                                    <div class="table-wrap">
                                        <table class="main-table" aria-describedby="">
                                            <thead>
                                                <th id="" class="text-center">Coin</th>
                                                <th id="" class="text-center">Withdraw Fee</th>
                                                <th id="" class="text-center">Action</th>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of feecoinArry ;let i = index">
                                                    <td id="" class="fixed-side">{{item.coinShortName}}</td>
                                                    <td>
                                                        <input type="text" class="form-control" name="{{i}}"
                                                            [(ngModel)]="item.withdrawlFee" placeholder="Withdraw Fee">
                                                    </td>
                                                    <td>
                                                        <a class="btn btn-large  max-WT-200 font-100 btn-green"
                                                            (click)="updatefeeapi(item.coinShortName)">Update</a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- tab2 -->



                    <!-- tab3 -->

                </div>





                <!--edit  privilege -->


            </div>
        </div>
    </div>

</main>