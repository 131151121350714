import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from '../service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-dispute-trade-details',
  templateUrl: './dispute-trade-details.component.html',
  styleUrls: ['./dispute-trade-details.component.css']
})
export class DisputeTradeDetailsComponent implements OnInit {
  addBannerForm: FormGroup;
  imgSrc : any 
  imageUrl: any;
  marked = false;
  constructor(public mainService: ServiceService, private router: Router) { }

  ngOnInit(): void {
    this.addBannerForm = new FormGroup({
      'title': new FormControl('', [Validators.required]),
      'image' : new FormControl(''),
      "description": new FormControl('', Validators.required),
      "isActive" : new FormControl(true)
    });
  }
  addBanner(){
    if(this.imgSrc == "assets/loader/1488 (2).gif"){
      this.mainService.toasterWarn("Please wait while Banner image is uploading.")
      return
    }
    let status = ""
    if (this.addBannerForm.value.isActive) {
      status = "ACTIVE"
    } else {
      status = "DISACTIVE"
    }
    let url = "static/admin/static-content/save-banner"
    const data = {
      "description": this.addBannerForm.value.description,

      "image": this.imgSrc,

      "title": this.addBannerForm.value.title,
      "bannerStatus" : status
    }
    console.log("isActive",data.bannerStatus);

    this.mainService.showSpinner()
      this.mainService.postApi(url,data).subscribe((res)=>{
        if(res.status == 200){
          this.mainService.hideSpinner()
          this.mainService.toasterSucc(res.message)
          this.router.navigate(['/dispute-management'])
        }
        else{
          this.mainService.hideSpinner()
          this.mainService.toasterErr(res.message)
        }
      })
    }

temp($event){
  // var img = $event.target.files[0];
    // this.uploadImageFunc(img);
    if ($event.target.files && $event.target.files[0]) {
      const max_height = 450;
      const max_width = 1920;
      const max_width2 = 1640;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          console.log(img_height, img_width);
          if (img_height == max_height && (img_width == max_width || img_width == max_width2)) {
            // this.imgBase64Path = e.target.result;
               
          } else {
            
            // this.image = ''
            // this.addBannerForm.controls['bannerFile'].reset()
            this.mainService.toasterErr('Please upload image of size 1920  450 or 1640  450 only.')
          }
        };
      };
      reader.readAsDataURL($event.target.files[0]);
    }
  }

  ValidateFileUpload(event) {
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (e: any) => {
      const image = new Image();
      image.src = e.target.result;
      image.onload = rs => {
        if (rs.currentTarget['height'] ==  450 && ( rs.currentTarget['width'] == 1920 ||  rs.currentTarget['width'] == 1640)){
          
            const file = event.target.files[0];
           this.imageUrl =  file
         this.uploadProfilePic() 
        }
        else{
          this.imageUrl = ''
          // this.addBannerForm.controls['bannerFile'].reset()
          this.mainService.toasterErr('Please upload image of size 1920  450 or 1640  450 only.')
        }
        
      }
      
    }
    // -=-=-==-=-=-=-=-=-=-=-=-=-=--=-==-=-=-=-=-=-=-==-=-=-=-=-=-=-=-=-=-=-=--=-=-=-=-
 
    

    
  }
  uploadProfilePic(){
    let url = "static/upload-file"
    this.imgSrc = "assets/loader/1488 (2).gif"
    const formData = new FormData();
    formData.append('file', this.imageUrl)
    this.mainService.showSpinner();
    this.mainService.postApi('account/upload-file', formData).subscribe(res => {
      // this.mainService.hideSpinner();
      if (res['status'] == '200') {
        this.imgSrc = res['data'];
        this.mainService.hideSpinner();
      }
    }, err => {
      this.mainService.hideSpinner();
      if (err['status'] == '401') {
        this.mainService.onLogout();
        this.mainService.toasterErr('Unauthorized Access');
      } else {
        this.mainService.toasterErr('Something Went Wrong');
      }
    })

  }

  toggleVisibility(e) {
    this.marked = e.target.checked;
  } 
}



