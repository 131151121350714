
<div class="wrapper">

    <!-- Header End -->
  
  
    <!-- Aside End -->
    <main class="middle-content" style="margin-top:-3%;">
        <!-- Page Title Start -->
        <div class="page_title_block">
            <h1 class="page_title">Dashboard</h1>
        </div>
        <!-- Page Title End -->
        <div class="content-section dashboard-block">
            <ul class="dash_list d-flex w-100 flex-wrap text-center justify-content-between">
                <li class="d-inline-flex align-items-center justify-content-center bg-blue">
                    <div class="w-100" [routerLink]="['/user-management']">
                        <div class="dash_icn">
                            <i class="fas fa-users fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Registered Users
                          </h2>
                        <h4>{{userListLength}}</h4>
                    </div>
                </li>
                <li class="d-inline-flex align-items-center justify-content-center bg-blue">
                    <div class="w-100" [routerLink]="['/user-management']">
                        <div class="dash_icn">
                            <i class="fas fa-user-ninja fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Active Users</h2>
                        <h4>{{activeUserLength}}</h4>
                    </div>
                </li>
                <li class="d-inline-flex align-items-center justify-content-center bg-blue">
                    <div class="w-100" [routerLink]="['/user-management']">
                        <div class="dash_icn">
                            <i class="fas fa-user-slash fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Blocked Users</h2>
                        <h4>{{blockedUserLength}}</h4>
                    </div>
                </li>
                <li class="d-inline-flex align-items-center justify-content-center bg-blue">
                    <div class="w-100" [routerLink]="['/kyc-management']">
                        <div class="dash_icn">
                            <i class="fas fa-file fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Pending KYC</h2>
                        <h4>{{countByKycStatus}}</h4>
                    </div>
                </li>
                <li class="d-inline-flex align-items-center justify-content-center bg-blue">
                    <div class="w-100" [routerLink]="['/kyc-management']">
                        <div class="dash_icn">
                            <i class="fas fa-coins fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Accepted Kyc</h2>
                        <h4>{{acceptedKyc || 0}}</h4>
                    </div>
                </li>
                <li class="d-inline-flex align-items-center justify-content-center bg-blue">
                    <div class="w-100" [routerLink]="['/hotcoldwallet-management']">
                        <div class="dash_icn">
                            <i class="fas fa-users-cog fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Deposit Request
                          </h2>
                        <h4>{{coinList.depositCount}}</h4>
                    </div>
                </li>
                <li class="d-inline-flex align-items-center justify-content-center bg-blue">
                    <div class="w-100" [routerLink]="['/hotcoldwallet-management']">
                        <div class="dash_icn">
                            <i class="fas fa-coins fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Coins</h2>
                        <h4>{{coinList.coinCount}}</h4>
                    </div>
                </li>
                <li class="d-inline-flex align-items-center justify-content-center bg-blue">
                    <div class="w-100" [routerLink]="['/hotcoldwallet-management']">
                        <div class="dash_icn">
                            <i class="fas fa-coins fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Number Of Referral</h2>
                        <h4>{{totalRefferal || 0}}</h4>
                    </div>
                </li>
                <li class="d-inline-flex align-items-center justify-content-center bg-blue">
                    <div class="w-100" [routerLink]="['/hotcoldwallet-management']">
                        <div class="dash_icn">
                            <i class="fas fa-coins fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Earning</h2>
                        <h4>{{totalEarning || 0}}</h4>
                    </div>
                </li>
               
            </ul>
        </div>
    </main>
    <!-- Middle Content End -->
  </div>
  <!-- Wrapper End -->
  
  <!--Modal Start-->
  <div class="modal fade global-modal reset-modal" id="delet_farms_modal">
    <div class="modal-dialog max-WT-500">
        <div class="modal-content">
            <div class="inner_border_area">
                <!-- Modal body -->
                <div class="modal-body  text-center">
                    <div class="row align-items-center modal_flax_height">
                        <div class="col">
                            <div class="box-title mb40 d-inline-block">
                                <h2>Are you sure?</h2>
                                <p>You won’t be able to revert this!</p>
                            </div>
                            <div class="max-WT-300 d-inline-block">
                                <button type="button" class="btn btn-gray btn-large radius0 btn-block">YES</button>
                                <button type="button" class="btn btn-red btn-large radius0 btn-block" data-dismiss="modal">CANCEL</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  <!-- signout_modal Start -->
  
  <!-- Signout Modal -->
  <!-- Change Password_modal Start -->
  <div class="modal fade global-modal reset-modal" id="change_passwprd_modal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="inner_border_area">
                    <div class="modal-header ">
                        <h4 class="modal-title text-center">Change Password</h4>
  
                    </div>
                    <div class="modal-body">
                        <div class="row align-items-center modal_flax_height">
                            <div class="col">
                                <div class="form-group">
                                    <label class="control-labe">Old Password</label>
                                    <input class="form-control" placeholder="" required="" type="text">
                                </div>
                                <div class="form-group">
                                    <label class="control-labe">New Password</label>
                                    <input class="form-control" placeholder="" required="" type="text">
                                </div>
                                <div class="form-group">
                                    <label class="control-labe">Confirm Password</label>
                                    <input class="form-control" placeholder="" required="" type="text">
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <button type="submit" class="btn btn-gray btn-large radius0 btn-block">SUBMIT</button>
                                    </div>
                                    <div class="col-6">
                                        <button type="button" class="btn btn-red btn-large radius0 btn-block" data-dismiss="modal">CANCEL</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
  </div>
  