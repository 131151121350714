import { Component } from '@angular/core';
import {NavigationEnd, Router,Event } from '@angular/router';
import { ServiceService } from './service.service';
declare var $:any

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'QTE Admin Panel';
  currUrl: any;
  view:boolean=false;
constructor(public service: ServiceService, public routes: Router){
  routes.events.subscribe((event: Event) => {
    if (event instanceof NavigationEnd) {
      this.currUrl = event.url.split('/')[1];
      console.log(this.currUrl)
    }
    if (!(this.currUrl == `login` || this.currUrl == `forgot-password` || this.currUrl?.includes('reset-password') || this.currUrl == ``)) {
      this.view = false
    }else{
      this.view=!this.view
    }
  })
}

  ngOnInit() {

    this.service.initSocketQtePrice();

  //   $(".btn-toggle,.close_panel").click(function() {
  //     $("body").toggleClass("toggle-wrapper");
  // });
  // this.service.initSocketSupportChat() //
  }

}
