import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ServiceService } from "../service.service";
import { FormGroup, FormControl } from "@angular/forms";
declare var $: any;
@Component({
  selector: "app-view-user-management-exchange",
  templateUrl: "./view-user-management-exchange.component.html",
  styleUrls: ["./view-user-management-exchange.component.css"],
})
export class ViewUserManagementExchangeComponent implements OnInit {
  currTab: any = "general";
  profileData : any  = []
  userId: any;
  basicUserDetails: any;
  loginDetailss: any = [];
 
  pageNumber: number = 1;
  loginDetailsLength: any;
  kycDetailss: any = [];
  kycDetailsLength: any;
 userStatus : any;
  
  tradeList: any = [];
  tradeListlength: any;
  wallethistoryData: any = [];
  wallethistoryDatalength: any;
 
  blockedbyData: any = [];
  BlockDeleteId: any;
  userStatusForSuspend: any;
  email : any
  phoneNumber : any
  suspendForm: FormGroup;

  buySell : any = []
  constructor(
    public router: Router,
    public service: ServiceService,
    public param: ActivatedRoute
  ) {
    this.param.queryParams.subscribe((res: any) => {
      console.log("jdhsgfjsdhg", res);
      this.userId = res.id;
    });
  }

  ngOnInit() {
    this.suspendForm = new FormGroup({
      reasonforsuspend: new FormControl(""),
    });
    this.kycDetails() 
    this.loginDetails()
    this.userBasicDetails()
    // this.selectTab(this.currTab)
  }
  /** to switch between tabs */
  selectTab(tab) {
    this.currTab = tab;
    switch (tab) {
      case "general":
        this.getProfile();
        break;
      case "buySell":
        this.getBuySell();
        break;
      case "trades":
        this.getTradeList();
        break;
      case "wallet":
        this.getWalletHistory();
        break;
     

      default:
        this.loginDetails()
        break;
    }
  }
  getBuySell() {
    this.service.showSpinner();
    this.service.get(`account/admin/user-management/user-details?userId=${this.userId}`).subscribe((res) => {
      if(res['status'] == 200){
      this.service.hideSpinner();
      this.profileData = res["data"];
      this.email = res["data"]["email"]
      this.phoneNumber = res["data"]["phoneNo"]
    }
    else{
      this.service.hideSpinner();
    }
    });
  }
  getProfile() {
    this.service.showSpinner();
    this.service.get(`account/admin/user-management/user-details?userId=${this.userId}`).subscribe((res) => {
      if(res['status'] == 200){
      this.service.hideSpinner();
      this.profileData = res["data"];
      this.email = res["data"]["email"]
      this.phoneNumber = res["data"]["phoneNo"]
    }
    else{
      this.service.hideSpinner();
    }
    });
  }

 
  getWalletHistory() {
    this.service
      .get(
        `wallet/admin/transaction-history/get-user-transaction-history?userId=${this.userId}`
      )
      .subscribe((res: any) => {
        if (res.status == 200) {
          this.wallethistoryData = res.data;
          this.wallethistoryDatalength = res.data.length;
          this.service.hideSpinner()
        }
        else{
          this.service.hideSpinner()
        }
      });
  }
  getTradeList() {
    this.service.showSpinner();
    this.service
      .get("p2p-exchange/search-and-filters-trade-list?page=0&pageSize=5")
      .subscribe((res: any) => {
        if (res.status == 200) {
          this.tradeList = res.data.list;
          this.tradeListlength = this.tradeList.length;
        
          this.service.hideSpinner();
        }
        else{
          this.service.hideSpinner()
        }
      });
  }

  kycDetails() {
    this.service
      .get("account/admin/kyc-management/get-kyc-details?userId=" + this.userId)
      .subscribe(
        (res: any) => {
          this.service.showSpinner();
          if (res.status == 200) {
            console.log("jhfgs876dhjgsvju", res);
            this.kycDetailss = res.data.document;
            this.kycDetailsLength = res.data.length;
           
            this.service.hideSpinner();
          } else {
            this.service.hideSpinner();
          }
        },
        (error) => {
          console.log("hgsfdu78s6djghs", error);
          this.service.hideSpinner();
        }
      );
  }
  userBasicDetails() {
    this.service.showSpinner();
    this.service
      .get("account/admin/user-management/user-details?userId=" + this.userId)
      .subscribe(
        (res: any) => {
          if (res.status == 200) {
            this.basicUserDetails = res.data;

            
            this.selectTab(this.currTab)
            this.service.hideSpinner();
            
           
          } else {
            this.service.hideSpinner();
           
          }
        },
        (error) => {
          this.service.hideSpinner();
        }
      );
  }

  loginDetails() {
    this.service.showSpinner();
    this.service
      .get(
        "account/admin/logs/get-user-login-details?userIdForLoginDetails=" +
          this.userId
      )
      .subscribe(
        (res: any) => {
          if (res.status == 200) {
            this.loginDetailss = res.data;
          
            this.loginDetailsLength = res.data.length;
            this.service.hideSpinner();
            console.log(
              "jdshfg89sdfghknjsdbfhgks89fgjbn",
              this.loginDetailsLength
            );
          } else {
            this.service.hideSpinner();
          }
        },
        (error) => {
          console.log("jdhsfgvsd897fgysdjbghfg", error);
          this.service.hideSpinner();
        }
      );
  }
 


  deleteFunction() {
    this.service
      .post(
        "account/admin/remove-user-from-block-list?blockedId=" +
          this.BlockDeleteId,
        ""
      )
      .subscribe((res: any) => {
        console.log("jdghsf89ds7yfghjkvbsdif8g", res);
      });
  }

  blockUnblockUser(val) {
    this.userStatus = val
    $("#blockUnblockModal").modal("show");
  }
  blockUnblockUserApi(){
    var url = 'account/admin/user-management/user-status?ipAddress=' + (11) + '&location=' + ('unknown') + '&userIdForStatusUpdate=' + (this.userId) + '&userStatus=' + (this.userStatus);
    this.service.showSpinner();
    this.service.post(url, '').subscribe(res => {

      if (res['status'] == 200) {
        
        this.userBasicDetails();
        this.service.hideSpinner();
        $("#blockUnblockModal").modal("hide");
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
    
  }
  unsuspenduser() {
    $("#unsuspendModal").modal("show");
  }
 

  
  exportAsXLSX() {
    let dataArr = [];
    this.loginDetailss.forEach((element, ind) => {
      dataArr.push({
        "S no": ind + 1,
        "User ID": element.userId ? element.userId : "",
        "User Name":
          element.firstName + "" + element.lastName ? element.lastName : "",
        Email: element.email ? element.email : "N/A",
        Phone: element.ipAddress ? element.ipAddress : "N/A",
        Status: element.userStatus == true ? "Active" : "Inactive",
        Date: element.createTime ? element.createTime.slice(0, 10) : "N/A",
      });
    });

    this.service.exportAsExcelFile(dataArr, "Admin User list");
  }

  loginHistoryExportInExcel() {
    let dataArr = [];
    this.loginDetailss.forEach((element, ind) => {
      dataArr.push({
        "S no": ind + 1,
        "Date Time": element.createTime ? element.createTime : "N/A",
        "Region IP": element.ipAddress ? element.ipAddress : "N/A",
      });
    });

    this.service.exportAsExcelFile(dataArr, "Admin User list");
  }

  kycStatusExportInExcel() {
    let dataArr = [];
    this.kycDetailss.forEach((element, ind) => {
      dataArr.push({
        "S no": ind + 1,
        "Date Time": element.createTime ? element.createTime : "N/A",
        "Region IP": element.ipAddress ? element.ipAddress : "N/A",
      });
    });

    this.service.exportAsExcelFile(dataArr, "Admin User list");
  }
}
