<main class="middle-content">
    <!-- Page Title Start -->
    <div class="page_title_block">
        <h1 class="page_title">Admin Details</h1>
    </div>
    <!-- Page Title End -->
    <div class="content-section" [formGroup]="editStaffForm">
        <div class="outer-box">
            <div class="custom_tabs common-tabs">
                <div class="tab-content">
                    <div class=" staff row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <span class="col-md-4">Employee ID </span>
                                <span class="col-md-1">:</span>
                                <span class="col-md-7">{{adminDetails?.userId}}</span>
                            </div>
                            <div class="form-group row">
                                <span class="col-md-4">Name </span>
                                <span class="col-md-1">:</span>
                                <span class="col-md-7">{{adminDetails?.firstName}} {{adminDetails?.lastName}}</span>
                            </div>
                            <div class="form-group row">
                                <span class="col-md-4">Email </span>
                                <span class="col-md-1">:</span>
                                <span class="col-md-7">{{adminDetails?.email}}</span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <span class="col-md-4">Gender </span>
                                <span class="col-md-1">:</span>
                                <span class="col-md-7">{{adminDetails?.gender}}</span>
                            </div>
                            <div class="form-group row">
                                <span class="col-md-4">Mobile Number</span>
                                <span class="col-md-1">:</span>
                                <span class="col-md-7">{{adminDetails?.phoneNo}}</span>
                            </div>
                            <div class="form-group row">
                                <span class="col-md-4">Role</span>
                                <span class="col-md-1">:</span>
                                <span class="col-md-7">{{adminDetails?.role}}</span>
                            </div>
                        </div>
                    </div>
                    <table aria-describedby="" style="margin-top: 1%;">
                        <tr style="background: #343434;
                        color: white;">
                        <tr>
                            <th id="" style="text-align: center;padding: 15px 0 15px 0;width: 69%;">Permission</th>
                            <th id="" style="text-align: center;padding: 15px 0 15px 0;">Operations</th>
                        </tr>
                        <tr>
                            <td class="permiss">Dashboard</td>

                            <td class="check">
                                <input type="checkbox" formControlName="dashboardChecked"
                                    (change)="checkboxClick('DASHBOARD',$event.target.checked)" disabled>
                            </td>
                        </tr>
                        <tr>
                            <td class="permiss">Staff Management</td>
                            <td class="check">
                                <input type="checkbox" formControlName="staffChecked"
                                    (change)="checkboxClick('STAFF_MANAGEMENT',$event.target.checked)" disabled>
                            </td>
                        </tr>

                        <tr>
                            <td class="permiss">Hot Wallet Management</td>
                            <td class="check">
                                <input type="checkbox" formControlName="hotWalletChecked"
                                    (change)="checkboxClick('HOT_COLD_LIMIT_MANAGEMENT',$event.target.checked)"
                                    disabled>
                            </td>
                        </tr>
                        <tr>
                            <td class="permiss">KYC Management</td>
                            <td class="check">
                                <input type="checkbox" formControlName="kycChecked"
                                    (change)="checkboxClick('KYC_MANAGEMENT',$event.target.checked)" disabled>
                            </td>
                        </tr>
                        <tr>
                            <td class="permiss">Wallet Management</td>
                            <td class="check">
                                <input type="checkbox" formControlName="walletChecked"
                                    (change)="checkboxClick('WALLET_MANAGEMENT',$event.target.checked)" disabled>
                            </td>
                        </tr>
                        <tr>
                            <td class="permiss">Static Content Management</td>
                            <td class="check">
                                <input type="checkbox" formControlName="staticChecked"
                                    (change)="checkboxClick('STATIC_CONTENT',$event.target.checked)" disabled>
                            </td>
                        </tr>
                        <tr>
                            <td class="permiss">Logs Management</td>
                            <td class="check">
                                <input type="checkbox" formControlName="logsChecked"
                                    (change)="checkboxClick('LOGS_MANAGEMENT',$event.target.checked)" disabled>
                            </td>
                        </tr>
                        <tr>
                            <td class="permiss">Ticket Management</td>
                            <td class="check">
                                <input type="checkbox" formControlName="ticketChecked"
                                    (change)="checkboxClick('TICKET_MANAGEMENT',$event.target.checked)" disabled>
                            </td>
                        </tr>

                        <tr>
                            <td class="permiss">Trade Management</td>
                            <td class="check">
                                <input type="checkbox" formControlName="tradeChecked"
                                    (change)="checkboxClick('TRADE_MANAGEMENT',$event.target.checked)" disabled>
                            </td>
                        </tr>
                        <tr>
                            <td class="permiss">Dispute Management</td>
                            <td class="check">
                                <input type="checkbox" formControlName="disputeChecked"
                                    (change)="checkboxClick('DISPUTE_MANAGEMENT',$event.target.checked)" disabled>
                            </td>
                        </tr>
                        <tr>
                            <td class="permiss">Bank Management</td>
                            <td class="check">
                                <input type="checkbox" formControlName="bankChecked"
                                    (change)="checkboxClick('BANK_MANAGEMENT',$event.target.checked)" disabled>
                            </td>
                        </tr>
                        <tr>
                            <td class="permiss">Fee Management</td>
                            <td class="check">
                                <input type="checkbox" formControlName="feeChecked"
                                    (change)="checkboxClick('FEE_MANAGEMENT',$event.target.checked)" disabled>
                            </td>
                        </tr>
                    </table>
                    &nbsp;
                    <div style="text-align: center; margin-bottom:3%;">
                        <button class="btn  btn-theme" type="button" (click)="back()">Back</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Table Responsive End -->
    </div>
</main>