<div class="wrapper">
    <!-- Aside End -->
    <main class="middle-content">
        <!-- Page Title Start -->
        <div class="page_title_block">
            <h1 class="page_title">Referal Management</h1>

        </div>
        <!-- Page Title End -->
        <div class="content-section">
            <div class="outer-box">
                <div class="custom_tabs common-tabs">

                    <div class="tab-content">
                        <div class="tab-pane active show">
                            <div class="tab-pane1 global-table">
                                <div class="sec_head_new">
                                    <div class="row">
                                        <div class="col-md-3  col-xl-4 col-lg-4">
                                            <div class="filter_fields">
                                                <div class="head_flt_select">

                                                    <div class="head_flt_select input-label input-label1">
                                                        <span class="d-flex align-items-center">Search.</span>
                                                        <input type="text" class="form-control"
                                                         [(ngModel)]="email" (mouseleave)="format()"   placeholder="Search by email id">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3 col-xl-2 col-lg-4 pr-0">
                                            <div class="filter_fields">
                                                <div class="head_flt_select">
                                                    <div class="head_flt_select input-label input-label1">
                                                        <span class="d-flex align-items-center">From Date</span>
                                                        <input type="date" name="" [(ngModel)]="fromDate" class="form-control datepicker"
                                                            placeholder="Search by date">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2 col-xl-2 col-lg-4 pr-0">
                                            <div class="filter_fields">
                                                <div class="head_flt_select">
                                                    <div class="head_flt_select input-label input-label1">
                                                        <span class="d-flex align-items-center">To Date</span>
                                                        <input type="date" [(ngModel)]="toDate" name="" class="form-control datepicker"
                                                            placeholder="Search by date">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-xl-2 col-lg-4"
                                            style="display: flex; margin-top: 18px;margin-left:10% ;">


                                            <div class="text-left">
                                                <div class="head_flt_select input-label input-label1">
                                                    <span class="d-flex align-items-center"></span>
                                                    <button type="submit" (click)="format()" class="btn  btn-theme">Search</button>

                                                </div>
                                            </div>
                                            <div class="text-left">
                                                <span class="d-flex align-items-center"></span>
                                                <button type="submit" class="btn  btn-theme">Reset</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="table-responsive">
                                    <table class="table table-bordered" aria-describedby="">
                                        <thead>
                                            <tr class="no_wrap_th">
                                                <th id="">S.No</th>
                                                <th id="">Refer To User Name</th>
                                                <th id="">Refer To Email Address</th>
                                                <th id="">Registration Date & Time</th>
                                                <th id="">Refer By User Name</th>
                                                <th id="">Refer By Email Address </th>
                                                <th id="">Referral Id</th>
                                                <th id="">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let data of referalData | paginate:{itemsPerPage: itemsPerPage, currentPage: currentPage,totalItems: lengthTotal}; let i=index;">
                                                <td>{{itemsPerPage * (currentPage - 1) + i+1}}</td>
                                                <td>{{data?.referToUserName || 'N/A'}}</td>
                                                <td>{{data?.referToEmail || 'N/A'}}</td>
                                                <td>{{data?.registraionDate | date:"medium" || '---'}}</td>
                                                <td> {{data?.referByUserName}}</td>
                                                <td>{{data?.referByEmailId || 'N/A'}}</td>
                                                <td>{{data?.referalId || 'N/A'}}</td>
                                                <td>
                                                    <div class="action-btn-alignment">
                                                        <a data-toggle="modal" class="pointer"
                                                         (click)="viewReferal(data?.id)"  ><em class="fa fa-eye"
                                                                title="View"></em></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr *ngIf="referalData == ''">
                                                <td colspan="8"> No wallet transactions found</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="custom-pagination mt20 text-center" style="float: right;">
                                    <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <!-- Table Responsive End -->
        </div>
    </main>
    <!-- Middle Content End -->
</div>
<!-- Wrapper End -->
<!--Modal Start-->
<div class="modal fade global-modal reset-modal" id="delet_farms_modal">
    <div class="modal-dialog max-WT-500">
        <div class="modal-content">
            <!-- Modal body -->
            <div class="modal-body  text-center">
                <div class="row align-items-center modal_flax_height">
                    <div class="col">
                        <div class="box-title mb40 d-inline-block">
                            <h2>Are you sure?</h2>
                            <p>You won’t be able to revert this!</p>
                        </div>
                        <div class="max-WT-300 d-inline-block border-top">
                            <button type="button" class="btn btn-gray btn-large radius0 btn-block">YES</button>
                            <button type="button" class="btn btn-red btn-large radius0 btn-block"
                                data-dismiss="modal">CANCEL</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- signout_modal Start -->

<!-- Signout Modal -->
<!-- invite_modal Start -->
<div class="modal fade global-modal reset-modal" id="invite">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-header ">
                        <h4 class="modal-title text-center">Invite</h4>
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                    </div>
                    <div class="modal-body">
                        <div class="row align-items-center modal_flax_height">
                            <div class="col">
                                <div class="form-group">
                                    <label class="control-labe">Email*</label>
                                    <input class="form-control" placeholder="" required="" type="email">
                                </div>
                                <div class="form-group">
                                    <label class="control-labe">Subject</label>
                                    <input class="form-control" placeholder="" required="" type="text">
                                </div>
                                <div class="form-group">
                                    <label class="control-labe">Message</label>
                                    <textarea name="" id="" cols="20" rows="5" class="form-control">
                                </textarea>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <button type="submit"
                                            class="btn btn-gray btn-large radius0 btn-block">SUBMIT</button>
                                    </div>
                                    <div class="col-6">
                                        <button type="button" class="btn btn-red btn-large radius0 btn-block"
                                            data-dismiss="modal">CANCEL</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- invite_modal End -->
<!-- invite_modal Start -->
<div class="modal fade global-modal reset-modal" id="accept">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-header ">
                        <h4 class="modal-title text-center">Accept</h4>
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                    </div>
                    <div class="modal-body">
                        <div class="row align-items-center modal_flax_height">
                            <div class="col">
                                <div class="form-group">
                                    <div class="d-flex align-items-center text-center justify-content-center">
                                        <label class="switch">
                                            <input type="checkbox">
                                            <span class="slider round"></span>
                                        </label>
                                        <strong> R/W Accesbility</strong>
                                    </div>
                                </div>
                                <hr>
                                <div class="form-group">
                                    <div class="d-flex align-items-center">
                                        <div class="w-100">
                                            <h6>User Type</h6>

                                            <div class="radio_btn_cst d-flex">
                                                <label class="radio_style">End User
                                                    <input type="radio" checked="checked" name="radio" />
                                                    <span class="checkmark"></span>
                                                </label>
                                                <label class="radio_style">Service User
                                                    <input type="radio" name="radio" />
                                                    <span class="checkmark"></span>
                                                </label>
                                                <label class="radio_style">Admin User
                                                    <input type="radio" name="radio" />
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="w-100">
                                        <h6>Role</h6>
                                        <select name="" class="form-control form-control2 select_opt">
                                            <option value="">Select</option>
                                            <option value="" selected>Abuja region</option>
                                            <option value="">Nigeria region</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="d-flex align-items-center flex-wrap text-center justify-content-center">
                                        <div class="w-50 d-inline-flex align-items-center">
                                            <label class="switch">
                                                <input type="checkbox">
                                                <span class="slider round"></span>
                                            </label>
                                            <span> Admin Rights</span>
                                        </div>
                                        <div class="w-50 d-inline-flex align-items-center">
                                            <label class="switch">
                                                <input type="checkbox">
                                                <span class="slider round"></span>
                                            </label>
                                            <span> Approved</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt20 text-center">
                                    <button class="btn btn-small btn-info">Set</button>
                                    <button class="btn btn-small btn-danger" data-dismiss="modal">Cancel</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- invite_modal End -->

<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="delete">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>

                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure want to delete this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-info">Submit</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- delete_modal End -->
<!-- block_modal Start -->
<div class="modal fade global-modal reset-modal" id="block">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>

                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure want to block this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-success">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>