import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
declare var H : any
@Component({
  selector: 'app-add-new-staff',
  templateUrl: './add-new-staff.component.html',
  styleUrls: ['./add-new-staff.component.css']
})
export class AddNewStaffComponent implements OnInit {
  addForm: FormGroup;
  ipAddress: any;
  
  check : any = [];
  index : any = 0
  name : any = []
  privilage  = []
  permissionArr : any =  ["DASHBOARD_MANAGEMENT","USER_MANAGEMENT" , "SUB_ADMIN_MANAGEMENT",
  "HOT_COLD_WALLET_MANAGEMENT", "KYC_MANAGEMENT", "WALLET_MANAGEMENT","BUY_SELL_MANAGEMENT",
  "FEE_MANAGEMENT","HELP_MANAGEMENT",
  "BANNER_MANAGEMENT","INSTITUTIONAL_INVESTOR_MANAGEMENT","STATTIC_CONTENT_MANAGEMENT"]
 
  constructor(public service: ServiceService, public router: Router, private http: HttpClient) {
    
    for(let i of this.permissionArr){
 
      this.name[this.index] = String(i).split('_').join(' ')
      this.index++
    }
    this.http.get<{ ip: string }>('https://jsonip.com')
      .subscribe(data => {
        console.log('th data', data);
        this.ipAddress = data
        console.log("djfhgdj", this.ipAddress)
      })
     
  }
  
 

  ngOnInit() {
    this.addForm = new FormGroup({
      'firstName': new FormControl('', [Validators.pattern(/^[a-z,.'-]+$/i), Validators.required]),
      'lastName': new FormControl('', [Validators.pattern(/^[a-z,.'-]+$/i), Validators.required]),
      'email': new FormControl('', [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,9}|[0-9]{1,3})(\]?)$/i)]),
      'role': new FormControl('', Validators.required),
      'gender': new FormControl('', Validators.required),
      'phoneNumber': new FormControl('', [Validators.required, Validators.pattern(/^[1-9][0-9]{8,13}$/), Validators.maxLength(18)]),
    })
  }

  addStaff() {
    let data = {
      "address": "string",
      "city": "string",
      "country": "INDIA",
      "countryCode": "+91",
      "dob": "string",
      "email": this.addForm.value.email,
      "firstName": this.addForm.value.firstName,
      "gender": this.addForm.value.gender,
      "imageUrl": "string",
      "ipAddress": this.ipAddress.ip,
      "lastName": this.addForm.value.lastName,
      "location": "string",
      "phoneNo": this.addForm.value.phoneNumber,
      "previlage": this.privilage,
      // "roleStatus": "SUBADMIN",
      "roleStatus": this.addForm.value.role,
      "state": "string",
      "webUrl": 'https://qte-admin.mobiloitte.org/reset-password',  
      "userIdToUpdate": 12
    }
    
    this.service.showSpinner()
    this.service.postApi('account/admin/user-management/create-sub-admin', data).subscribe((res) => {
      this.service.hideSpinner()
      if(res.status == 200){
        this.service.toasterSucc(res.message)
        this.router.navigate(['/staff-management'])
      }else if(res.status == 205)(
        this.service.toasterSucc(res.message)
      )
    },(err)=>{
      this.service.hideSpinner()
      if(err.status == 400){
        this.service.toasterErr(err.error.message)
      }else {
        this.service.toasterErr(err.error.message)
      }
    })
  }

  /** Function for checkbox click */
  checkPerm(permName,ch){
  
    console.log(permName,ch);
    if(ch){
      this.privilage.push(permName)
    }
    else{
     
      this.privilage.splice(this.privilage.indexOf(permName),1)
    }
    console.log(this.privilage);
    
   }
}
