<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title">Trade Management</h1>
    </div>
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">
                        <div class="sec_head_new">
                            <div class="row">
                                <div class="col-md-2 pL0">
                                    <div class="head_flt_select">
                                        <div class="head_flt_select input-label input-label1">
                                            <span class="d-flex align-items-center">Trade Status</span>
                                            <select class="form-control" [(ngModel)]="tradeStatus">
                                                <option value="" placeholder="Select">Select</option>
                                                <option value="PENDING">Pending</option>
                                                <option value="COMPLETE">Complete</option>
                                                <option value="CANCEL">Cancel</option>
                                                <option value="DISPUTE">Dispute</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 pL0">
                                    <div class="head_flt_select">
                                        <div class="head_flt_select input-label input-label1">
                                            <span class="d-flex align-items-center">Country</span>
                                            <select class="form-control" [(ngModel)]="country">
                                                <option value="">Select</option>
                                                <option *ngFor="let item of countryList" [value]="item?.country">
                                                    {{item?.country}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 pL0">
                                    <div class="head_flt_select">
                                        <div class="head_flt_select input-label input-label1">
                                            <span class="d-flex align-items-center">Payment Method</span>
                                            <select class="form-control" [(ngModel)]="paymentType">
                                                <option value="">Select</option>
                                                <option value="NationalBankTransfer">National Bank Transfer</option>
                                                <option value="IMPS">IMPS</option>
                                                <option value="paypal">PAYPAL</option>
                                                <option value="paytm">PAYTM</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 pL0">
                                    <div class="head_flt_select">
                                        <div class="head_flt_select input-label input-label1">
                                            <span class="d-flex align-items-center">Trade Type</span>
                                            <select class="form-control" [(ngModel)]="type">
                                                <option value="">Select</option>
                                                <option value="SELL">Sell</option>
                                                <option value="BUY">Buy</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 user-pl">
                                    <div class="user-frmbtn" style="display: flex;">
                                        <button type="submit" (click)="search()" class="btn  btn-theme mr-2 pL7">Search</button>
                                        <button type="submit" (click)="reset()" class="btn  btn-theme">Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="trade table">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th id="">Trade ID</th>
                                        <th id="">Buyer</th>
                                        <th id="">Seller</th>
                                        <th id="">Created Date & Time</th>
                                        <th id="">Status</th>
                                        <th id="">Trade</th>
                                        <th id="">Payment Method</th>
                                        <th id="">Fiat</th>
                                        <th id="">Fee</th>
                                        <th id="">Exchange</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let data of tradeList | paginate:{itemsPerPage: itemsPerPage, currentPage: currentPage,totalItems: totalItems}; let i=index; ">
                                        <td (click)="navigate(data.tradeId)"><u
                                                class="trade-table-data">{{data?.tradingId || 'N/A'}}</u></td>
                                        <td>{{data?.buyer || 'N/A'}}</td>
                                        <td>{{data?.seller || 'N/A'}}</td>
                                        <td>{{(data?.creationTime | date:"d MMM, y h:mm a") || 'N/A'}}</td>
                                        <td>{{data?.tradeStatus || 'N/A'}}</td>
                                        <td>{{data?.type || 'N/A'}}</td>
                                        <td>{{data?.paymentType || 'N/A'}}</td>
                                        <td>{{data?.tradeAmount || 'N/A'}} {{data?.fiatCoin}}</td>
                                        <td>{{data?.tradeFee || 'N/A'}}</td>
                                        <td>{{data?.totalBTC || 'N/A'}}</td>
                                    </tr>
                                    <tr *ngIf="tradeList?.length==0">
                                        <td colspan="11" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <H5>No record found</H5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="custom-pagination mt20 text-center" style="float: right;" *ngIf="totalItems > 5 ">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>