<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="text-transform: uppercase;">Edit Announcement</h1>
       
    </div>
    <div class="content-section">
        <!-- <div class="page_title_block" style="padding-left: 21%;padding-top: 7%;">
            <h1 class="page_title">Add Banner</h1>
        </div> -->
        <div class="order-view setting-page">
            <div class="  mb40">
                <form [formGroup]="addAnounceneent" >
                    <div class="input-style mb20 mt20 ">
                        <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-4 textalignright">Announcement Title
                                </label>
                                <div class="col-md-1">
                                  <span>:</span>
                                 </div>
                            <div class="col-md-7 textaligncenter">
                                <div class="form-group ">
                                    <input type="text" class="form-control" placeholder="Enter announcement title"
                                        formControlName="title" (keypress)="mainService.preventSpace($event)"
                                        maxlength="150">
                                    <div class="text-danger">
                                        <span
                                            *ngIf="addAnounceneent.get('title').hasError('required') && (addAnounceneent.get('title').dirty || addAnounceneent.get('title').touched )">
                                            *Please enter announcement title.
                                        </span>
  
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-4 textalignright">Announcement Description
                                </label>
                                <div class="col-md-1">
                                  <span>:</span>
                                 </div>
                            <div class="col-md-7 textaligncenter">
                                <div class="form-group ">
                                    <textarea name="" id="" class="form-control" placeholder="Enter announcement description " formControlName="description" cols="20" rows="10"></textarea>
                                    <div class="text-danger">
                                        <span
                                            *ngIf="addAnounceneent.get('description').hasError('required') && (addAnounceneent.get('description').dirty || addAnounceneent.get('description').touched )">
                                            *Please enter announcement description.
                                        </span>
  
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-4 textalignright">Anouncement Tag
                                </label>
                                <div class="col-md-1">
                                  <span>:</span>
                                 </div>
                            <div class="col-md-7 textaligncenter">
                                <div class="form-group ">
                                    <select class="form-control" formControlName="tag">
                                        <option value="">Select Tag</option>
                                        <option value="BTC">BTC</option>
                                        <option value="ETH">ETH</option>
                                        <option value="QTE">QTE</option>
                                        <option value="USDT">USDT</option>
                                    </select>
                                    <div class="text-danger">
                                        <span
                                            *ngIf="addAnounceneent.get('tag').hasError('required') && (addAnounceneent.get('tag').dirty || addAnounceneent.get('tag').touched )">
                                            *Please select announcement tag.
                                        </span>
  
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                     
                      
  
                    </div>
  
                    
                    <div class="mt40 mb40 text-center">
                        <button class="btn btn-large  max-WT-150 btn-secondary" routerLink="/anouncement-management">Cancel</button>
                        <button class="btn btn-large  max-WT-150 btn-primary ml-2"
                        [disabled]="!addAnounceneent.valid" (click)="editAnouncement()">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
  </main>