<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title">VIEW REFERAL</h1>
        <hr style="background: black; opacity: 0.5;">
    </div>
    <div class="content-section">
        <div class="order-view  setting-page text-center">
            <div class="  mb40">
                <form>
                    <div class="input-style mb20 mt20 ">
                        <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-4 text-left ">Refer To User Name
                            </label>
                            <div class="col-md-1">
                                <span>:</span>
                            </div>

                            <div class="col-md-7 text-left">
                                <div class="form-group">

                                    <label style="font-weight: 400;">{{refferalData?.referToUserName}}</label> 
                                </div>
                            </div>
                        </div>


                        <div class="form-group row view-label-align">
                            <label class="col-md-4 text-left ">Refer To Email Address	
                            </label>
                            <div class="col-md-1">
                                <span>:</span>
                            </div>
                            <div class="col-md-7 text-left">
                                <div class="form-group">
                                    <label style="font-weight: 400;">{{refferalData?.referToEmail}}</label> 


                                </div>
                            </div>
                        </div>
                        <div class="form-group row view-label-align">
                            <label class="col-md-4 text-left ">Registration Date & Time
                            </label>
                            <div class="col-md-1">
                                <span>:</span>
                            </div>
                            <div class="col-md-7 text-left">
                                <div class="form-group">
                                    <label style="font-weight: 400;">{{refferalData?.referToEmail}}</label> 

                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-4 text-left ">Refer By User Name
                            </label>
                            <div class="col-md-1">
                                <span>:</span>
                            </div>
                            <div class="col-md-7 text-left">
                                <div class="form-group">
                                    <label style="font-weight: 400;">{{refferalData?.referToEmail}}</label> 
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-4 text-left "> Refer By Email Address
                            </label>
                            <div class="col-md-1">
                                <span>:</span>
                            </div>
                            <div class="col-md-7 text-left">
                                <div class="form-group">

                                    <label style="font-weight: 400;">{{refferalData?.referToEmail}}</label> 
                                </div>
                            </div>
                        </div>

                        <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-4 text-left ">  Referral Id
                            </label>
                            <div class="col-md-1">
                                <span>:</span>
                            </div>
                            <div class="col-md-7 text-left">
                                <div class="form-group">

                                    <label style="font-weight: 400;">{{refferalData?.referalId}}</label> 
                                </div>
                            </div>
                        </div>
                       
                        <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-4 text-left ">  Referal Level
                            </label>
                            <div class="col-md-1">
                                <span>:</span>
                            </div>
                            <div class="col-md-7 text-left">
                                <div class="form-group">

                                    <label style="font-weight: 400;">Level 6</label> 
                                </div>
                            </div>
                        </div>
                       
                            
                        <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-4 text-left ">   Level Description
                            </label>
                            <div class="col-md-1">
                                <span>:</span>
                            </div>
                            <div class="col-md-7 text-left">
                                <div class="form-group">

                                    <label style="font-weight: 400;">Get 10% reward on next trade of more than 1 BTC</label> 
                                </div>
                            </div>
                        </div>
                       

                    </div>

                    <div class="text-center mt40 mb40">
                        <button class="btn btn-large  max-WT-150 btn-secondary"
                            routerLink="/referal-managemnet" >Back</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</main>