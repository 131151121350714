<main class="middle-content"  style="margin-top:-3%;">
    <!-- Page Title Start -->
    <div class="page_title_block">
        <h1 class="page_title">View Staff</h1>
    </div>
    <!-- Page Title End -->
    <div  class="content-section">
        <div class="outer-box">
            <div class="custom_tabs common-tabs">
                <div class="tab-content">
                    <div class="staff row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <span class="col-md-4">Employee ID </span>
                                <span class="col-md-1">:</span>
                                <span class="col-md-7">{{staffDetails?.userId}}</span>
                            </div>
                            <div class="form-group row">
                                <span class="col-md-4">Name </span>
                                <span class="col-md-1">:</span>
                                <span class="col-md-7">{{staffDetails?.firstName}} {{staffDetails?.lastName}}</span>
                            </div>
                            <div class="form-group row">
                                <span class="col-md-4">Email </span>
                                <span class="col-md-1">:</span>
                                <span class="col-md-7">{{staffDetails?.email}}</span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <span class="col-md-4">Gender </span>
                                <span class="col-md-1">:</span>
                                <span class="col-md-7">{{staffDetails?.gender}}</span>
                            </div>
                            <div class="form-group row">
                                <span class="col-md-4">Mobile Number</span>
                                <span class="col-md-1">:</span>
                                <span class="col-md-7">{{staffDetails?.phoneNo}}</span>
                            </div>
                            <div class="form-group row">
                                <span class="col-md-4">Role</span>
                                <span class="col-md-1">:</span>
                                <span class="col-md-7">{{staffDetails?.role}}</span>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div style="text-align: center;margin-top: 3%;">
                        <h1 class="page_title">Permissions</h1>
                    </div>

                    <div class="row " style="margin-top: 3%;">

                        
                            <div class="col-md-4" *ngFor = "let item of permissionArr; let i = index">
                                <label class="globalCheckBox" style="text-transform: capitalize;"> {{name[i] |lowercase}}
                                    <input type="checkbox" disabled [(ngModel)]="permArray[i]"   [ngModelOptions]= "{standalone: true}"
                                     >
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                          
                      
                     
                    </div>

                    <!-- <div class="text-center mt40 mb40">
                        
                        <button class="btn btn-large  max-WT-150 ml-1 btn-secondary"
                            routerLink="/subAdmin">Back</button>
                     
                    </div> -->
                    &nbsp;

                    <div style="text-align: center; margin-bottom:3%;">
                        <button class="btn  btn-theme" type="button" (click)="edit(staffDetails?.userId)">Edit Staff</button>
                        <button class="btn  btn-theme" type="button" (click)="back()">Back</button>

                    </div>
                </div>
            </div>
        </div>
        <!-- Table Responsive End -->
    </div>
</main>
