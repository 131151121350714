<div class="wrapper">
    <!-- Aside End -->
    <main class="middle-content">
        <!-- Page Title Start -->
        <div class="page_title_block">
            <h1 class="page_title">View kyc</h1>
        </div>
        <!-- Page Title End -->
        <div class="content-section">
            <div class="outer-box">
                <!-- Gloabl Table Box Start -->
                <div class="global-table no-radius p0">
                    <div class="tab-content1">
                        <div class="tab-pane1">
                            <div class="col">
                                <div class="card" style="width: 80%;margin-left: 13%;">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="col-md-6">
                                                    <div class="row">
                                                        <label class="col-md-4">KYC ID </label>
                                                        <span class="col-md-1">:</span>
                                                        <span class="col-md-5">{{data?.kycId || '---'}}</span>
                                                    </div>
                                                    <div class="row">
                                                        <label class="col-md-4">KYC Status </label>
                                                        <span class="col-md-1">:</span>
                                                        <span class="col-md-5">{{data?.kycStatus || '---'}}</span>
                                                    </div>
                                                    <div class="row">
                                                        <label class="col-md-4">Created On </label>
                                                        <span class="col-md-1">:</span>
                                                        <span
                                                            class="col-md-5">{{(data?.createTime |date:"medium") || '---'  }}</span>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="row">
                                                        <label class="col-md-4">ID Document Type </label>
                                                        <span class="col-md-1">:</span>
                                                        <span class="col-md-5">{{kycDocList?.docName || '---'}}</span>
                                                    </div>
                                                    <div class="row">
                                                        <label class="col-md-4">ID Number </label>
                                                        <span class="col-md-1">:</span>
                                                        <span
                                                            class="col-md-5">{{kycDocList?.docIdNumber || '---'}}</span>
                                                    </div>
                                                    <div class="row">
                                                        <label class="col-md-4">Document Status</label>
                                                        <span class="col-md-1">:</span>
                                                        <span
                                                            class="col-md-5">{{kycDocList?.documentStatus || '---'}}</span>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>

                                <div class="row" style="margin-top: 3%">
                                    <div class="col" style="text-align: center;position: relative; left: 7%;">
                                        <img [src]="kycDocList?.frontIdUrl || 'assets/img/testimonial-img1.jpg'"
                                            (click)="viewDoc('Front',kycDocList?.frontIdUrl)" style="max-width: 60%;" alt=""><br>
                                        <label>(Front)</label>
                                    </div>
                                    <div class="col" style="text-align: center;">
                                        <img [src]="kycDocList?.backIdUrl || 'assets/img/testimonial-img1.jpg'"
                                        (click)="viewDoc('Back',kycDocList?.backIdUrl)" alt="" style="max-width: 60%;"><br>
                                        <label>(Back)</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div style="text-align: center;     margin-top: 9%; margin-bottom:3% ;">
                    <div><button class="btn btn-theme ml-1" style="width: 15%" *ngIf="kycData.kycStatus == 'PENDING'"
                            (click)="approveKyc()">Approve</button>
                        <button class="btn btn-theme ml-1" style="width: 15%" *ngIf="kycData.kycStatus == 'PENDING'"
                            (click)="openModal()">Reject</button>
                        <button class="btn btn-theme ml-1" style="width: 15%" (click)="back()">Back</button></div>
                </div>

            </div>
        </div>
        <!-- Table Responsive End -->

    </main>
</div>
<!-- Wrapper End -->
<!-- approve_modal Start -->
<div class="modal fade global-modal reset-modal" id="approveModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to approve this document?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2"
                                        (click)="approveDocFunc()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- approve_modal End -->
<!-- reject_modal Start -->
<div class="modal fade global-modal reset-modal" id="reject">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to reject this document?</p>
                                <textarea type="text" placeholder="Specify reason for rejection.."
                                    [(ngModel)]="rejectionReason" class="form-control"
                                    [ngModelOptions]="{standalone: true}" maxlength="50"></textarea>
                                <div style="margin-top: 11px">
                                    <button type="submit" class="btn btn-info mr-2" (click)="rejectKyc()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- reject_modal End -->
<!-- view Document -->
<div class="modal fade global-modal reset-modal" id="documentModal">
    <div class="modal-dialog">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h2 class="text-center">{{sideName}} Image</h2>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <img [src]="imgSrc" alt="" style="width: 25em;">
                                <div style="margin-top: 10px;">
                                   
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>