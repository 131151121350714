<main class="middle-content">
    <!-- Page Title Start -->
    <div class="page_title_block">
        <h1 class="page_title">Add Admin</h1>
    </div> 
    <!-- Page Title End -->
    <div class="content-section">
        <div class="outer-box" [formGroup]="addForm">
            <div class="custom_tabs common-tabs">
                <div class="tab-content">
                    <div class=" staff row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label class="col-md-4">First Name </label>
                                <span class="col-1">:</span>
                                <div class="col-md-7">
                                    <input maxlength="60" class="form-control" formControlName="firstName"
                                        (keypress)="service.preventSpace($event)" type="text"
                                        placeholder="First Name">
                                    <div class="text-danger"
                                        *ngIf="addForm.get('firstName').invalid && (addForm.get('firstName').dirty  || addForm.get('firstName').touched)">
                                        <span *ngIf="addForm.get('firstName').hasError('required')">*Please enter first name.</span>
                                        <span *ngIf="addForm.get('firstName').hasError('pattern')">*Please enter
                                            valid name.</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-4">Last Name </label>
                                <span class="col-1">:</span>
                                <div class="col-md-7">
                                    <input maxlength="60" class="form-control" formControlName="lastName"
                                        (keypress)="service.preventSpace($event)" type="text"
                                        placeholder="Last Name ">
                                    <div class="text-danger"
                                        *ngIf="addForm.get('lastName').invalid && (addForm.get('lastName').dirty  || addForm.get('lastName').touched)">
                                        <span *ngIf="addForm.get('lastName').hasError('required')">**Please enter last name.</span>
                                        <span *ngIf="addForm.get('lastName').hasError('pattern')">*Please enter
                                            valid name.</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-4">Email </label>
                                <span class="col-1">:</span>
                                <div class="col-md-7">
                                    <input class="form-control" formControlName="email"
                                        (keypress)="service.preventSpace($event)" type="text"
                                        placeholder="Email">
                                    <div class="text-danger"
                                        *ngIf="addForm.get('email').invalid && (addForm.get('email').dirty  || addForm.get('email').touched)">
                                        <span *ngIf="addForm.get('email').hasError('required')">**Please enter email.</span>
                                        <span *ngIf="addForm.get('email').hasError('pattern')">*Please enter
                                            valid email.</span>
                                    </div>
                                </div>
                            </div>
                         </div>
                         <div class="col-md-6" style="padding-right: 30px;">
                             <div class="form-group row">
                                <label class="col-md-4">Mobile Number</label>
                                <span class="col-1">:</span>
                                <div class="col-md-7" style="padding-left: 1px;padding-right: 1px;">
                                    <input maxlength="10" class="form-control" formControlName="phoneNumber"
                                        (keypress)="service.preventSpace($event)" type="text"
                                        placeholder="Mobile Number">
                                    <div class="text-danger"
                                        *ngIf="addForm.get('phoneNumber').invalid && (addForm.get('phoneNumber').dirty  || addForm.get('phoneNumber').touched)">
                                        <span *ngIf="addForm.get('phoneNumber').hasError('required')">**Please enter mobile number.</span>
                                        <span *ngIf="addForm.get('phoneNumber').hasError('pattern')">*Please enter
                                            valid number.</span>
                                    </div>
                                </div>
                             </div>
                             <div class="form-group row">
                                <label class="col-md-4">Gender </label>
                                <span class="col-1">:</span>
                                <select class="col-md-7 form-control" formControlName="gender">
                                    <option value="">Gender</option>
                                    <option value="MALE">Male</option>
                                    <option value="FEMALE">Female</option>
                                </select>
                             </div>
                             <div class="form-group row">
                                <label class="col-md-4">Role </label>
                                <span class="col-md-1">:</span>
                                <select class="col-md-7 form-control" formControlName="role">
                                    <option value="">Options</option>
                                    <option value="SUBADMIN">Sub Admin</option>
                                </select>
                             </div>
                         </div>
                    </div>
                       
                    </div> 
                    <table aria-describedby="" style="margin-top: 1%;">
                        <tr>
                            <th id=""  style="text-align: center;padding: 15px 0 15px 0;width: 69%;">Permission</th>
                            <th id="" style="text-align: center;padding: 15px 0 15px 0;">Operations</th>
                        </tr>
                        <tr>
                            <td class="permiss">Dashboard</td>

                            <td class="check">
                                <input type="checkbox" (change)="checkboxClick('DASHBOARD',$event.target.checked)">
                            </td>
                        </tr>
                        <tr>
                            <td class="permiss">Staff Management</td>
                            <td class="check">
                                <input type="checkbox"
                                    (change)="checkboxClick('STAFF_MANAGEMENT',$event.target.checked)">
                            </td>
                        </tr>
                        <tr>
                            <td class="permiss">User Management</td>
                            <td class="check">
                                <input type="checkbox"
                                    (change)="checkboxClick('USER_MANAGEMENT',$event.target.checked)">
                            </td>
                        </tr>

                        <tr>
                            <td class="permiss">Hot Wallet Management</td>
                            <td class="check">
                                <input type="checkbox"
                                    (change)="checkboxClick('HOT_COLD_LIMIT_MANAGEMENT',$event.target.checked)">
                            </td>
                        </tr>
                        <tr>
                            <td class="permiss">KYC Management</td>
                            <td class="check">
                                <input type="checkbox" (change)="checkboxClick('KYC_MANAGEMENT',$event.target.checked)">
                            </td>
                        </tr>
                        <tr>
                            <td class="permiss">Wallet Management</td>
                            <td class="check">
                                <input type="checkbox"
                                    (change)="checkboxClick('WALLET_MANAGEMENT',$event.target.checked)">
                            </td>
                        </tr>
                        <tr>
                            <td class="permiss">Static Content Management</td>
                            <td class="check">
                                <input type="checkbox" (change)="checkboxClick('STATIC_CONTENT',$event.target.checked)">
                            </td>
                        </tr>
                        <tr>
                            <td class="permiss">Logs Management</td>
                            <td class="check">
                                <input type="checkbox"
                                    (change)="checkboxClick('LOGS_MANAGEMENT',$event.target.checked)">
                            </td>
                        </tr>
                        <tr>
                            <td class="permiss">Ticket Management</td>
                            <td class="check">
                                <input type="checkbox"
                                    (change)="checkboxClick('TICKET_MANAGEMENT',$event.target.checked)">
                            </td>
                        </tr>

                        <tr>
                            <td class="permiss">Trade Management</td>
                            <td class="check">
                                <input type="checkbox"
                                    (change)="checkboxClick('TRADE_MANAGEMENT',$event.target.checked)">
                            </td>
                        </tr>
                        <tr>
                            <td class="permiss">Dispute Management</td>
                            <td class="check">
                                <input type="checkbox"
                                    (change)="checkboxClick('DISPUTE_MANAGEMENT',$event.target.checked)">
                            </td>
                        </tr>
                        <tr>
                            <td class="permiss">Bank Management</td>
                            <td class="check">
                                <input type="checkbox"
                                    (change)="checkboxClick('BANK_MANAGEMENT',$event.target.checked)">
                            </td>
                        </tr>
                        <tr>
                            <td class="permiss">Fee Management</td>
                            <td class="check">
                                <input type="checkbox" (change)="checkboxClick('FEE_MANAGEMENT',$event.target.checked)">
                            </td>
                        </tr>
                    </table>
                    &nbsp;
                    <div class="mb-4" style="text-align: center">
                        <button class="btn  btn-theme" type="button" [disabled]="addForm.invalid"
                            (click)="addStaff()">Submit</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Table Responsive End -->

</main>