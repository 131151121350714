import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from '../service.service';
declare var $
@Component({
  selector: 'app-ticket-details',
  templateUrl: './ticket-details.component.html',
  styleUrls: ['./ticket-details.component.css']
})
export class TicketDetailsComponent implements OnInit {
  ticketId: any;
  ticketDetails: any;
  imgSrc : any
  mail : any = "mailto:xyz@example.com?Subject=tanveer&body=links:  %0D http://link1.com  %0D http://link1.com "
  constructor(private activatedRoute: ActivatedRoute, private router: Router, public service: ServiceService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((res) => {
      console.log(res);
      if (res.id) {
        this.ticketId = res.id
        this.getTicketDetails()
      }
    })
  }

  getTicketDetails() {
    this.service.showSpinner();
    this.service.get(`static/view-ticket-detail?ticketId=${this.ticketId}`).subscribe((res) => {
      console.log(res);
      this.service.hideSpinner()
      if (res['status'] == 200) {
        this.ticketDetails = res['data']
        this.service.toasterSucc(res['message']);
      } else {
        this.service.toasterErr(res['message'])
      }
    }, err => {
      console.log(err);
      this.service.hideSpinner();
      this.service.toasterErr(err['message'])
    })
  }

  back() {
    this.router.navigate(['/ticket-management'])
  }
  reply(){
    $('#replyModal').modal('show')
  }
  replyUser(email,subject,body){
    var mail = document.createElement("a");
   
    mail.href = `mailto:${email}?Subject=${subject}&body=${body}`
    mail.click()
    // this.service.toasterSucc("Replied Successfully")
    $('#replyModal').modal('hide')
  return
    this.service.showSpinner();
    this.service.get(`static/view-ticket-detail?ticketId=${this.ticketId}`).subscribe((res) => {
      console.log(res);
      this.service.hideSpinner()
      if (res['status'] == 200) {
        this.ticketDetails = res['data']
        this.service.toasterSucc(res['message']);
      } else {
        this.service.toasterErr(res['message'])
      }
    }, err => {
      console.log(err);
      this.service.hideSpinner();
      this.service.toasterErr(err['message'])
    })
  }
  viewDoc(img){
    
    this.imgSrc = img
    $('#documentModal').modal('show')
  }
}
