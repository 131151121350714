<main class="middle-content" style="margin-top:-3%;">
    <!-- Page Title Start -->
    <div class="page_title_block">
        <h1 class="page_title">Add Sub Admin</h1>
    </div>
    <!-- Page Title End -->
    <div class="content-section">
        <div class="outer-box" [formGroup]="addForm">
            <div class="custom_tabs common-tabs">
                <div class="tab-content">
                    <div class="staff row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="Fname" class="col-md-4">First Name </label>
                                <label class="col-md-1"> :</label>
                                <div class="col-md-7">
                                    <input maxlength="15" id="Fname" placeholder="First Name" class="form-control"
                                        formControlName="firstName" (keypress)="service.preventSpace($event)"
                                        type="text">
                                    <div class="text-danger"
                                        *ngIf="addForm.get('firstName').invalid && (addForm.get('firstName').dirty  || addForm.get('firstName').touched)">
                                        <span *ngIf="addForm.get('firstName').hasError('required')">* Please enter first
                                            name.</span>
                                        <span *ngIf="addForm.get('firstName').hasError('pattern')">*Please enter
                                            valid name.</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="Lname" class="col-md-4">Last Name </label>
                                <label class="col-md-1"> :</label>
                                <div class="col-md-7">
                                    <input maxlength="15" id="Lname" placeholder="Last Name" class="form-control"
                                        formControlName="lastName" (keypress)="service.preventSpace($event)"
                                        type="text">
                                    <div class="text-danger"
                                        *ngIf="addForm.get('lastName').invalid && (addForm.get('lastName').dirty  || addForm.get('lastName').touched)">
                                        <span *ngIf="addForm.get('lastName').hasError('required')">*Please enter last
                                            name
                                            .</span>
                                        <span *ngIf="addForm.get('lastName').hasError('pattern')">*Please enter
                                            valid name.</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="email" class="col-md-4">Email </label>
                                <label class="col-md-1"> :</label>
                                <div class="col-md-7">
                                    <input id="email" placeholder="Email" class="form-control" formControlName="email"
                                        (keypress)="service.preventSpace($event)" type="text">
                                    <div class="text-danger"
                                        *ngIf="addForm.get('email').invalid && (addForm.get('email').dirty  || addForm.get('email').touched)">
                                        <span *ngIf="addForm.get('email').hasError('required')">*Please enter
                                            email.</span>
                                        <span *ngIf="addForm.get('email').hasError('pattern')">*Please enter
                                            valid email.</span>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="mobile" class="col-md-4">Mobile Number </label>
                                <label class="col-md-1"> :</label>
                                <div class="col-md-7">
                                    <input maxlength="10" id="mobile" placeholder="Mobile Number" class="form-control"
                                        formControlName="phoneNumber" (keypress)="service.preventSpace($event)"
                                        type="text">
                                    <div class="text-danger"
                                        *ngIf="addForm.get('phoneNumber').invalid && (addForm.get('phoneNumber').dirty  || addForm.get('phoneNumber').touched)">
                                        <span *ngIf="addForm.get('phoneNumber').hasError('required')">*Please enter
                                            mobile number.</span>
                                        <span *ngIf="addForm.get('phoneNumber').hasError('pattern')">*Please enter
                                            valid number.</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">

                                <label for="GNDR" class="col-md-4">Gender </label>
                                <label class="col-md-1"> :</label>
                                <div class="col-md-7">
                                    <select class="form-control" id="GNDR" formControlName="gender">
                                        <option value="">Gender</option>
                                        <option value="MALE">Male</option>
                                        <option value="FEMALE">Female</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="ROLE" class="col-md-4">Role </label>
                                <label class="col-md-1"> :</label>
                                <div class="col-md-7">
                                    <select id="ROLE" class="form-control" formControlName="role">
                                        <option value="">Options</option>
                                        <option value="SUBADMIN">Staff</option>

                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                   
                    <div class="text-center" style="margin-top: 3%;">
                        <h1 class="page_title">Permissions</h1>
                    </div>

                    <div class="row" style="margin-top: 3%;">

                        <div class="col-md-4" *ngFor="let item of permissionArr ; let i = index">
                            <label class="globalCheckBox" style="text-transform:capitalize;"> {{name[i] | lowercase}}
                                <input type="checkbox" [(ngModel)]="check[i]" [ngModelOptions]="{standalone: true}"
                                    (click)="checkPerm(item,!check[i])">
                                <span class="checkmark"></span>
                            </label>
                        </div>

                    </div>
                    &nbsp;
                    <div style="text-align: center;margin-bottom: 2%;">
                        <button class="btn  btn-theme" type="button" [disabled]="addForm.invalid"
                            (click)="addStaff()">Submit</button>
                        <button class="btn  btn-theme" type="button" routerLink="/staff-management">Back</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Table Responsive End -->
    </div>
</main>