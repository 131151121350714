<div class="wrapper">

    <!-- Aside End -->
    <main class="middle-content">
        <!-- Page Title Start -->
        <div class="page_title_block">
            <h1 class="page_title" style="color: black">Sub Admin Management</h1>

        </div>
        <!-- Page Title End -->
        <div class="content-section">
            <div class="outer-box">
                <!-- Gloabl Table Box Start -->
                <div class="global-table no-radius p0">
                    <div class="tab-content1">
                        <div class="tab-pane1">
                            <div class="sec_head_new">
                                <div class="row">
                                    <div class="col-md-6 registration-col">
                                        <div class="filter_search  width100 registration-search">
                                            <div class="input-group filter_search_group">
                                                <input type="text" class="form-control" name="usersearch"
                                                    [(ngModel)]="searchobj.usersearch" (keyup.enter)="search()"
                                                    (keyup)="clearSearch()" placeholder="Search by username , email">
                                                <div class="input-group-append">
                                                    <button class="btn btn_search_group" type="button"><img
                                                            src="assets/img/icon-search.png" alt="Search"></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-xl-2">
                                        <div class="text-left">
                                            <button type="submit" class="btn  btn-theme"
                                                (click)="search()">Search</button>
                                        </div>
                                    </div>
                                    <div class="col-md-3  col-xl-2">
                                        <div class="text-left">
                                            <button type="submit" (click)="createsub()"
                                                class="btn  btn-theme create-subadmin">Create Sub Admin</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="table-responsive">
                                <table class="table table-bordered" aria-describedby="sub-admin management table">
                                    <thead>
                                        <tr class="no_wrap_th">
                                            <th scope="col">User ID</th>
                                            <th scope="col">User Name</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Phone Number</th>
                                            <th scope="col">Created Date</th>
                                            <th scope="col" class="action_td_btn3">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let user of userList | paginate:{itemsPerPage: 10, currentPage: pageNumber,totalItems: userList.length} ; let i=index;">
                                            <td>{{user.userId}}</td>
                                            <td>{{user.firstName}}</td>
                                            <td>{{user.email}}</td>
                                            <td>{{user.phoneNo}}</td>
                                            <td>{{user.createTime | date:'MM/dd/yyy, hh:mm:ss a'}}</td>
                                            <td class="action_td_btn3">
                                                <a data-toggle="modal" (click)="openModal('DELETE',user.userId)">
                                                    <i class="fa fa-trash" aria-hidden="true"></i></a>
                                                <a *ngIf="user.userStatus == 'ACTIVE'" data-toggle="modal"
                                                    (click)="openModal('BLOCK',user.userId)"><i class="fa fa-ban"
                                                        [ngClass]="{'change': user.userStatus == 'ACTIVE' }"
                                                        aria-hidden="true"></i></a>
                                                <a *ngIf="user.userStatus != 'ACTIVE'" data-toggle="modal"
                                                    (click)="openModal('ACTIVE',user.userId)"><i class="fa fa-ban"
                                                        aria-hidden="true"></i></a>
                                            </td>
                                        </tr>
                                        <tr *ngIf="userList == ''">
                                            <td colspan="6"> No User Found</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="custom-pagination mt20 text-right" *ngIf="userList.length > 10 ">
                                <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
        <!-- Table Responsive End -->

    </main>
    <!-- Middle Content End -->
    <footer>
        <div class="container-fluid">
            <p class="copyright">Copyright © 2020 <strong class="theme-text-color">CryptoBiz Exchange</strong> All
                Rights Reserved.</p>
        </div>
    </footer>
    <!-- Footer Finish -->
</div>
<!-- Wrapper End -->



<!--Modal Start-->
<div class="modal fade global-modal reset-modal" id="delet_farms_modal">
    <div class="modal-dialog max-WT-500">
        <div class="modal-content">
            <!-- Modal body -->
            <div class="modal-body  text-center">
                <div class="row align-items-center modal_flax_height">
                    <div class="col">
                        <div class="box-title mb40 d-inline-block">
                            <h2>Are you sure?</h2>
                            <p>You won’t be able to revert this!</p>
                        </div>
                        <div class="max-WT-300 d-inline-block border-top">
                            <button type="button" class="btn btn-gray btn-large radius0 btn-block">YES</button>
                            <button type="button" class="btn btn-red btn-large radius0 btn-block"
                                data-dismiss="modal">CANCEL</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- signout_modal Start -->


<!-- invite_modal End -->
<!-- invite_modal Start -->
<div class="modal fade global-modal reset-modal" id="accept">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-header ">
                        <h4 class="modal-title text-center">Accept</h4>
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                    </div>
                    <div class="modal-body">
                        <div class="row align-items-center modal_flax_height">
                            <div class="col">
                                <div class="form-group">
                                    <div class="d-flex align-items-center text-center justify-content-center">
                                        <label class="switch">
                                            <input type="checkbox">
                                            <span class="slider round"></span>
                                        </label>
                                        <strong> R/W Accesbility</strong>
                                    </div>
                                </div>
                                <hr>
                                <div class="form-group">
                                    <div class="d-flex align-items-center">
                                        <div class="w-100">
                                            <h6>User Type</h6>

                                            <div class="radio_btn_cst d-flex">
                                                <label class="radio_style">End User
                                                    <input type="radio" checked="checked" name="radio" />
                                                    <span class="checkmark"></span>
                                                </label>
                                                <label class="radio_style">Service User
                                                    <input type="radio" name="radio" />
                                                    <span class="checkmark"></span>
                                                </label>
                                                <label class="radio_style">Admin User
                                                    <input type="radio" name="radio" />
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="w-100">
                                        <h6>Role</h6>
                                        <select name="" class="form-control form-control2 select_opt">
                                            <option value="">Select</option>
                                            <option value="" selected>Abuja region</option>
                                            <option value="">Nigeria region</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="d-flex align-items-center flex-wrap text-center justify-content-center">
                                        <div class="w-50 d-inline-flex align-items-center">
                                            <label class="switch">
                                                <input type="checkbox">
                                                <span class="slider round"></span>
                                            </label>
                                            <span> Admin Rights</span>
                                        </div>
                                        <div class="w-50 d-inline-flex align-items-center">
                                            <label class="switch">
                                                <input type="checkbox">
                                                <span class="slider round"></span>
                                            </label>
                                            <span> Approved</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt20 text-center">
                                    <button class="btn btn-small btn-info">Set</button>
                                    <button class="btn btn-small btn-danger" data-dismiss="modal">Cancel</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- invite_modal End -->

<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <h3 style="text-align: center;padding: 8px;">Delete Sub Admin </h3>
                    <hr>
                    <button type="button" class="close" data-dismiss="modal" style="margin-top: -83px;
                        padding: 11px;">&times;</button>

                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to delete this sub admin?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2"
                                        (click)="deleteFunction()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- delete_modal End -->
<!-- block_modal Start -->

<div class="modal fade global-modal reset-modal" id="active">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <h3 style="text-align: center;padding: 8px;">Active Sub Admin </h3>
                    <hr>
                    <button type="button" class="close" data-dismiss="modal" style="margin-top: -83px;
                        padding: 11px;">&times;</button>

                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to active this sub admin?</p>
                                <div>
                                    <button type="submit" class="btn btn-success mr-2"
                                        (click)="performAction()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- block_modal Start -->
<div class="modal fade global-modal reset-modal" id="block">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <h3 style="text-align: center;padding: 8px;">Block Sub Admin </h3>
                    <hr>
                    <button type="button" class="close" data-dismiss="modal" style="margin-top: -83px;
                    padding: 11px;">&times;</button>

                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to block this sub admin?</p>
                                <div>
                                    <button type="submit" class="btn btn-success mr-2"
                                        (click)="performAction()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>