<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title">Announcement Management</h1>
    </div>
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">

                        <!-- common search section -->
                        <div class="sec_head_new">
                            <div class="row">
                                <div class="col-md-2" style="padding-left: 0px;">
                                    <div class="head_flt_select">
                                        <div class="head_flt_select input-label input-label1">
                                            <span class="d-flex align-items-center">Status</span>
                                            <select class="form-control" #status id="status">
                                                <option value="" placeholder="Select">Select</option>
                                                <option value="BTC">BTC</option>
                                                <option value="ETC">ETC</option>
                                                <option value="ETH">ETH</option>
                                                <option value="QTE">QTE</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2" style="padding-left: 0px;">
                                    <div class="head_flt_select">
                                        <div class="head_flt_select input-label input-label1">
                                            <span class="d-flex align-items-center">Start Date</span>
                                            <input type="date" name="fromdate" [max]="service.getToday()" #startDate
                                                (change)="getStartDate(startDate.value)" class="form-control datepicker"
                                                placeholder="Search by date">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2" style="padding-left: 0px;">
                                    <div class="head_flt_select">
                                        <div class="head_flt_select input-label input-label1">
                                            <span class="d-flex align-items-center">End Date</span>
                                            <input type="date" name="todate" #endDate [min]="startDate.value"
                                                class="form-control datepicker" placeholder="Search by date" id="eDate">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-3 ">
                                    <div class="user-frmbtn" style="display: flex; float: right;">
                                        <button type="submit"
                                            (click)="search(status.value,startDate.value,endDate.value)"
                                            class="btn btn-theme">Search</button>&nbsp;&nbsp;
                                        <button type="submit" (click)="reset()" class="btn btn-theme">Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- second row -->
                        <!-- <div class="row mt-3">
                                <div class="col-md-6 col-xl-12 col-lg-4 mt10">
                                    <div class="text-left" style="float: right;">
                                        <button type="submit" class="btn btn-theme" (click)="exportAsXLXS()">EXPORT AS EXCEL</button>
                                    </div>
                                </div>
                            </div> -->
                        <!-- </div> -->
                        <!-- end common search section -->
                        <div class="col-md-12">
                            <div class="admin-btn pull-right">
                                <button type="button" class="btn  btn-theme" routerLink="/add-anouncemant"
                                    style="margin-right : -15px;margin-bottom : 8px">Add Announcement</button>
                            </div>
                        </div>
                        <!-- table for customer -->
                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="trade table">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th id="">S.No</th>
                                        <th id="">Announcement Title</th>
                                        <th id="">Announcement Tag</th>
                                        <th id="">Created At</th>
                                        <!-- <th id="">Request Date & Time</th> -->
                                        <!-- <th id="">Status</th> -->
                                        <th id="">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let data of anoncementData | paginate:{itemsPerPage: itemsPerPage, currentPage: currentPage,totalItems: totalItems}; let i=index; ">

                                        <td>{{itemsPerPage*(currentPage-1)+i+1}}</td>
                                        <td>
                                            <div class="text-wrap max-WT-700">{{data?.title || 'N/A'}}</div>
                                        </td>
                                        <td>{{data?.coinName || 'N/A'}}</td>
                                        <td class="min-wt-180">{{(data?.createdTime | date:"medium") || 'N/A'}}</td>
                                        <!-- <td>{{data?.ticketStatus || 'N/A'}}</td> -->

                                        <td>
                                            <div class="action-btn-alignment">
                                                <a class="view-icon"><em class="fa fa-eye"
                                                        (click)="viewAnouncement(data?.announcementId)"></em></a>


                                                <a class="view-icon ml-2"
                                                    *ngIf="data?.announcementStatus == 'ACTIVE'"><em class="fa fa-ban text-success"
                                                        (click)="getBannerID(data?.announcementId,1)"></em></a>


                                                <a class="view-icon ml-2"
                                                    *ngIf="data?.announcementStatus == 'BLOCK'"><em class="fa fa-ban text-danger"
                                                        (click)="getBannerID(data?.announcementId,2)"></em></a>


                                                <a data-toggle="modal" class="pointer ml-2"
                                                    (click)="editAnouncement(data?.announcementId,3)"><em
                                                        class="fa fa-edit" title="View"></em></a>


                                                <a data-toggle="modal" (click)="getBannerID(data?.announcementId)"
                                                    class="pointer ml-2"><em class="fa fa-trash" title="View"></em></a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="anoncementData?.length==0">
                                        <td colspan="11" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <H5>No record found</H5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- end table for customer -->
                        <div class="custom-pagination mt20 text-center" style="float: right;"
                            *ngIf="totalItems > itemsPerPage ">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

<!-- Modal  -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <div class="modal-body">
                        <h5 style="text-align: center; ">Delete announcement</h5>
                        <div class="modal-inner-content">
                            <div class="modal-body">
                                <div
                                    class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                                    <div class="w-100">
                                        <p>Are you sure you want to delete this announcement?</p>
                                        <div>
                                            <button type="submit" class="btn btn-info mr-2"
                                                (click)="deleteAnnouncement()">Yes</button>
                                            <button type="button" class="btn btn-danger"
                                                data-dismiss="modal">No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- --=-=-=-=-=-=-=-=-=-Active Modal =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-==-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= -->
<div class="modal fade global-modal reset-modal" id="activeModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <div class="modal-body">
                        <h5 style="text-align: center; ">Active announcement</h5>
                        <div class="modal-inner-content">
                            <div class="modal-body">
                                <div
                                    class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                                    <div class="w-100">
                                        <p>Are you sure you want to active this announcement?</p>
                                        <div>
                                            <button type="submit" class="btn btn-info mr-2"
                                                (click)="activeAnnouncement()">Yes</button>
                                            <button type="button" class="btn btn-danger"
                                                data-dismiss="modal">No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<!-- -=-=-=-=-==-=-=Block-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-==-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= -->

<div class="modal fade global-modal reset-modal" id="blockModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <div class="modal-body">
                        <h5 style="text-align: center; ">Block announcement</h5>
                        <div class="modal-inner-content">
                            <div class="modal-body">
                                <div
                                    class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                                    <div class="w-100">
                                        <p>Are you sure you want to block this announcement?</p>
                                        <div>
                                            <button type="submit" class="btn btn-info mr-2"
                                                (click)="blockAnnouncement()">Yes</button>
                                            <button type="button" class="btn btn-danger"
                                                data-dismiss="modal">No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>