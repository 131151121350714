import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { ServiceService } from '../service.service';
declare var $: any;

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.css']
})
export class SidemenuComponent implements OnInit {
  currUrl: string;
  isLoggedIn: boolean;
  userDetail: any = {};
  userID: any;
  previlage: any ;
  getperm: any;
  getrole: any;
  getpermission: any;
 
  role: string;
  flag: boolean = false;
  adminmgmt: boolean = false;
  settingmgmt: boolean = false;
  advertisementmgmt: boolean = false;
  reset: boolean;

  constructor(private routes: Router, public service: ServiceService) {
    routes.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currUrl = event.url.split('/')[1];
        if (this.service.isLoggedIn()) {
          if ((this.currUrl == `login` || this.currUrl == `forgot-password` || this.currUrl?.includes("`reset-password`") || this.currUrl == ``)) {
            this.routes.navigate([`/dashboard`])
          }
        } else {
          if (!(this.currUrl == `login` || this.currUrl == `forgot-password` || this.currUrl == (`reset-password`) || this.currUrl == ``)) {
            this.routes.navigate([`/login`])
          }
        }
      }
    })
  }

  ngOnInit() {
    // login or when profile edit
    this.service.loginObs.subscribe(response => {
      if (response == 'login') {
        this.isLoggedIn = true;
        this.myProfile();
      }
      else {
        this.isLoggedIn = false;
      }
    })

    // when page refresh
    if (this.service.isLoggedIn()) {
      this.isLoggedIn = true;
      this.myProfile();
    } else {
      this.isLoggedIn = false;
    }
  }

  // get profile
  myProfile() {
    var url = 'account/my-account';
    // this.service.showSpinner();
    this.service.get(url).subscribe(res => {
      // this.service.hideSpinner();
      if (res['status'] == 200) {
        this.userDetail = res['data'];
        this.userID = res['data'].userId;
        console.log(res['data']['role']);
        if(res['data']['role'] == "ADMIN"){
          var a = []
          a =  ["DASHBOARD_MANAGEMENT","USER_MANAGEMENT" , "SUB_ADMIN_MANAGEMENT",
          "HOT_COLD_WALLET_MANAGEMENT", "KYC_MANAGEMENT", "WALLET_MANAGEMENT","BUY_SELL_MANAGEMENT",
          "FEE_MANAGEMENT","HELP_MANAGEMENT",
          ,"BANNER_MANAGEMENT","INSTITUTIONAL_INVESTOR_MANAGEMENT","STATTIC_CONTENT_MANAGEMENT"]
          this.previlage = String(a);
        }
        else{
          this.previlage = String(res['data']['previlage']);
        }
        
        console.log("-------------", this.previlage);
        
        
       
        localStorage.setItem('userId', this.userID);
     
        localStorage.setItem('usertype', this.role);
       
      
        
       
       
        
       
      } else {
        this.service.toasterErr(res['message']);
      }
    }, err => {
      // this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access.');
      } else {
        this.service.toasterErr('Something Went Wrong.');
      }
    })
  }

  // logout
  logoutModalOpen() {
    $('#logoutModal').modal('show');
  }

  onLogout() {
    $('#logoutModal').modal('hide');
    this.service.onLogout();
    window.location.reload();
    
  }

  ready() {
    $("#toggle_Menu").click(function () {
      $(".body-class").toggleClass("toggle-wrapper")
    });
  }


}
