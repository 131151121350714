import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { ServiceService } from "src/app/service.service";

@Component({
  selector: "app-limitation-management",
  templateUrl: "./limitation-management.component.html",
  styleUrls: ["./limitation-management.component.css"],
})
export class LimitationManagementComponent implements OnInit {
  constructor(private router: Router, public service: ServiceService) {}

  feecoinArry = [
    {
      coinShortName: "Fiat Deposit Limit",
      withdrawlFee : '50k',

    },
    {
      coinShortName: "Crypto Withdraw Limit",
      withdrawlFee : '50k',

    },
    {
      coinShortName: "Crypto Deposit Limit",
      withdrawlFee : '50k',

    },
    {
      coinShortName: "P2P transaction Limit",
      withdrawlFee : '50k',

    },
  ];

  ngOnInit() {

   
  
  }

  
  updatefeeapi(coinName) {
   this.service.toasterSucc(`${coinName} updated successfully`)
  }
  
}
