<div class="login-wrapper">
    <div class="container-common">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <form class="login_box_outer" [formGroup]="forgotPassword">
                    <div class="login-box max-WT-520">
                        <div class="login-right-block">
                            <div class="text-center">
                                <img src="src/assets/img/dark logo.png" alt="crypto-logo"  >
                            </div>
                            <!-- <img src="assets\img\crypto-logo.png" alt="crypto-logo" > -->
                            <div class="login-heading">
                                <h4 style="color:white;margin-top: 5%;">FORGOT PASSWORD?</h4>
                            </div>
                            <div class="login-box-body">
                                <p class="common-paragrph text-center" style="color: #b4b9bc;;">Please enter a registered email address so that
                                    we can send you reset instruction.</p>
                                <div class="form-group">
                                    <input type="email" formControlName="email" class="form-control"
                                        placeholder="Email Address" maxlength="60" />
                                    <span class="error">
                                        <p *ngIf="forgotPassword.get('email').hasError('pattern') && forgotPassword.get('email').dirty"
                                            class="error" padding>*Please enter valid email address.</p>
                                    </span>
                                </div>
                                <div class="form-group text-center">
                                    <button type="submit" class="btn btn-login btn-small  width100 font-100 mr-2" [disabled]="!forgotPassword.valid"
                                        (click)="onForgot()">SUBMIT</button>
                                    <button type="submit" class="btn btn-login btn-small  width100 font-100"
                                        [routerLink]="['/login']">BACK</button>
                                </div>
                            </div>
                            <a (click)="onResend()" class="text-center mt20 reset-link">Resend Link ?</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="footer">
    <p class="copyright">Copyright © 2021 <strong class="theme-text-color">Crypto Exchange & QTE Token</strong> All Rights Reserved.</p>
</div>