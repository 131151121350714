<main class="middle-content" id="view">
    <!-- Page Title Start -->
    <div class="page_title_block">
        <h1 class="page_title">User Details</h1>

    </div>
    <div class="custom_tabs common-tabs">
        <div class="row mb20 justify-content-center">
            <div class="col-sm-12">
                <ul class="nav nav-tabs d-flex  justify-content-center text-center">
                    <li class="nav-item flex-fill"> <a class="nav-link active show"
                            [ngClass]="{'active': currTab=='general'}" (click)="selectTab('general')"
                            href="javascript:;">General Information</a>
                    </li>
                    <li class="nav-item flex-fill"> <a class="nav-link active show"
                            [ngClass]="{'active': currTab=='wallet'}" (click)="selectTab('wallet')"
                            href="javascript:;">Wallet</a>
                    </li>
                    <li class="nav-item flex-fill"> <a class="nav-link active show"
                            [ngClass]="{'active': currTab=='trading'}" (click)="selectTab('trading')"
                            href="javascript:;">Trading</a>
                    </li>
                    <li class="nav-item flex-fill"> <a class="nav-link active show"
                            [ngClass]="{'active': currTab=='login'}" (click)="selectTab('login')"
                            href="javascript:;">Login Session Activity</a>
                    </li>
                </ul>
            </div>
        </div>
        <!-- Page Title End -->
        <div class="content-section">
            <div class="tab-pane active show" *ngIf="this.currTab == 'general'">
                <div class="order-view mt30 mb20 max-WT-700 mrgn-0-auto">
                    <div class="main-block-innner mb40 mt40">
                        <div class="add-store-block1 input-style ">
                            <div class="form-group row">
                                <div class="col-md-12" style="text-align: center">
                                    <img [src]="profile?profile:'assets/img/testimonial-img1.jpg'" alt=""
                                        style="height: 100px; border-radius: 52%;">
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-4">Name</label>
                                <div class="col-md-2">:</div>
                                <div class="col-md-6">
                                    <span>{{userDetail?.firstName}} {{userDetail?.lastName}}</span>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-4">Email</label>
                                <div class="col-md-2">:</div>
                                <div class="col-md-6">
                                    <span class="user-eml">{{userDetail?.email || '---'}}</span>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-4">Mobile Number</label>
                                <div class="col-md-2">:</div>
                                <div class="col-md-6">
                                    <span>{{userDetail?.phoneNo || '---'}}</span>
                                </div>
                            </div>


                            <div class="form-group row align-items-baseline">
                                <label class="col-md-4">2FA Security</label>
                                <div class="col-md-2">:</div>
                                <div class="col-md-6">
                                    <span> {{userDetail?.twoFaType || '----'}}</span>
                                </div>
                            </div>

                            <div class="form-group row align-items-baseline"
                                style="word-break: break-all;">
                                <label class="col-md-4">Address </label>
                                <div class="col-md-2">:</div>
                                <div class="col-md-6">
                                    <span>{{userDetail?.address || '---'}}</span>
                                </div>
                            </div>

                            <div class="form-group row align-items-baseline">
                                <label class="col-md-4">KYC Doc </label>
                                <div class="col-md-2">:</div>
                                <div class="col-md-6 zoom" *ngIf="userDetail?.kyc?.document[0].backIdUrl!=null">
                                    <img src="{{userDetail?.kyc?.document[0].backIdUrl}}" alt="">
                                </div>
                                <div class="col-md-6" *ngIf="userDetail?.kyc?.document[0].backIdUrl==null">
                                    No Document
                                </div>

                            </div>
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-4">KYC Doc </label>
                                <div class="col-md-2">:</div>
                                <div class="col-md-6 zoom" *ngIf="userDetail?.kyc?.document[0].frontIdUrl!=null">
                                    <img src="{{userDetail?.kyc?.document[0].frontIdUrl}}" alt="">
                                </div>
                                <div class="col-md-6" *ngIf="userDetail?.kyc?.document[0].frontIdUrl==null">
                                    No Document
                                </div>
                            </div>
                        </div>
                        <div class="text-left mt40 text-center">
                            <a (click)="back()" class="btn btn-large  max-WT-100 font-100 btn-green">Back</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane active show" *ngIf="this.currTab == 'wallet'">
                <div class="row mb20 justify-content-center" *ngIf="!viewTradingg">
                    <div style="width: 20%;margin-right: 74.5%;">
                        <select name="" id="" (change)="selectTabb($event)" class="form-control">
                            <option *ngFor="let coin of coinlist" [value]=coin?.coinShortName>{{coin?.coinShortName ||
                                'N/A'}}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="!viewTradingg">
                    <div class="tab-pane active show" *ngIf="this.currTabb == 'BTC'">
                        <div class="content-section">
                            <div class="outer-box">
                                <div class="custom_tabs common-tabs">
                                    <div class="tab-content">
                                        <div class="tab-pane active show" id="btc">
                                            <div class="tab-pane1">
                                                <div class="table-responsive">
                                                    <table class="table table-bordered" aria-describedby="">
                                                        <thead>
                                                            <tr class="no_wrap_th">
                                                                <th id="">ID</th>
                                                                <th id="">Coin</th>
                                                                <th id="">Type</th>
                                                                <th id="">Amount</th>
                                                                <th id="">Transaction Hash</th>
                                                                <th id="">Date & Time</th>
                                                                <th id="">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                *ngFor="let data of walletDetailsList | paginate:{itemsPerPage: 10, currentPage: pageNumber,totalItems: walletDetailsListLength}; let i=index; ">
                                                                <td>{{10 * (pageNumber - 1) + i+1}}</td>
                                                                <td>{{data?.coinType || 'N/A'}}</td>
                                                                <td>{{data?.txnType || 'N/A'}}</td>
                                                                <td>{{data?.amount || 'N/A'}}</td>
                                                                <td>
                                                                    <div *ngIf="data?.txnHash!=null">
                                                                        {{data?.txnHash || 'N/A'}}<i class="fa fa-clone pointer"
                                                                            aria-hidden="true"
                                                                            style="margin-left: 2%;"
                                                                            (click)="copyToClipboard(data.txnHash)"></i>
                                                                    </div>
                                                                    <div *ngIf="data?.txnHash==null">
                                                                        N/A
                                                                    </div>
                                                                </td>
                                                                <td>{{data?.txnTime | date:"medium"}}</td>
                                                                <td class="action_td_btn3">
                                                                    <a data-toggle="modal" class="pointer" 
                                                                        (click)="viewCoinDetails(data?.txnId)"><em
                                                                            class="fa fa-eye"></em></a>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="walletDetailsListLength==0">
                                                                <td colspan="10" vertical-align="middle">
                                                                    <div class="no-record">
                                                                        <div class="no-recordin">
                                                                            <H5>No record found
                                                                            </H5>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="custom-pagination mt20 text-center"
                                                    style="    float: right;">

                                                    <pagination-controls (pageChange)="pagination($event)">
                                                    </pagination-controls>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <!-- Table Responsive End -->
                        </div>
                    </div>
                </div>
                <div *ngIf="viewTradingg">
                    <div>
                        <div class="order-view mt30 mb20 max-WT-700 mrgn-0-auto">
                            <div class="main-block-innner mb40 mt40">
                                <div class="form-group row">
                                    <div class="col-md-6 pL20-percent">
                                        <label>Transaction Id</label>
                                    </div>
                                    <div class="col-md-6" style="word-break:break-all;">{{coinList?.txnHash || 'N/A'}}
                                    </div>

                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 pL20-percent">
                                        <label>Coin</label>
                                    </div>
                                    <div class="col-md-6">{{coinList?.coinType || 'N/A'}}</div>

                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 pL20-percent">
                                        <label>Wallet Address</label>
                                    </div>
                                    <div class="col-md-6">{{coinList?.address || 'N/A'}}</div>

                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 pL20-percent">
                                        <label>Amount</label>
                                    </div>
                                    <div class="col-md-6">{{coinList?.units || 'N/A'}}</div>

                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 pL20-percent">
                                        <label>Type</label>
                                    </div>
                                    <div class="col-md-6">{{coinList?.txnType || 'N/A'}}</div>

                                </div>

                                <div class="form-group row">
                                    <div class="col-md-6 pL20-percent">
                                        <label>Transaction</label>
                                    </div>
                                    <div class="col-md-6">{{coinList?.txnType || 'N/A'}}</div>

                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 pL20-percent">
                                        <label>User Name</label>
                                    </div>
                                    <div class="col-md-6">{{coinList?.userName || 'N/A'}}</div>

                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 pL20-percent">
                                        <label>Email</label>
                                    </div>
                                    <div class="col-md-6">{{coinList?.userEmail || 'N/A'}}</div>

                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 pL20-percent">
                                        <label>Date and Time</label>
                                    </div>
                                    <div class="col-md-6">{{coinList?.txnTime | date:"medium"}}</div>

                                </div>
                                <div class="form-group row">
                                    <div class="col-md-12" style="text-align: center; margin: 21px;">
                                        <button class="btn btn-theme" (click)="backkk()">Back</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!viewEth">
                    <div class="tab-pane active show" *ngIf="this.currTabb == 'ETH'">
                        <div class="content-section" [formGroup]="staffForm">
                            <div class="outer-box">
                                <div class="custom_tabs common-tabs">

                                    <div class="tab-content">
                                        <div class="tab-pane active show" id="btc">
                                            <div class="tab-pane1">

                                                <div class="table-responsive">
                                                    <table class="table table-bordered" aria-describedby="">
                                                        <thead>
                                                            <tr class="no_wrap_th">
                                                                <th id="">ID</th>
                                                                <th id="">Currency</th>
                                                                <th id="">Type</th>
                                                                <th id="">Amount</th>
                                                                <th id="">Transaction ID</th>
                                                                <th id="">Date & Time</th>
                                                                <th id="">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                *ngFor="let data of walletDetailsList | paginate:{itemsPerPage: 10, currentPage: pageNumber,totalItems: walletDetailsListLength}; let i=index; ">
                                                                <td>{{10 * (pageNumber - 1) + i+1}}</td>
                                                                <td>{{data?.coinType}}</td>
                                                                <td>{{data?.txnType}}</td>
                                                                <td>{{data?.amount}}</td>
                                                                <td>{{data?.txnId}}</td>
                                                                <td>{{data?.txnTime | date:"medium"}}</td>
                                                                <td class="action_td_btn3">
                                                                    <a data-toggle="modal" class="pointer"
                                                                        (click)="viewCoinBTCDetails(data?.txnId)"><em
                                                                            class="fa fa-eye"></em></a>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="walletDetailsListLength==0">
                                                                <td colspan="10" vertical-align="middle">
                                                                    <div class="no-record">
                                                                        <div class="no-recordin">
                                                                            <H5>No record found
                                                                            </H5>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="custom-pagination mt20 text-center"
                                                    style="    float: right;">

                                                    <pagination-controls (pageChange)="pagination($event)">
                                                    </pagination-controls>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <!-- Table Responsive End -->
                        </div>
                    </div>
                </div>
                <div *ngIf="viewEth">
                    <div>
                        <h3>Wallet Details</h3>
                        <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                            <div class="main-block-innner mb40 mt40">
                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label>Transaction Id</label>
                                    </div>
                                    <div class="col-md-6">{{coinList?.txnId || 'N/A'}}</div>

                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label>Coin</label>
                                    </div>
                                    <div class="col-md-6">{{coinList?.coinType || 'N/A'}}</div>

                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label>Wallet Address</label>
                                    </div>
                                    <div class="col-md-6">{{coinList?.address || 'N/A'}}</div>

                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label>Amount</label>
                                    </div>
                                    <div class="col-md-6">{{coinList?.units || 'N/A'}}</div>

                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label>Type</label>
                                    </div>
                                    <div class="col-md-6">{{coinList?.txnType || 'N/A'}}</div>

                                </div>

                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label>Transaction</label>
                                    </div>
                                    <div class="col-md-6">{{coinList?.txnType || 'N/A'}}</div>

                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label>User Name</label>
                                    </div>
                                    <div class="col-md-6">{{coinList?.userName || 'N/A'}}</div>

                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label>Email</label>
                                    </div>
                                    <div class="col-md-6">{{coinList?.userEmail || 'N/A'}}</div>

                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label>Date and Time</label>
                                    </div>
                                    <div class="col-md-6">{{coinList?.txnTime | date:"medium"}}</div>

                                </div>
                                <div class="form-group row">
                                    <div class="col-md-12" style="text-align: center;">
                                        <button class="btn btn-theme" (click)="backfromEth()">Back</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!viewXrp">
                    <div class="tab-pane active show" *ngIf="this.currTabb == 'XRP'">
                        <div class="content-section" [formGroup]="staffForm">
                            <div class="outer-box">
                                <div class="custom_tabs common-tabs">

                                    <div class="tab-content">
                                        <div class="tab-pane active show" id="btc">
                                            <div class="tab-pane1">
                                              
                                                <div class="table-responsive">
                                                    <table class="table table-bordered" aria-describedby="">
                                                        <thead>
                                                            <tr class="no_wrap_th">
                                                                <th id="">ID</th>
                                                                <th id="">Currency</th>
                                                                <th id="">Type</th>
                                                                <th id="">Amount</th>
                                                                <th id="">Transaction ID</th>
                                                                <th id="">Date & Time</th>
                                                                <th id="">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                *ngFor="let data of walletDetailsList | paginate:{itemsPerPage: 10, currentPage: pageNumber,totalItems: walletDetailsListLength}; let i=index; ">
                                                                <td>{{10 * (pageNumber - 1) + i+1}}</td>
                                                                <td>{{data?.coinType || 'N/A'}}</td>
                                                                <td>{{data?.txnType || 'N/A'}}</td>
                                                                <td>{{data?.amount || 'N/A'}}</td>
                                                                <td>{{data?.txnId || 'N/A'}}</td>
                                                                <td>{{data?.txnTime | date:"medium"}}</td>
                                                                <td class="action_td_btn3">
                                                                    <a data-toggle="modal" class="pointer"
                                                                        (click)="viewXrpCoinDetails(data?.txnId)"><em
                                                                            class="fa fa-eye"></em></a>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="walletDetailsListLength==0">
                                                                <td colspan="10" vertical-align="middle">
                                                                    <div class="no-record">
                                                                        <div class="no-recordin">
                                                                            <H5>No record found
                                                                            </H5>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="custom-pagination mt20 text-center"
                                                    style="    float: right;">

                                                    <pagination-controls (pageChange)="pagination($event)">
                                                    </pagination-controls>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <!-- Table Responsive End -->
                        </div>
                    </div>
                </div>
                <div *ngIf="viewXrp">
                    <div>
                        <h3>Wallet Details</h3>
                        <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                            <div class="main-block-innner mb40 mt40">
                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label>Transaction Id:</label>
                                    </div>
                                    <div class="col-md-6">{{coinList?.txnId || 'N/A'}}</div>

                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label>Coin</label>
                                    </div>
                                    <div class="col-md-6">{{coinList?.coinType || 'N/A'}}</div>

                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label>Wallet Address</label>
                                    </div>
                                    <div class="col-md-6">{{coinList?.address || 'N/A'}}</div>

                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label>Amount</label>
                                    </div>
                                    <div class="col-md-6">{{coinList?.units || 'N/A'}}</div>

                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label>Type</label>
                                    </div>
                                    <div class="col-md-6">{{coinList?.txnType || 'N/A'}}</div>

                                </div>

                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label>Transaction</label>
                                    </div>
                                    <div class="col-md-6">{{coinList?.txnType || 'N/A'}}</div>

                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label>User Name</label>
                                    </div>
                                    <div class="col-md-6">{{coinList?.userName || 'N/A'}}</div>

                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label>Email</label>
                                    </div>
                                    <div class="col-md-6">{{coinList?.userEmail || 'N/A'}}</div>

                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label>Date and Time</label>
                                    </div>
                                    <div class="col-md-6">{{coinList?.txnTime | date:"medium"}}</div>

                                </div>
                                <div class="form-group row">
                                    <div class="col-md-12" style="text-align: center;">
                                        <button class="btn btn-theme" (click)="backfromXrp()">Back</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!viewLtc">
                    <div class="tab-pane active show" *ngIf="this.currTabb == 'LTC'">
                        <div class="content-section" [formGroup]="staffForm">
                            <div class="outer-box">
                                <div class="custom_tabs common-tabs">

                                    <div class="tab-content">
                                        <div class="tab-pane active show" id="btc">
                                            <div class="tab-pane1">

                                                <div class="table-responsive">
                                                    <table class="table table-bordered" aria-describedby="">
                                                        <thead>
                                                            <tr class="no_wrap_th">
                                                                <th id="">ID</th>
                                                                <th id="">Currency</th>
                                                                <th id="">Type</th>
                                                                <th id="">Amount</th>
                                                                <th id="">Transaction ID</th>
                                                                <th id="">Date & Time</th>
                                                                <th id="">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                *ngFor="let data of walletDetailsList | paginate:{itemsPerPage: 10, currentPage: pageNumber,totalItems: walletDetailsListLength}; let i=index; ">
                                                                <td>{{10 * (pageNumber - 1) + i+1}}</td>
                                                                <td>{{data?.coinType || 'N/A'}}</td>
                                                                <td>{{data?.txnType || 'N/A'}}</td>
                                                                <td>{{data?.amount || 'N/A'}}</td>

                                                                <td>{{data?.txnId || 'N/A'}}</td>

                                                                <td>{{data?.txnTime | date:"medium"}}</td>
                                                                <td class="action_td_btn3">
                                                                    <a data-toggle="modal" class="pointer"
                                                                        (click)="viewLtcCoinDetails(data?.txnId)"><em
                                                                            class="fa fa-eye"></em></a>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="walletDetailsListLength==0">
                                                                <td colspan="10" vertical-align="middle">
                                                                    <div class="no-record">
                                                                        <div class="no-recordin">
                                                                            <H5>No record found
                                                                            </H5>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="custom-pagination mt20 text-center"
                                                    style="    float: right;">

                                                    <pagination-controls (pageChange)="pagination($event)">
                                                    </pagination-controls>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <!-- Table Responsive End -->
                        </div>
                    </div>
                </div>
                <div *ngIf="viewLtc">
                    <div>
                        <h3>Wallet Details</h3>
                        <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                            <div class="main-block-innner mb40 mt40">
                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label>Transaction Id</label>
                                    </div>
                                    <div class="col-md-6">{{coinList?.txnId || 'N/A'}}</div>

                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label>Coin</label>
                                    </div>
                                    <div class="col-md-6">{{coinList?.coinType || 'N/A'}}</div>

                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label>Wallet Address</label>
                                    </div>
                                    <div class="col-md-6">{{coinList?.address || 'N/A'}}</div>

                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label>Amount</label>
                                    </div>
                                    <div class="col-md-6">{{coinList?.units || 'N/A'}}</div>

                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label>Type</label>
                                    </div>
                                    <div class="col-md-6">{{coinList?.txnType || 'N/A'}}</div>

                                </div>

                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label>Transaction</label>
                                    </div>
                                    <div class="col-md-6">{{coinList?.txnType || 'N/A'}}</div>

                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label>User Name</label>
                                    </div>
                                    <div class="col-md-6">{{coinList?.userName || 'N/A'}}</div>

                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label>Email</label>
                                    </div>
                                    <div class="col-md-6">{{coinList?.userEmail || 'N/A'}}</div>

                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label>Date And Time</label>
                                    </div>
                                    <div class="col-md-6">{{coinList?.txnTime| date:"medium"}}</div>

                                </div>
                                <div class="form-group row">
                                    <div class="col-md-12" style="text-align: center;">
                                        <button class="btn btn-theme" (click)="backfromLtc()">Back</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!viewTrading">
                <div class="tab-pane active show" *ngIf="this.currTab == 'trading'">
                    <div class="content-section" [formGroup]="tradingForm">
                        <div class="outer-box">
                            <div class="custom_tabs common-tabs">

                                <div class="tab-content">
                                    <div class="tab-pane active show" id="btc">
                                        <div class="tab-pane1">
                                            <div class="row trassction-mainbox">
                                                <div class="col-md-3 col-lg-2">
                                                    <div class="head_flt_select">
                                                        <div class="head_flt_select input-label input-label1">
                                                            <span class="d-flex align-items-center">Type</span>
                                                            <select class="form-control" [(ngModel)]="tradingStatus"
                                                                [ngModelOptions]="{standalone: true}">
                                                                <option value="selected">Select</option>
                                                                <option value="BUY">BUY</option>
                                                                <option value="SELL">SELL</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-lg-2">
                                                    <div class="head_flt_select">
                                                        <div class="head_flt_select input-label input-label1">
                                                            <span class="d-flex align-items-center">Currency</span>
                                                            <select class="form-control" [(ngModel)]="tradingCurrency"
                                                                [ngModelOptions]="{standalone: true}">
                                                                <option value="selected" selected>Select</option>
                                                                <option *ngFor=" let coin of coinlist"
                                                                    [value]=coin?.coinShortName>{{coin?.coinShortName}}
                                                                </option>
                                                            </select>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-lg-2 date-padding">
                                                    <div class="head_flt_select">
                                                        <div class="head_flt_select input-label input-label1">
                                                            <span class="d-flex align-items-center">From </span>
                                                            <input type="date" max="{{minAge | date:'yyyy-MM-dd'}}"
                                                                name="fromdate" [(ngModel)]="calender.fromdate"
                                                                [ngModelOptions]="{standalone: true}" (change)="
                                                                fromdate()" class="form-control datepicker"
                                                                placeholder="Search by date">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-lg-2 date-padding">
                                                    <div class="head_flt_select">
                                                        <div class="head_flt_select input-label input-label1">
                                                            <span class="d-flex align-items-center">To </span>
                                                            <input type="date" name="todate"
                                                                min="{{fromDate | date:'yyyy-MM-dd'}}"
                                                                (change)="todate()" [disabled]="!calender.fromdate"
                                                                [(ngModel)]="calender.todate"
                                                                [ngModelOptions]="{standalone: true}" class="
                                                                form-control datepicker" placeholder="Search by date">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 col-lg-4">
                                                    <div class="tras-threebtn" style="display: inline-flex;">
                                                        <div class="comman-btn">
                                                            <button type="submit" class="btn  btn-theme"
                                                                (click)="searchTrading()">Search</button>
                                                        </div>
                                                        <div class="comman-btn">
                                                            <button type="submit" class="btn  btn-theme"
                                                                (click)="reset()">Reset</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="tras-threebtn" style="float: right;">
                                                        <div class="comman-btn">
                                                            <button type="submit" class="btn  btn-theme"
                                                                (click)="exportAsXLSX()">EXPORT
                                                                AS EXCEL
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                            <div class="table-responsive">
                                                <table class="table table-bordered" aria-describedby="">
                                                    <thead>
                                                        <tr class="no_wrap_th">
                                                            <th id="">ID</th>
                                                            <th id="">Transaction ID</th>
                                                            <th id="">Transaction Type</th>
                                                            <th id="">Amount</th>
                                                            <th id="">Base Coin</th>
                                                            <th id="">Executable Coin</th>
                                                            <th id="">Date and Time</th>
                                                            <th id="">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            *ngFor="let data of tradingList | paginate:{itemsPerPage: 4, currentPage: pageNumber,totalItems: tradingListlength}; let i=index; ">
                                                            <td>{{10 * (pageNumber - 1) + i+1}}</td>
                                                            <td>{{data?.transactionId || 'N/A'}}</td>
                                                            <td>{{data?.orderSide || 'N/A'}}</td>
                                                            <td>{{data?.quantity || 'N/A'}}</td>
                                                            <td>{{data?.baseCoin || 'N/A'}}</td>
                                                            <td>{{data?.exeCoin || 'N/A'}}</td>
                                                            <td>{{data?.lastExecutionTime | date:"medium"}}</td>
                                                            <td class="action_td_btn3">
                                                                <a data-toggle="modal" class="pointer"
                                                                    (click)="viewTradingDetails(data?.userId, data?.transactionId)"><em
                                                                        class="fa fa-eye"></em></a>
                                                            </td>
                                                        </tr>
                                                        <tr *ngIf="tradingList==0">
                                                            <td colspan="10" vertical-align="middle">
                                                                <div class="no-record">
                                                                    <div class="no-recordin">
                                                                        <h5>No record found
                                                                        </h5>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="custom-pagination mt20 text-center" style="    float: right;">

                                                <pagination-controls (pageChange)="pagination($event)">
                                                </pagination-controls>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <!-- Table Responsive End -->
                    </div>
                </div>
            </div>
            <div *ngIf="viewTrading">
                <div>
                    <h3>Trading Details</h3>
                    <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                        <div class="main-block-innner mb40 mt40">
                            <div class="form-group row mL10-percent">
                                <div class="col-md-5">
                                    <label>Transaction Id</label>
                                </div>
                                <label class="col-md-1">:</label>
                                <div class="col-md-6">{{tradingList?.transactionId || 'N/A'}}</div>

                            </div>
                            <div class="form-group row mL10-percent">
                                <div class="col-md-5">
                                    <label>Base Coin</label>
                                </div>
                                <label class="col-md-1">:</label>
                                <div class="col-md-6">{{tradingList?.baseCoin || 'N/A'}}</div>

                            </div>
                            <div class="form-group row mL10-percent">
                                <div class="col-md-5">
                                    <label>Executable Coin</label>
                                </div>
                                <label class="col-md-1">:</label>
                                <div class="col-md-6">{{tradingList?.exeCoin || 'N/A'}}</div>
                            </div>

                            <div class="form-group row mL10-percent">
                                <div class="col-md-5">
                                    <label>Amount</label>
                                </div>
                                <label class="col-md-1">:</label>
                                <div class="col-md-6">{{tradingList?.quantity || 'N/A'}}</div>

                            </div>
                            <div class="form-group row mL10-percent">
                                <div class="col-md-5">
                                    <label>Transaction Type</label>
                                </div>
                                <label class="col-md-1">:</label>
                                <div class="col-md-6">{{tradingList?.orderSide || 'N/A'}}</div>

                            </div>
                            <div class="form-group row mL10-percent">
                                <div class="col-md-5">
                                    <label>Date and Time</label>
                                </div>
                                <label class="col-md-1">:</label>
                                <div class="col-md-6">{{tradingList?.lastExecutionTime | date:"medium"}}</div>

                            </div>
                            <div class="form-group row">
                                <div class="col-md-12" style="text-align: center;">
                                    <button class="btn btn-theme" (click)="backk()">Back</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane active show" *ngIf="this.currTab == 'login'">
                <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                    <div class="main-block-innner mb40 mt40">
                        <div class="add-store-block input-style">
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-6" style="position: relative;">Browser Agent </label>
                                <div class="col-md-1" style="position: relative;">:</div>
                                <div class="col-md-5">
                                    <span>{{loginDetails?.userLastLoginUserAgent || 'N/A'}}</span>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-6">Last Login Date & Time</label>
                                <div class="col-md-1">:</div>
                                <div class="col-md-5">
                                    <span>{{loginDetails?.userLastLoginTime | date:"medium"}}</span>
                                </div>
                            </div>

                            <div class="form-group row align-items-baseline">
                                <label class="col-md-6" style="position: relative;">IP Address </label>
                                <div class="col-md-1" style="position: relative;">:</div>
                                <div class="col-md-5">
                                    <span>{{loginDetails?.ipAdress || 'N/A'}}</span>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-6">Last Transaction Date & Time</label>
                                <div class="col-md-1">:</div>
                                <div class="col-md-5">
                                    <span>{{loginDetails?.lastTransactionDate | date:"medium"}}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

<div class="modal fade global-modal reset-modal" id="trading">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Trading Details</h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to active this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-success mr-2"
                                        (click)="performAction()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>