
<div class="wrapper">

    <!-- Aside End -->
    <main class="middle-content" >
        <!-- Page Title Start -->
        <div class="page_title_block">
            <h1 class="page_title">About Us</h1>

        </div>
        <!-- Page Title End -->
        <div class="content-section">
            <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block1 input-style">
                        <div class="form-group row ">
                            <label class="col-md-3" >Page Name </label>
                            <label class="col-md-1"> : </label>
                            <div class="col-md-8" style="margin-top: -15px;max-height: 30px;">
                                <textarea class="form-control1 common-textarea" readonly [(ngModel)]="dataa.pageKey" ></textarea>
                            </div>
                        </div>
                        <div class="form-group row ">
                            <label class="col-md-4">Description</label>

                        </div>
                        <div class="row">
                            <div class="col-md-12" *ngIf="dataa.pageData">
                                <ck-editor name="editor1" [(ngModel)]="dataa.pageData" skin="moono-lisa" language="en" [fullPage]="true"></ck-editor>
                            </div>
                        </div>
                        <div class="text-center mt40">
                            <a href="javascript:void(0)" class="btn btn-large  max-WT-200 font-100 btn-green mr-2" (click)="saveAboutUS()">Update</a>
                            <a href="javascript:void(0)" class="btn btn-large  max-WT-200 font-100 btn-grey ml5" [routerLink]="['/statics-content']">Back</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <!-- Middle Content End -->
</div>
