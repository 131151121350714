import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-edit-banner',
  templateUrl: './edit-banner.component.html',
  styleUrls: ['./edit-banner.component.css']
})
export class EditBannerComponent implements OnInit {
  bannerId: number;
  addBannerForm: FormGroup;
  imgSrc: any="assets/Product image/chargerImage-2.jpg";
  isActive: any;
  imageUrl: any;
  editValue:any
  constructor(public mainService: ServiceService, private router: Router,private activatedRoute : ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe((res)=>{
      this.bannerId = res.id
    })
   }

  ngOnInit(): void {
    this.addBannerForm = new FormGroup({
      'title': new FormControl('', [Validators.required]),
      "bannerStatus" : new FormControl(),
      "description": new FormControl('', Validators.required),
      "image": new FormControl(''),
      "bannerId":new FormControl('')
    });
    this.getBanner()
  }
  getBanner(){
    let url ="static/admin/static-content/get-banner-By-Id?bannerId="+this.bannerId
    // this.mainService.showSpinner()
    this.mainService.get(url).subscribe((res)=>{
      if (res["status"] ==200) {
        this.editValue=res["data"]
        console.log(res["data"].description);
        this.addBannerForm.patchValue({
          title : res["data"].title,
          description : res["data"].description,
         bannerStatus:res["data"].bannerStatus,
        })
        this.imgSrc = res["data"].image
        // this.bannerId = this.bannerId 
        this.mainService.hideSpinner()
        this.mainService.toasterSucc(res['message'])
      } else {
        this.mainService.hideSpinner()
        this.mainService.toasterErr(res["message"])
      }
    })
  }

  updateBanner(){
    if(this.imgSrc == "assets/loader/1488 (2).gif"){
      this.mainService.toasterWarn("Please wait while Banner image is uploading.")
      return
    }
    let url = "static/admin/static-content/update-banner"
    const data = {
      "image":this.imgSrc,
      "title"  :this.addBannerForm.value.title,
      "bannerId": this.bannerId ,
      "description": this.addBannerForm.value.description,
      
    }
    // console.log(data.bannerStatus);

    this.mainService.postApi(url,data).subscribe((res)=>{
      if (res.status ==200) {

        this.mainService.hideSpinner()
        this.mainService.toasterSucc(res.message)
        this.router.navigate(['/dispute-management'])
      } else {
        this.mainService.hideSpinner()
        this.mainService.toasterErr(res.message)
      }
    })
  }

  ValidateFileUpload(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
     this.imageUrl =  file
    }
    console.log(this.imageUrl);

    this.uploadProfilePic()
  }
  uploadProfilePic(){
    let url = "static/upload-file"
    this.imgSrc = "assets/loader/1488 (2).gif"
    const formData = new FormData();
    formData.append('file',  this.imageUrl);
    this.mainService.postApi(url,formData).subscribe((res)=>{
      if(res.status == 200){
        this.imgSrc = res.data
        this.imageUrl = res.data

      }
      else{
        this.mainService.toasterErr("Can't Upload This File")
      }
    })

  }
  toggleVisibility(e) {
   /*  this.marked = e.target.checked; */
   console.log(this.isActive);

  }

}
