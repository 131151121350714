import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-view-anonouncement',
  templateUrl: './view-anonouncement.component.html',
  styleUrls: ['./view-anonouncement.component.css']
})
export class ViewAnonouncementComponent implements OnInit {
  anouncement : any = []
  id : any
  constructor(private activatedRoute : ActivatedRoute, private service : ServiceService) {
    this.activatedRoute.queryParams.subscribe((res)=>{
      this.id = res.id
    })
   }

  ngOnInit(): void {
    this.viewAnouncement()
  }
viewAnouncement(){
let url = "static/admin/get-Announcement-By-Id?announcementId="+this.id
this.service.showSpinner()
this.service.get(url).subscribe((res)=>{
  console.log(res);
  
  if(res["status"] == 200){
    this.anouncement = res["data"]
    this.service.hideSpinner()
    this.service.toasterSucc(res["message"])
  }else{
    this.service.hideSpinner()
    this.service.toasterErr(res["message"])
  }
})
}
}
