<main class="middle-content" style="margin-top:-3%;">
    <!-- Page Title Start -->
    <div class="page_title_block">
        <h1 class="page_title">Sub Admin Management</h1>
    </div>
    <!-- Page Title End -->
    <div class="content-section" [formGroup]="staffForm">
        <div class="outer-box">
            <div class="custom_tabs common-tabs">
                <div class="tab-content">
                    <div class="tab-pane active show" id="btc">
                        <div class="tab-pane1">
                            <div class="row trassction-mainbox">
                                <div class="col-md-3 col-lg-2">
                                    <div class="head_flt_select">
                                        <div class="head_flt_select input-label input-label1">
                                            <span class="d-flex align-items-center">Status</span>
                                            <select class="form-control" formControlName="status"
                                                (change)="coinfunction($event.target.value)">
                                                <option value="status" [selected]='true'>Select</option>
                                                <option value="ACTIVE">Active</option>
                                                <option value="BLOCK">Blocked</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-3 col-lg-2 date-padding">
                                    <div class="head_flt_select">
                                        <div class="head_flt_select input-label input-label1">
                                            <span class="d-flex align-items-center">From Date</span>

                                            <input type="date" max="{{minAge | date:'yyyy-MM-dd'}}"
                                                (change)="formdate()" formControlName="fromDate" name="formdate"
                                                [(ngModel)]="calender.formdate" class="form-control datepicker">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-lg-2 date-padding">
                                    <div class="head_flt_select">
                                        <div class="head_flt_select input-label input-label1">
                                            <span class="d-flex align-items-center">To Date</span>

                                            <input type="date" name="todate" [readOnly]="IsToDate"
                                                formControlName="toDate" class="form-control datepicker"
                                                [min]="this.staffForm.controls['fromDate'].value"
                                                placeholder="Search by date">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-6">
                                    <div class="tras-threebtn">
                                        <input type="text" placeholder="Search by email" class="form-control"
                                            maxlength="60" formControlName="search" />
                                        <div class="comman-btn">
                                            <button type="submit" class="btn  btn-theme"
                                                (click)="search()">Search</button>
                                        </div>
                                        <div class="comman-btn">
                                            <button type="submit" class="btn  btn-theme"
                                                (click)="reset()">Reset</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="tras-threebtn" style="float: right;">
                                        <div class="comman-btn">
                                            <button type="submit" class="btn  btn-theme" (click)="exportAsXLSX()">EXPORT
                                                AS EXCEL
                                            </button>
                                        </div>
                                        <div class="comman-btn">
                                            <button type="submit" class="btn  btn-theme" (click)="addStaff()">ADD
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-bordered" aria-describedby="">
                                    <thead>
                                        <tr class="no_wrap_th">
                                            <th id="">ID</th>
                                            <th id="">Name</th>
                                            <th id="">Email</th>
                                            <th id="">Role</th>
                                            <th id="">Created Date & Time</th>
                                            <th id="">Last Logged In Date & Time</th>
                                            <th id=""> Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let data of staffList | paginate: { 
                                                itemsPerPage: pageSize,
                                                currentPage: pageNumber,
                                                totalItems: totalRecord}; let i=index; ">
                                            <td>{{pageSize * (this.pageNumber - 1) + i+1}}</td>
                                            <td>{{data?.firstName || 'N/A'}} {{data?.lastName || 'N/A'}}</td>
                                            <th scope="col" style=" font-size: 15px; font-weight: 200;color: #202020;">
                                                {{data?.email || 'N/A'}}</th>
                                            <td>{{data?.role || 'N/A'}}</td>
                                            <td>{{(data?.createdTime | date:"medium")||"---"}}</td>
                                            <td>{{(data?.lastLoginTime | date:"medium")||"---"}}</td>
                                            <td class="action_td_btn3">
                                                <a data-toggle="modal" class="pointer"
                                                    (click)="viewStaff(data?.userId)"><em class="fa fa-eye"
                                                        title="View"></em></a>
                                                <a data-toggle="modal" class="pointer"
                                                    (click)="editStaff(data?.userId)"><em class="fa fa-edit"
                                                        title="Edit"></em></a>

                                                <a data-toggle="modal" class="pointer"
                                                    *ngIf="data?.userStatus == 'ACTIVE'"
                                                    (click)="openModal('BLOCK', data?.userId)"><em
                                                        class="fa fa-ban text-success" title="Deactivate"></em></a>
                                                <a data-toggle="modal" class="pointer"
                                                    *ngIf="data?.userStatus == 'BLOCK'"
                                                    (click)="openModal('ACTIVE', data?.userId)"><em
                                                        class="fa fa-ban text-danger" title="Activate"></em></a>
                                                <a data-toggle="modal" class="pointer"
                                                    (click)="openModal('DELETE', data?.userId)"><em class="fa fa-trash"
                                                        title="Delete"></em></a>
                                            </td>
                                        </tr>
                                        <tr *ngIf="staffList?.length==0">
                                            <td colspan="7" vertical-align="middle">
                                                <div class="no-record">
                                                    <div class="no-recordin">
                                                        <H5>No record found</H5>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end"
                                *ngIf="staffList?.length">
                                <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- Table Responsive End -->
    </div>
</main>

<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <div class="modal-body">
                        <h5 style="text-align: center; ">Remove Staff </h5>
                        <div class="modal-inner-content">
                            <div class="modal-body">
                                <div
                                    class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                                    <div class="w-100">
                                        <p>Are you sure you want to delete this staff?</p>
                                        <div>
                                            <button type="submit" class="btn btn-info mr-2"
                                                (click)="deleteFunction()">Yes</button>
                                            <button type="button" class="btn btn-danger"
                                                data-dismiss="modal">No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- delete_modal End -->
<!-- block_modal Start -->
<div class="modal fade global-modal reset-modal" id="block">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <div class="modal-body">
                        <h5 style="text-align: center; ">Block Staff</h5>
                        <div class="modal-inner-content">
                            <div class="modal-body">
                                <div
                                    class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                                    <div class="w-100">
                                        <p>Are you sure you want to block this staff?</p>
                                        <div class="modal-btn-box">
                                            <button type="submit" class="btn btn-success mr-2"
                                                (click)="performAction()">Yes</button>
                                            <button type="button" class="btn btn-danger"
                                                data-dismiss="modal">No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- active Start -->
<div class="modal fade global-modal reset-modal" id="active">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <div class="modal-body">
                        <h5 style="text-align: center; ">Active Staff </h5>
                        <div class="modal-inner-content">
                            <div class="modal-body">
                                <div
                                    class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                                    <div class="w-100">
                                        <p>Are you sure you want to active this staff?</p>
                                        <div class="modal-btn-box">
                                            <button type="submit" class="btn btn-success mr-2"
                                                (click)="performActionActive()">Yes</button>
                                            <button type="button" class="btn btn-danger"
                                                data-dismiss="modal">No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>