<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">My Profile</h1>
        </div>
        <div class="content-section">
            <div style="text-align: end;">
                <button class="btn btn-theme" (click)="changePassword()">Change Password</button>
            </div>
            <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block input-style">
                        <div class="user-profile">
                            <div class="image-box">
                                <img [src]="userDetail.imageUrl || 'assets/img/profile-img.jpg'" class="profile-pic"
                                    alt="">
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-5">Name </label>
                            <div class="col-md-1"> :</div>
                            <div class="col-md-6">
                                <span>{{userDetail?.firstName}} {{userDetail?.lastName}}</span>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-5">Email </label>
                            <div class="col-md-1"> :</div>
                            <div class="col-md-6">
                                <span><a>{{userDetail?.email}}</a></span>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-5">Mobile Number</label>
                            <div class="col-md-1"> :</div>
                            <div class="col-md-6">
                                <span><a>{{userDetail?.phoneNo}}</a></span>
                            </div>
                        </div>

                        <div class="form-group row align-items-baseline">
                            <label class="col-md-5">City </label>
                            <div class="col-md-1"> :</div>
                            <div class="col-md-6">
                                <span>{{userDetail?.city}}</span>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-5">Country </label>
                            <div class="col-md-1"> :</div>
                            <div class="col-md-6">
                                <span>{{userDetail?.country}}</span>
                            </div>
                        </div>

                        <div class="form-group row align-items-baseline">
                            <label class="col-md-5">Address </label>
                            <div class="col-md-1"> :</div>
                            <div class="col-md-6">
                                <span style="word-wrap: break-word;"> {{userDetail?.address}}</span>
                            </div>
                        </div>
                        <div class="text-center mt40">
                            <a [routerLink]="['/edit-profile']" routerLinkActive="router-link-active"
                                class="btn btn-large  max-WT-200 font-100 btn-green mr-2">Edit Profile</a>
                            <a href="javascript:void(0)" class="btn btn-large  max-WT-200 font-100 btn-green ml5 "
                                [routerLink]="['/setting']">Back</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
