import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ServiceService } from "src/app/service.service";

@Component({
  selector: "app-add-anonouncement",
  templateUrl: "./add-anonouncement.component.html",
  styleUrls: ["./add-anonouncement.component.css"],
})
export class AddAnonouncementComponent implements OnInit {
  addAnounceneent: FormGroup;
  constructor(public mainService: ServiceService,private router : Router) {}

  ngOnInit(): void {
    this.addAnounceneent = new FormGroup({
      title: new FormControl("", [Validators.required]),

      description: new FormControl("", Validators.required),
      tag: new FormControl("", Validators.required),
    });
  }
  addAnouncement() {
    let url = "static/admin/save-announcement";
    const data = {
      announcementStatus: "ACTIVE",
      coinName: this.addAnounceneent.value.tag,

      description: this.addAnounceneent.value.description,
      title: this.addAnounceneent.value.title,
    };
    this.mainService.showSpinner()
    this.mainService.post(url,data).subscribe((res)=>{
      if(res["status"]==200){
        this.mainService.toasterSucc(res["message"])
        this.mainService.hideSpinner()
        this.router.navigate(['/anouncement-management'])
      }
      else{
        this.mainService.toasterErr(res["message"])
        this.mainService.hideSpinner()
      }
    })
  }
}
