import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-withdrawl-fee',
  templateUrl: './withdrawl-fee.component.html',
  styleUrls: ['./withdrawl-fee.component.css']
})
export class WithdrawlFeeComponent implements OnInit {
  coinlist: any = [];
  cryptoCoinListArray: any = [];

  constructor(public service: ServiceService) { }

  ngOnInit() {
    this.getCoinWalletList()
  }

  // get coin list
  getCoinWalletList() {
    this.service.showSpinner();
    this.service.get('wallet/coin/get-coin-list').subscribe((res: any) => {
      console.log("coin list res==>", res)
      if (res.status == 200) {
        this.coinlist = res.data;
        this.cryptoCoinListArray = this.coinlist.filter(x => x.coinType == "crypto")
        console.log("crypto coin list res==>")
        this.service.hideSpinner();
      }
      else {
        this.service.hideSpinner();
      }
    }, (error) => {
      this.service.hideSpinner();
    })
  }

}
