<main class="middle-content" id="view">
    <div class="page_title_block">
        <h1 class="page_title">Help Details</h1>
    </div>
    <div class="custom_tabs common-tabs">
        <div class="content-section">
            <div class="tab-pane active show">
                <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                    <div class="main-block-innner mb40 mt40">
                        <div class="add-store-block input-style">
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-6">Ticket ID</label>
                                <div class="col-md-1">:</div>
                                <div class="col-md-5">
                                    <span>{{ticketDetails?.ticketId || 'N/A'}}</span>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-6">Customer Name</label>
                                <div class="col-md-1">:</div>
                                <div class="col-md-5">
                                    <span>{{ticketDetails?.name  || 'N/A'}}</span>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-6">Customer Email</label>
                                <div class="col-md-1">:</div>
                                <div class="col-md-5">
                                    <span>{{ticketDetails?.email  || 'N/A'}}</span>

                                </div>
                            </div>
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-6">Subject</label>
                                <div class="col-md-1">:</div>
                                <div class="col-md-5">
                                    <span>{{ticketDetails?.subject || 'N/A'}}</span>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-6">Description</label>
                                <div class="col-md-1">:</div>
                                <div class="col-md-5">
                                    <span class="">{{ticketDetails?.description  || 'N/A'}}</span>

                                </div>
                            </div>
                            
                          
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-6">Customer Ticket Status</label>
                                <div class="col-md-1">:</div>
                                <div class="col-md-5">
                                    <span>{{ticketDetails?.ticketStatus  || 'N/A'}}</span>

                                </div>
                            </div>
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-6">Request Date & Time</label>
                                <div class="col-md-1">:</div>
                                <div class="col-md-5">
                                    <span>{{(ticketDetails?.createdAt | date:"d MMM, y h:mm a" ) || 'N/A'}}</span>

                                </div>
                            </div>

                            <div *ngIf="ticketDetails?.imageUrl" class="form-group row align-items-baseline">
                                <label class="col-md-6">Attachment</label>
                                <div class="col-md-1">:</div>
                                <div class="col-md-5">
                                    <div (click)="viewDoc(ticketDetails?.imageUrl)" class="img-attch-box">
                                        <img [src]="ticketDetails?.imageUrl" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-left mt40 text-center">
                            <a (click)="reply()" class="btn btn-large  max-WT-200 font-100 btn-green">Reply</a> 
                            <a (click)="back()" class="btn btn-large ml-2 max-WT-200 font-100 btn-green">Back</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</main>


<div class="modal fade global-modal reset-modal" id="documentModal">
    <div class="modal-dialog">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h2 class="text-center">Attachment</h2>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <img id="largeImage" [src]="imgSrc" alt="" >
                                <div style="margin-top: 10px;">
                                   
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>


<div class="modal fade global-modal reset-modal" id="replyModal">
    <div class="modal-dialog">
        <form class="change_password" #mailForm = "ngForm">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h2 class="text-center">Reply User</h2>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <div class="row">
                                    <div class="col-12 m-0">
                                        <input type="text" name="subject" ngModel class="form-control" #subject required placeholder="Subject">
                                    </div>
                                   
                                </div><br>
                                <div class="row">
                                    <div class="col-12">
                                        <textarea cols="30" name="body" ngModel class="form-control" rows="10" #body required placeholder="Reply to user."></textarea>
                                    </div>
                                    
                                </div>
                            
                                <div style="margin-top: 10px;">
                                    <button class="btn btn-danger" (click)="replyUser(ticketDetails?.email,subject.value , body.value)" [disabled]="mailForm.form.invalid" target="_top">Send Mail </button>
                                    <!-- <button type="button" (click)="replyUser(f.value)" class="btn btn-danger" data-dismiss="modal" [disabled]="!f.value">Reply</button> -->
                                    <button type="button" class="btn btn-danger ml-2" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>