import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from '../service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-edit-staff',
  templateUrl: './edit-staff.component.html',
  styleUrls: ['./edit-staff.component.css']
})
export class EditStaffComponent implements OnInit {
  editStaffForm: FormGroup;
  id: any;
  staffDetails: any;
  ipAddress: any;
  staffPrivileges: any = [];
  permissionArr : any =  ["DASHBOARD_MANAGEMENT","USER_MANAGEMENT" , "SUB_ADMIN_MANAGEMENT",
  "HOT_COLD_WALLET_MANAGEMENT", "KYC_MANAGEMENT", "WALLET_MANAGEMENT","BUY_SELL_MANAGEMENT",
  "FEE_MANAGEMENT","HELP_MANAGEMENT",
  "BANNER_MANAGEMENT","INSTITUTIONAL_INVESTOR_MANAGEMENT","STATTIC_CONTENT_MANAGEMENT"]
  index = 0
  name:any = []
  check : any  = []
  permArray : any = []
  checkedBoolead: boolean = true;

  constructor(public router: Router, public param: ActivatedRoute, public service: ServiceService, public http: HttpClient) {
    this.param.queryParams.subscribe((res) => {
      this.id = res.id;
     
    });
    for(let i of this.permissionArr){
 
      this.name[this.index] = String(i).split('_').join(' ')
      this.index++
    }
    this.http.get<{ ip: string }>('https://jsonip.com')
      .subscribe(data => {
        console.log('th data', data);
        this.ipAddress = data
        console.log("djfhgdj", this.ipAddress)
      })
  }


  ngOnInit() {
    this.editStaffForm = new FormGroup({
      firstName: new FormControl('', [Validators.pattern(/^[a-z,.'-]+$/i), Validators.required]),
      lastName: new FormControl('', [Validators.pattern(/^[a-z,.'-]+$/i), Validators.required]),
      email: new FormControl('', [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
      gender: new FormControl('', Validators.required),
      role: new FormControl('', Validators.required),
      phoneNumber: new FormControl('', [Validators.required, Validators.pattern(/^[1-9][0-9]{8,13}$/), Validators.maxLength(18)]),
      dashboardChecked: new FormControl(''),
      staffChecked: new FormControl(''),
      hotWalletChecked: new FormControl(''),
      kycChecked: new FormControl(''),
      walletChecked: new FormControl(''),
      staticChecked: new FormControl(''),
      logsChecked: new FormControl(''),
      ticketChecked: new FormControl(''),
      tradeChecked: new FormControl(''),
      disputeChecked: new FormControl(''),
      bankChecked: new FormControl(''),
      feeChecked: new FormControl('')
    })
   this.getProfile()
  }

  getProfile() {
    this.service.showSpinner();
    let dataa = {
      "primaryIdCommonPerRequest": this.id
    }
    this.service.post('account/admin/user-management/get-staff-user-profile', dataa).subscribe((res) => {
     if(res["status"] ==561){
      this.staffDetails = res['data']['staffDetails'];
      this.staffPrivileges = res['data']['staffPrivileges'];
        this.editStaffForm.patchValue({
        firstName: this.staffDetails.firstName,
        lastName: this.staffDetails.lastName,
        email: this.staffDetails.email,
        gender: this.staffDetails.gender,
        role: this.staffDetails.role,
        phoneNumber: this.staffDetails.phoneNo
      })
      this.permission()
      this.service.hideSpinner()

     }
     else{
       this.service.toasterErr(res["meassage"])
      this.service.hideSpinner()
     }
      
      
    })
   
  }

  permission(){
    let index = 0
    for(let item of this.permissionArr){
      console.log(this.staffPrivileges.indexOf(item))
      if(this.staffPrivileges.indexOf(item) >= 0){
        this.permArray.push(true)
      }
      else{
        this.permArray.push(false)
      }
    }
    console.log();
    
  }

  editStaff() {
    let data = {
      "address": "string",
      "city": "string",
      "country": "INDIA",
      "countryCode": "+91",
      "dob": "string",
      "email": this.editStaffForm.value.email,
      "firstName": this.editStaffForm.value.firstName,
      "gender": this.editStaffForm.value.gender,
      "imageUrl": "string",
      "ipAddress": this.ipAddress.ip,
      "lastName": this.editStaffForm.value.lastName,
      "location": "string",
      "phoneNo": this.editStaffForm.value.phoneNumber,
      "previlage": this.staffPrivileges,
      "roleStatus": "SUBADMIN",
      "state": "string",
      "webUrl": "string",
      "userIdToUpdate": this.id
    }
    this.service.showSpinner()
    this.service.post('account/admin/user-management/edit-sub-admin', data).subscribe((res) => {
      this.service.hideSpinner()
      if(res['status'] == 200){
        this.service.toasterSucc(res['message'])
        this.router.navigate(['/staff-management'])
      }
    })
  }

  /** Function for checkbox click */
  checkPerm(permName,ch){
  
    console.log(permName,ch);
    if(ch){
      this.staffPrivileges.push(permName)
    }
    else{
     
      this.staffPrivileges.splice(this.staffPrivileges.indexOf(permName),1)
    }
    console.log(this.staffPrivileges);
    
   }

}
