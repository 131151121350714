import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-edit-anouncement',
  templateUrl: './edit-anouncement.component.html',
  styleUrls: ['./edit-anouncement.component.css']
})
export class EditAnouncementComponent implements OnInit {
id : any
  addAnounceneent: FormGroup;
  constructor(public mainService: ServiceService,private router : Router,private activatedRoute : ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe((res)=>{
      this.id = res.id
    })
  }

  ngOnInit(): void {
    this.addAnounceneent = new FormGroup({
      title: new FormControl("", [Validators.required]),

      description: new FormControl("", Validators.required),
      tag: new FormControl("", Validators.required),
    });
    this.viewAnouncement()
  }
  viewAnouncement(){
    let url = "static/admin/get-Announcement-By-Id?announcementId="+this.id
    this.mainService.showSpinner()
    this.mainService.get(url).subscribe((res)=>{
      console.log(res);
      
      if(res["status"] == 200){
        this.addAnounceneent.patchValue({
          title : res["data"]["title"],
          description : res["data"]["description"],
          tag : res["data"]["coinName"]
        })
        this.mainService.hideSpinner()
        this.mainService.toasterSucc(res["message"])
      }else{
        this.mainService.hideSpinner()
        this.mainService.toasterErr(res["message"])
      }
    })
    }
  editAnouncement() {
    let url = "static/admin/update-announcement";
    const data = {
      "announcementId": this.id,
      "coinName": this.addAnounceneent.value.tag,
      
      "description": this.addAnounceneent.value.description,
      "title": this.addAnounceneent.value.title,
     
    }
    this.mainService.showSpinner()
    this.mainService.post(url,data).subscribe((res)=>{
      if(res["status"]==200){
        this.mainService.toasterSucc(res["message"])
        this.mainService.hideSpinner()
        this.router.navigate(['/anouncement-management'])
      }
      else{
        this.mainService.toasterErr(res["message"])
        this.mainService.hideSpinner()
      }
    })
  }

}
