import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-referal',
  templateUrl: './view-referal.component.html',
  styleUrls: ['./view-referal.component.css']
})
export class ViewReferalComponent implements OnInit {
  refferalData : any = {
    referToUserName : "Tanveer Haider",
    referToEmail : "tanveer@mailinator.com",
    registraionDate : new Date(),
    referByUserName : "Gourav",
    referByEmailId : "gourav@mailinator.com",
    referalId : "VPQHCW",
    id: 1

  }
  constructor() { }

  ngOnInit(): void {
  }

}
