<main class="middle-content" id="view">
    <!-- Page Title Start -->
    <div class="page_title_block">
        <h1 class="page_title" >Document Details</h1>
    </div>
    <!-- Page Title End -->
    <div class="content-section">
        <div class="order-view mt30 max-WT-700 mrgn-0-auto">
            <div class="main-block-innner mb40 mt40">
                <div class="add-store-block input-style">
                    <h4 class="mb30">Document Details</h4>
                    <div class="form-group row align-items-baseline">

                    </div>
                    <div class="text-left mt40 text-center">
                        <a (click)="back()" class="btn btn-large  max-WT-200 font-100 btn-green">Back</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>