import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-view-banner',
  templateUrl: './view-banner.component.html',
  styleUrls: ['./view-banner.component.css']
})
export class ViewBannerComponent implements OnInit {

  bannerId : number
  bannerData:any
  description: any;
 
  constructor(private mainService : ServiceService,private activatedRoute : ActivatedRoute) { 
    this.activatedRoute.queryParams.subscribe((res)=>{
      this.bannerId = res.id
    })
  }

  ngOnInit(): void {
    this.getBanner()
    
  }

    getBanner(){
      let url ="static/admin/static-content/get-banner-By-Id?bannerId="+this.bannerId
      
      this.mainService.showSpinner()
      this.mainService.get(url).subscribe((res)=>{
        if (res["status"] ==200) {
          this.bannerData = res["data"]
          this.description=res["data"].description
          this.mainService.hideSpinner()
          this.mainService.toasterSucc(res["message"])
        } else {
          this.mainService.hideSpinner()
          this.mainService.toasterErr(res["message"])
        }
      })
    
  }

}
