<main class="middle-content">
    <!-- Page Title Start -->
    <div class="page_title_block">
        <h1 class="page_title">User Details</h1>
    </div>
    <!-- Page Title End -->
    <div class="content-section">
        <div class="outer-box">
            <div class=" common-tabs">
                <div class="tab-content">
                    <div class="tab-pane active show" id="btc">
                        <div class="tab-pane1">
                            <div class="row  form-group">
                                <div class="col-md-12">
                                    <div class="tras-threebtn" style="float: right;">
                                        <div class="comman-btn">
                                            <button  type="submit"
                                                class="btn  btn-theme" *ngIf="basicUserDetails.userStatus == 'ACTIVE'" (click)="blockUnblockUser('Block')">Block
                                                User
                                            </button>
                                            <button *ngIf="basicUserDetails.userStatus  == 'BLOCK'" type="submit"
                                                class="btn  btn-theme" (click)="blockUnblockUser('Active')">Active
                                                User
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-4">
                                    <label>Username</label>&nbsp;&nbsp;&nbsp;<span>{{basicUserDetails?.firstName || 'N/A'}}
                                        {{basicUserDetails?.lastName || 'N/A'}}</span>
                                </div>

                                <div class="col-md-4">
                                    <label>Email</label>&nbsp;&nbsp;&nbsp;<span>{{basicUserDetails?.email || 'N/A'}}</span>
                                </div>

                                <div class="col-md-4">
                                    <label>Account Created
                                        On</label>&nbsp;&nbsp;&nbsp;<span>{{basicUserDetails?.creationTime | date:'short' || 'N/A'}}</span>
                                </div>

                            </div>
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <div>
                                        <label>Login History</label>
                                        <button class="btn btn-theme" style="float: right;"
                                            (click)="loginHistoryExportInExcel()">EXPORT AS EXCEL</button>
                                    </div>&nbsp;
                                    <table class="table table-bordered" aria-describedby="">
                                        <thead>
                                            <tr class="no_wrap_th">
                                                <th id="">S.No.</th>
                                                <th id="">Date - Time</th>
                                                <th id="">Region IP</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let data of loginDetailss | paginate:{itemsPerPage: 5, currentPage: 1,totalItems: loginDetailsLength}; let i=index; ">
                                                <td>{{ i+1}}</td>
                                                <td>{{data?.createTime | date:'medium'}}</td>
                                                <td>{{data?.ipAddress || 'N/A'}}</td>
                                            </tr>
                                            <tr *ngIf="loginDetailss?.length==0">
                                                <td colspan="5" vertical-align="middle">
                                                    <div class="no-record">
                                                        <div class="no-recordin">
                                                            <H5>No record found</H5>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <!-- <div class="custom-pagination mt20 text-center" style="float: right;"
                                        *ngIf="loginDetailsLength > 10 ">
                                        <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
                                    </div> -->
                                </div>
                                <div class="col-md-6">
                                    <div>
                                        <label>KYC Status</label>
                                        <button class="btn btn-theme" style="float: right;"
                                            (click)="kycStatusExportInExcel()">EXPORT AS EXCEL</button>
                                    </div>&nbsp;
                                    <table class="table table-bordered" aria-describedby="">
                                        <thead>
                                            <tr class="no_wrap_th">
                                                <th id="">Document</th>
                                                <th id="">Status</th>
                                                <th id="">Date - Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Email</td>
                                                <td>
                                                    <button 
                                                        class="btn btn-theme" disabled>Verified</button>
                                                    <button *ngIf="userStatusForSuspend == 'BLOCK'"
                                                        class="btn btn-theme" disabled>Unverified</button>
                                                </td>
                                                <td>{{basicUserDetails?.emailVerificationTime | date:'medium'}}</td>
                                            </tr>
                                            <tr>
                                                <td>Mobile Number</td>
                                                <td>
                                                    <button 
                                                        class="btn btn-theme" disabled>Verified</button>
                                                    <button *ngIf="userStatusForSuspend == 'BLOCK'"
                                                        class="btn btn-theme" disabled>Unverified</button>
                                                </td>
                                                <td>{{basicUserDetails?.emailVerificationTime | date:'medium'}}</td>
                                            </tr>
                                            <tr>
                                                <td>ID</td>
                                                <td>
                                                    <button class="btn btn-theme"
                                                        disabled>{{basicUserDetails?.kyc?.kycStatus || 'N/A'}}</button>
                                                </td>
                                                <td>{{basicUserDetails?.creationTime | date:"medium"}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="custom-pagination mt20 text-center" style="float: right;"
                                        *ngIf="kycDetailsLength > 10 ">
                                        <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
                                    </div>
                                </div>

                            </div>
                            <div class="custom_tabs common-tabs">

                                <div class="row mb20 justify-content-center">
                                    <div class="col-sm-12">
                                        <ul class="nav nav-tabs d-flex  justify-content-center text-center">
                                            <li class="nav-item flex-fill"> <a class="nav-link active show"
                                                [ngClass]="{'active': currTab=='general'}"
                                                (click)="selectTab('general')" href="javascript:;">General
                                            </a>
                                        </li>
                                            <li class="nav-item flex-fill"> <a class="nav-link active show"
                                                    [ngClass]="{'active': currTab=='buySell'}"
                                                    (click)="selectTab('buySell')"
                                                    href="javascript:;">Buy/Sell</a>
                                            </li>
                                            <li class="nav-item flex-fill"> <a class="nav-link active show"
                                                    [ngClass]="{'active': currTab=='trades'}"
                                                    (click)="selectTab('trades')" href="javascript:;">Trades</a>
                                            </li>
                                            <li class="nav-item flex-fill"> <a class="nav-link active show"
                                                    [ngClass]="{'active': currTab=='wallet'}"
                                                    (click)="selectTab('wallet')" href="javascript:;">Wallet</a>
                                            </li>
                                            <li class="nav-item flex-fill"> <a class="nav-link active show"
                                                    [ngClass]="{'active': currTab=='logs'}"
                                                    (click)="selectTab('logs')" href="javascript:;">Log's Activity
                                                </a>
                                            </li>
                                            <!-- <li class="nav-item flex-fill"> <a class="nav-link active show"
                                                    [ngClass]="{'active': currTab=='blockedby'}"
                                                    (click)="selectTab('blockedby')" href="javascript:;">Blocked By
                                                </a>
                                            </li> -->
                                        </ul>
                                    </div>
                                </div>
                                <!-- Page Title End -->
                                <div class="content-section">
                                    <!-- general tab -->
                                    <div class="tab-pane active show" *ngIf="this.currTab == 'general'">
                                        <div class="order-view mt30 mb20 max-WT-700 mrgn-0-auto">
                                            <div class="main-block-innner mb40 mt40">
                                                <div class="add-store-block1 input-style ">
                                                    <div class="form-group row">
                                                        <div class="col-md-12" style="text-align: center">
                                                            <img [src]="profile?profile:'assets/img/testimonial-img1.jpg'" alt=""
                                                                style="height: 100px; border-radius: 52%;">
                                                        </div>
                                                    </div>
                                                    <div class="form-group row align-items-baseline text-center">
                                                        <label class="col-md-5 text-left">Name</label>
                                                        <div class="col-md-2">:</div>
                                                        <div class="col-md-5 text-left">
                                                            <span>{{profileData?.firstName}} {{profileData?.lastName}}</span>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row align-items-baseline text-center">
                                                        <label class="col-md-5 text-left">Email</label>
                                                        <div class="col-md-2">:</div>
                                                        <div class="col-md-5 text-left">
                                                            <span class="user-eml">{{profileData?.email || '---'}}</span>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row align-items-baseline text-center">
                                                        <label class="col-md-5 text-left">Mobile Number</label>
                                                        <div class="col-md-2">:</div>
                                                        <div class="col-md-5 text-left">
                                                            <span>{{profileData?.phoneNo || '---'}}</span>
                                                        </div>
                                                    </div>
                        
                        
                                                    <div class="form-group row align-items-baseline text-center">
                                                        <label class="col-md-5 text-left">2FA Security</label>
                                                        <div class="col-md-2">:</div>
                                                        <div class="col-md-5 text-left">
                                                            <span> {{profileData?.twoFaType || '----'}}</span>
                                                        </div>
                                                    </div>
                        
                                                    <div class="form-group row align-items-baseline text-center"
                                                        style="word-break: break-all;">
                                                        <label class="col-md-5 text-left">Address </label>
                                                        <div class="col-md-2">:</div>
                                                        <div class="col-md-5 text-left">
                                                            <span>{{profileData?.address || '---'}}</span>
                                                        </div>
                                                    </div>
                        
                                                    <!-- <div class="form-group row align-items-baseline">
                                                        <label class="col-md-4">KYC Doc </label>
                                                        <div class="col-md-2">:</div>
                                                        <div class="col-md-6 zoom" *ngIf="userDetail?.kyc?.document[0].backIdUrl!=null">
                                                            <img src="{{userDetail?.kyc?.document[0].backIdUrl}}" alt="">
                                                        </div>
                                                        <div class="col-md-6" *ngIf="userDetail?.kyc?.document[0].backIdUrl==null">
                                                            No Document
                                                        </div>
                        
                                                    </div>
                                                    <div class="form-group row align-items-baseline">
                                                        <label class="col-md-4">KYC Doc </label>
                                                        <div class="col-md-2">:</div>
                                                        <div class="col-md-6 zoom" *ngIf="userDetail?.kyc?.document[0].frontIdUrl!=null">
                                                            <img src="{{userDetail?.kyc?.document[0].frontIdUrl}}" alt="">
                                                        </div>
                                                        <div class="col-md-6" *ngIf="userDetail?.kyc?.document[0].frontIdUrl==null">
                                                            No Document
                                                        </div>
                                                    </div> -->
                                                </div>
                                                <div class="text-left mt40 text-center">
                                                    <a routerLink="/user-management-exchange" class="btn btn-large  max-WT-100 font-100 btn-green">Back</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- general tab end -->
                                    <div class="tab-pane active show" *ngIf="this.currTab == 'buySell'">
                                        <div class="">
                                            <table class="table table-bordered" aria-describedby="">
                                                <thead>
                                                    <tr class="no_wrap_th">
                                                        <th id="">S.No</th>
                                                        <th id="">Coin Name</th>
                                                        <th id="">Quantity</th>
                                                        <th id="">Price</th>
                                                        <th id="">Date & Time</th>
                                                        <th id="">Trade Type</th>
                                                       
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let data of advertisementlist | paginate:{itemsPerPage: 10, currentPage: pageNumber,totalItems: advertisementlistlength}; let i=index; ">
                                                        <td>{{10 * (pageNumber - 1) + i+1}}</td>
                                                        <td>{{data?.creationTime | date:'medium'}}</td>
                                                        <td><button class="btn btn-theme"
                                                                disabled>{{data?.orderStatus || 'N/A'}}</button></td>
                                                        <td>{{data?.orderType || 'N/A'}}</td>
                                                        <td>{{data?.paymentType || 'N/A'}}</td>
                                                        <td>{{data?.fiatCoin || 'N/A'}}</td>
                                                        <td>{{data?.maxValue || 'N/A'}}</td>
                                                    </tr>
                                                    <tr *ngIf="advertisementlist==0">
                                                        <td colspan="5" vertical-align="middle">
                                                            <div class="no-record">
                                                                <div class="no-recordin">
                                                                    <H5>No record found</H5>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="custom-pagination mt20 text-center" style="float: right;"
                                                *ngIf="advertisementlistlength > 5 ">
                                                <pagination-controls (pageChange)="pageNumber = $event">
                                                </pagination-controls>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane active show" *ngIf="this.currTab == 'trades'">
                                        <div class="">
                                            <table class="table table-bordered" aria-describedby="">
                                                <thead>
                                                    <tr class="no_wrap_th">
                                                        <th id="">Ad ID</th>
                                                        <th id="">Created Date-Time</th>
                                                        <th id="">Status</th>
                                                        <th id="">Type</th>
                                                        <th id="">Payment Method</th>
                                                        <th id="">Trading Partner</th>
                                                        <th id="">Fiat</th>
                                                        <th id="">Trade Amount</th>
                                                        <th id="">Fee</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let data of tradeList | paginate:{itemsPerPage: 10, currentPage: pageNumber,totalItems: tradeListlength}; let i=index; ">
                                                        <td>{{data?.tradingId || 'N/A'}}</td>
                                                        <td>{{data?.creationTime | date:'medium'}}</td>
                                                        <td><button class="btn btn-theme"
                                                                disabled>{{data?.tradeStatus || 'N/A'}}</button></td>
                                                        <td>{{data?.type || 'N/A'}}</td>
                                                        <td>{{data?.paymentType || 'N/A'}}</td>
                                                        <td>{{data?.buyer || 'N/A'}}</td>
                                                        <td>{{data?.totalBTC ||'N/A'}} BTC</td>
                                                        <td>{{data?.tradeAmount ||'N/A'}}</td>
                                                        <td>{{data?.tradeFee ||'N/A'}}</td>
                                                    </tr>
                                                    <tr *ngIf="tradeList==0">
                                                        <td colspan="11" vertical-align="middle">
                                                            <div class="no-record">
                                                                <div class="no-recordin">
                                                                    <H5>No record found</H5>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="custom-pagination mt20 text-center" style="float: right;"
                                                *ngIf="tradeListlength > 5 ">
                                                <pagination-controls (pageChange)="pageNumber = $event">
                                                </pagination-controls>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane active show" *ngIf="this.currTab == 'wallet'">
                                        <div class="">
                                            <table class="table table-bordered" aria-describedby="">
                                                <thead>
                                                    <tr class="no_wrap_th">
                                                        <th id="">ID</th>
                                                        <th id="">Coin</th>
                                                        <th id="">Transaction Type</th>
                                                        <th id="">Created Date-Time </th>
                                                        <th id="">Transaction Hash</th>
                                                        <th id="">Amount</th>
                                                        <th id="">Email</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let data of wallethistoryData | paginate:{itemsPerPage: 10, currentPage: pageNumber,totalItems: wallethistoryDatalength}; let i=index; ">
                                                        <td>{{10 * (pageNumber - 1) + i+1}}</td>
                                                        <td>{{data?.coinType || 'N/A'}}</td>
                                                        <td>{{data?.txnType || 'N/A'}}</td>
                                                        <td>{{data?.txnTime | date:'medium'}}</td>
                                                        <td>{{data?.txnHash || 'N/A'}}</td>
                                                        <td>{{data?.fees || 'N/A'}}</td>
                                                        <td>{{data?.userEmail || 'N/A'}}</td>
                                                    </tr>
                                                    <tr *ngIf="wallethistoryData==0">
                                                        <td colspan="7" vertical-align="middle">
                                                            <div class="no-record">
                                                                <div class="no-recordin">
                                                                    <H5>No record found</H5>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="custom-pagination mt20 text-center" style="float: right;"
                                                *ngIf="wallethistoryDatalength > 10 ">
                                                <pagination-controls (pageChange)="pageNumber = $event">
                                                </pagination-controls>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane active show" *ngIf="this.currTab == 'feedback'">
                                        <div class="">
                                            <table class="table table-bordered" aria-describedby="">
                                                <thead>
                                                    <tr class="no_wrap_th">
                                                        <th id="">S.No.</th>
                                                        <th id="">Created Date-Time </th>
                                                        <th id="">Type</th>
                                                        <th id="">Feedback</th>
                                                        <th id="">Customer Name</th>
                                                        <th id="">Message</th>
                                                        <th id="">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let data of feedbackLoopData | paginate:{itemsPerPage: 10, currentPage: pageNumber,totalItems: advertisementlistlength}; let i=index; ">
                                                        <td>{{10 * (pageNumber - 1) + i+1}}</td>
                                                        <td>{{data?.date | date:'medium'}}</td>
                                                        <td>{{data?.type || 'N/A'}}</td>
                                                        <td><button class="btn btn-theme"
                                                                disabled>{{data?.feedbackStatus || 'N/A'}}</button></td>
                                                        <td>{{data?.name || 'N/A'}}</td>
                                                        <td>{{data?.feedbackMessage || 'N/A'}}</td>
                                                        <td class="action_td_btn3 pointer" >
                                                            <a (click)="feedback(data?.feedbackId)"><em
                                                                    class="fa fa-eye"></em></a>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="feedbackLoopData==0">
                                                        <td colspan="7" vertical-align="middle">
                                                            <div class="no-record">
                                                                <div class="no-recordin">
                                                                    <H5>No record found</H5>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="custom-pagination mt20 text-center" style="float: right;"
                                                *ngIf="advertisementlistlength > 5 ">
                                                <pagination-controls (pageChange)="pageNumber = $event">
                                                </pagination-controls>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane active show" *ngIf="this.currTab == 'trustedby'">
                                        <div class="">
                                            <table class="table table-bordered" aria-describedby="">
                                                <thead>
                                                    <tr class="no_wrap_th">
                                                        <th id="">S.No.</th>
                                                        <th id="">Date-Time</th>
                                                        <th id="">Username</th>
                                                        <th id="">Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>03/07/2020, 01:45 PM</td>
                                                        <td>Username</td>
                                                        <td class="action_td_btn3 pointer" >
                                                            <a (click)="blockby()"><em class="fa fa-trash"></em></a>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="staffList?.length==0">
                                                        <td colspan="5" vertical-align="middle">
                                                            <div class="no-record">
                                                                <div class="no-recordin">
                                                                    <H5>No record found</H5>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="custom-pagination mt20 text-center" style="    float: right;">

                                                <pagination-controls (pageChange)="pagination($event)">
                                                </pagination-controls>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane active show" *ngIf="this.currTab == 'logs'">
                                        <div class="">
                                            <table class="table table-bordered" aria-describedby="">
                                                <thead>
                                                    <tr class="no_wrap_th">
                                                        <th id="">S.No</th>
                                                        <th id="">User Id</th>
                                                        <th id="">Date-Time</th>
                                                        <th id="">Ip Address</th>
                                                        <th id="">Browser Agent</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let data of loginDetailss | paginate:{itemsPerPage: 10, currentPage: pageNumber,totalItems: advertisementlistlength}; let i=index; ">
                                                        <td>{{10 * (pageNumber - 1) + i+1}}</td>
                                                        <td>{{data.userLoginId}}</td>
                                                        <td>{{data?.createTime | date:'medium'}}</td>
                                                        <td>{{data?.ipAddress || 'N/A'}}</td>
                                                        <td>{{data?.userAgent || 'N/A'}}</td>
                                                    </tr>
                                                    <tr *ngIf="loginDetailss.length==0">
                                                        <td colspan="5" vertical-align="middle">
                                                            <div class="no-record">
                                                                <div class="no-recordin">
                                                                    <H5>No record found</H5>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="custom-pagination mt20 text-center" style="float: right;">

                                                <pagination-controls (pageChange)="pageNumber = $event">
                                                </pagination-controls>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
        <!-- Table Responsive End -->
    </div>
</main>

<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h5 style="text-align: center; ">Remove User </h5>


                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to delete this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2"
                                        (click)="deleteFunction()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- delete_modal End -->
<!-- block_modal Start -->
<div class="modal fade global-modal reset-modal" id="block">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h5 style="text-align: center; ">Block Staff </h5>


                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to block this Staff?</p>
                                <div>
                                    <button type="submit" class="btn btn-success mr-2"
                                        >Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- active Start -->
<div class="modal fade global-modal reset-modal" id="active">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h5 style="text-align: center; ">Active Staff </h5>


                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to active this Staff?</p>
                                <div>
                                    <button type="submit" class="btn btn-success mr-2"
                                        >Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="blockUnblockModal">
    <div class="modal-dialog max-WT-500">
        <form [formGroup]="suspendForm" class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h5 style="text-align: center; ">{{userStatus}} User </h5>


                        <div class="form-group row">
                            
                            <div class="col-md-12 text-center">
                              <p> Are you sure to {{userStatus | lowercase}} this user</p>
                            </div>
                        </div>
                        <div style="text-align: center;">
                            <button class="btn btn-theme" (click)="blockUnblockUserApi()" >{{userStatus}}</button>&nbsp;&nbsp;
                            <button class="btn btn-theme" data-dismiss="modal">Cancel</button>
                        </div>

                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<!-- delete_modal Start -->
<!-- <div class="modal fade global-modal reset-modal" id="unsuspendModal">
    <div class="modal-dialog max-WT-500">
        <form [formGroup]="suspendForm" class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Active User </h3>


                        <div class="form-group row">
                            <div class="col-md-4" style="    text-align: center;">
                                <label>Reason </label>
                            </div>
                            <div class="col-md-8">
                                <textarea name="" id="" cols="40" style=" padding: 2%;"
                                    formControlName="reasonforsuspend"
                                    rows="5">Message that will be sent to the user's email if the "Notify Use"r is selected.</textarea>
                            </div>
                        </div>
                        <div style="text-align: center;">
                            <button class="btn btn-theme" >Active</button>&nbsp;&nbsp;
                            <button class="btn btn-theme" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div> -->