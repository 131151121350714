import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-view-staff',
  templateUrl: './view-staff.component.html',
  styleUrls: ['./view-staff.component.css']
})
export class ViewStaffComponent implements OnInit {
  id: any;
  staffDetails: any;
  editStaffForm: FormGroup;
  ipAddress: any;
  staffDetail: any = []
  staffPrivileges: any = [];
  newArr: any = [];
  checkedBoolead: boolean = true;
  index : any = 0
  name : any = []
  permArray : any = []
  permissionArr : any =  ["DASHBOARD_MANAGEMENT","USER_MANAGEMENT" , "SUB_ADMIN_MANAGEMENT",
  "HOT_COLD_WALLET_MANAGEMENT", "KYC_MANAGEMENT", "WALLET_MANAGEMENT","BUY_SELL_MANAGEMENT",
  "FEE_MANAGEMENT","HELP_MANAGEMENT"
  ,"BANNER_MANAGEMENT","INSTITUTIONAL_INVESTOR_MANAGEMENT","STATTIC_CONTENT_MANAGEMENT"]

  constructor(public router: Router, public param: ActivatedRoute, public service: ServiceService, public http: HttpClient) {
    for(let i of this.permissionArr){
 
      this.name[this.index] = String(i).split('_').join(' ')
      this.index++
    }

    this.param.queryParams.subscribe((res) => {
      this.id = res.id;
    
    });
    this.http.get<{ ip: string }>('https://jsonip.com')
      .subscribe(data => {
       
        this.ipAddress = data
        
      })
  }

  ngOnInit() {
    
    this.getProfile();
  }

  getProfile() {
    this.service.showSpinner();
    let dataa = {
      "primaryIdCommonPerRequest": this.id
    }
    this.service.post('account/admin/user-management/get-staff-user-profile', dataa).subscribe((res) => {
      if(res["status"] == 561){
        this.staffDetails = res["data"]["staffDetails"]
        this.staffPrivileges = res["data"]["staffPrivileges"]
        this.permission()
        this.service.hideSpinner()
        this.service.toasterSucc(res["message"])
      }
      else{
       
        this.service.hideSpinner()
        this.service.toasterSucc(res["message"])
      }
    })
  }



  /** Function for checkbox click */
  permission(){
    let index = 0
    for(let item of this.permissionArr){
      console.log(this.staffPrivileges.indexOf(item))
      if(this.staffPrivileges.indexOf(item) >= 0){
        this.permArray.push(true)
      }
      else{
        this.permArray.push(false)
      }
    }
    console.log();
    
  }


  back() {
    this.router.navigate(['/staff-management'])
  }
  edit(id) {
    this.router.navigate(['/edit-staff'], {queryParams:{id}})
  }

  

}
