<main class="middle-content">
  <!-- Page Title Start -->
  <div class="page_title_block">
    <h1 class="page_title">Fee Management</h1>
  </div>
  <!-- Page Title End -->
  <div class="content-section">

    <div class="order-view">
      <div class="custom_tabs common-tabs">
        <div class="row mb20 justify-content-center">
          <div class="col-sm-12">
            <ul class="nav nav-tabs d-flex  justify-content-center text-center">
              <li class="nav-item flex-fill">
                <a class="nav-link active show" [ngClass]="{'active': currTab=='Fee'}" (click)="selectTab('Fee')"
                  href="javascript:;">Fee Management</a>
              </li>

              <li class="nav-item flex-fill">
                <a class="nav-link active show" [ngClass]="{'active': currTab=='Tacker'}" (click)="selectTab('Tacker')"
                  href="javascript:;">Taker & Maker Fee</a>
              </li>

              <li class="nav-item flex-fill">
                <a class="nav-link active show" [ngClass]="{'active': currTab=='privilege'}"
                  (click)="selectTab('privilege')" href="javascript:;">Manage Coins</a>
              </li>

              <!-- <li class="nav-item flex-fill">
                <a class="nav-link active show" [ngClass]="{'active': currTab=='income'}" (click)="selectTab('income')"
                  href="javascript:;">Manage Trading Pairs</a>
              </li> -->
            </ul>
          </div>
        </div>

        <div class="tab-content" style="margin-left: 13px;">
          <div class="tab-pane active show" *ngIf="this.currTab == 'Feer'">
            <div class="row">
              <div class="col-md-4">
                <label>
                  <strong>Coin</strong>
                </label>
              </div>
              <div class="col-md-3">
                <label>
                  <strong>Withdraw Fee%</strong>
                </label>
              </div>

              <div class="col-md-2">
                <label class="di-action">
                  <strong>Action</strong>
                </label>
              </div>
            </div>

            <div class="row" *ngFor="let item of feecoinArry ;let i = index;">

              <div class="col-md-4">
                <label>{{item.coinShortName}}</label>
              </div>
              <div class="col-md-3">
                <label>
                  <input type="text" name="{{i}}" [(ngModel)]="item.withdrawlFee" class="form-control"
                    autocomplete="off" />
                </label>
              </div>

              <div class="col-md-2">
                <button class="btn btn-theme"
                  (click)="updatefeeapi(item.coinShortName,item.withdrawlFee)">Update</button>
              </div>
            </div>
          </div>

          <div class="tab-pane active show" *ngIf="this.currTab == 'Fee'">
            <div class="global-table no-radius p0">
              <div class="table-responsive">
                <div id="table-scroll" class="table-scroll">
                  <div class="table-wrap">
                    <table class="main-table" aria-describedby="">
                      <thead>
                        <th id="" class="text-center">Coin</th>
                        <th id="" class="text-center">Withdraw Fee</th>
                        <th id="" class="text-center">Action</th>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of feecoinArry ;let i = index">
                          <td id="" class="fixed-side">{{item.coinShortName}}</td>
                          <td>
                            <input type="text" class="form-control" name="{{i}}" [(ngModel)]="item.withdrawlFee"
                              placeholder="Withdraw Fee">
                          </td>
                          <td>
                            <a class="btn btn-large  max-WT-200 font-100 btn-green"
                              (click)="updatefeeapi(item.coinShortName,item.withdrawlFee)">Update</a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- tab2 -->

          <div class="tab-pane active show" *ngIf="this.currTab == 'MINI'">
            <div class="row">
              <div class="col-md-4">
                <label>
                  <strong>Coin</strong>
                </label>
              </div>
              <div class="col-md-3">
                <label>
                  <strong>Min Withdraw Fee</strong>
                </label>
              </div>

              <div class="col-md-2">
                <label>
                  <strong>Action</strong>
                </label>
              </div>
            </div>
            <div class="row" *ngFor="let item of feecoinArry ;let i = index;">
              <div class="col-md-4">
                <label>{{item.coinShortName}}</label>
              </div>
              <div class="col-md-3">
                <label>
                  <input type="text" name="{{i}}" [(ngModel)]="item.withdrawalAmount" class="form-control"
                    autocomplete="off" />
                </label>

              </div>
              <div class="col-md-2">
                <button class="btn btn-theme"
                  (click)="updateMiniwithdraw(item.coinShortName,item.withdrawalAmount)">Update</button>
              </div>
            </div>
          </div>

          <!-- tab3 -->
          <div class="tab-pane active show" *ngIf="this.currTab == 'Tacker'">
            <div class="global-table no-radius p0">
              <div class="table-responsive">
                <div id="table-scroll" class="table-scroll">
                  <div class="table-wrap">
                    <table class="main-table" aria-describedby="">
                      <thead>
                        <th id="" class="text-center">Coin</th>
                        <th id="" class="text-center">Taker Fee %</th>
                        <th id="" class="text-center">Maker Fee %</th>
                        <th id="" class="text-center">Action</th>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of feecoinArry ;let i = index">
                          <td id="" class="fixed-side">{{item.coinShortName}}</td>

                          <td>
                            <input type="text" class="form-control" name="{{i}}" [(ngModel)]="item.takerFee"
                              placeholder="Taker Fee %">
                          </td>
                          <td>
                            <input type="text" class="form-control" name="{{i}}" [(ngModel)]="item.makerFee"
                              placeholder="Maker Fee %">
                          </td>
                          <td>
                            <a class="btn btn-large  max-WT-200 font-100 btn-green"
                              (click)="updateTacker(item.coinShortName,item.takerFee,item.makerFee)">Update</a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="manageTrading">
          <div class="tab-pane active show" *ngIf="this.currTab == 'income'" style="width: 99%;
          margin-left: 12px;">
            <div class="table-responsive">
              <table class="table table-bordered" aria-describedby="" style="text-align: center;">
                <thead>
                  <tr class="no_wrap_th">
                    <th id="">S.No.</th>
                    <th id="">Base Currency</th>
                    <th id="">Paired Currency</th>
                    <th id="">Visible</th>
                    <th id="">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                  <tr
                    *ngFor="let data of coinpairlistarray | paginate:{itemsPerPage: 10, currentPage: pageNumber,totalItems: staffLength}; let i=index; ">
                    <td>{{10 * (pageNumber - 1) + i+1}}</td>
                    <td>{{data?.baseCoin}}</td>
                    <td>{{data?.executableCoin}}</td>
                    <td><input type="checkbox" [checked]="data?.visible"></td>
                    <td class="action_td_btn3">
                      <a data-toggle="modal" class="pointer"
                        (click)="editStafff(data?.baseCoin, data?.executableCoin, data?.visible)"><em
                          class="fa fa-edit"></em></a>
                    </td>
                  </tr>
                  <tr *ngIf="coinpairlistarray?.length==0">
                    <td colspan="5" vertical-align="middle">
                      <div class="no-record">
                        <div class="no-recordin">
                          <H5>No record found</H5>
                        </div>
                      </div>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
            <div class="custom-pagination mt20 text-center" style="    float: right;">
              <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
            </div>
          </div>
        </div>

        <div *ngIf="!manageTrading">
          <div class="tab-pane active show" *ngIf="this.currTab == 'income'" style="width: 99%;
          margin-left: 12px;">

            <h5 style="padding-left: 26px;">Edit Manage Trading</h5>
            <form [formGroup]="manageCoinPair">
              <div class="form-group row">
                <div class="col-md-4 text-right font-600">
                  Base Currency
                </div>
                <div class="col-md-4 text-center">
                  <input formControlName="baseCoin" [value]="baseCoinn" type="text" name="" id="" class="form-control"
                    readonly>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-4 text-right font-600">
                  Paired Currency
                </div>
                <div class="col-md-4 text-center">
                  <input formControlName="pairedCoin" [value]="executableCoinn" type="text" name="" id=""
                    class="form-control" readonly>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-4 text-right font-600">
                  Visible
                </div>
                <div class="col-md-4 ">
                  <input type="checkbox" [checked]="visiblen" formControlName="visible">
                </div>
              </div>
            </form>

            <div style="text-align: center">
              <button class="btn btn-theme" (click)="manageCoinsss()">Back</button>
              <button class="btn btn-theme" (click)="updateCoinPairs()">Submit</button>
            </div>

          </div>
        </div>


        <!--edit  privilege -->
        <div *ngIf="manageCoins">
          <div class="tab-pane active show" *ngIf="this.currTab == 'privilege'" style="width: 99%;
          margin-left: 12px;">
            <div class="global-table no-radius p0">
              <div class="table-responsive">
                <div>
                  <div class="table-wrap">
                    <table class="table table-bordered" aria-describedby="">
                      <thead>
                        <tr class="no_wrap_th">
                          <th id="">S.No.</th>
                          <th id="">Coin Name</th>
                          <th id="">Visible</th>
                          <th id="">Withdrawal</th>
                          <th id="">Deposite</th>
                          <th id="">Remarks</th>
                          <th id="">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                        <tr
                          *ngFor="let data of loopdata | paginate:{itemsPerPage: 10, currentPage: pageNumber,totalItems: staffLength}; let i=index; ">
                          <td>{{10 * (pageNumber - 1) + i+1}}</td>
                          <td>{{data?.coinName}}</td>
                          <td><input type="checkbox" [checked]="data?.isVisible"></td>
                          <td><input type="checkbox" [checked]="data?.isWithdrawl"></td>
                          <td><input type="checkbox" [checked]="data?.isDeposite"></td>
                          <td>{{data?.remark || '---'}}</td>
                          <td class="action_td_btn3">
                            <a data-toggle="modal" class="pointer" (click)="editStaff(data?.coinName)"><em
                                class="fa fa-edit"></em></a>
                          </td>
                        </tr>
                        <tr *ngIf="loopdata?.length==0">
                          <td colspan="5" vertical-align="middle">
                            <div class="no-record">
                              <div class="no-recordin">
                                <H5>No record found</H5>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="custom-pagination mt20 text-center" style="float: right;">
              <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
            </div>
          </div>
        </div>
        <div *ngIf="!manageCoins">
          <div class="tab-pane active show" *ngIf="this.currTab == 'privilege'">

            <h5 style="padding-left: 26px;">Update Manage coin </h5>
            <form [formGroup]="manageCoinForm">
              <div class="form-group row">
                <div class="col-md-4 text-right font-600">
                  Coin
                </div>
                <div class="col-md-4 text-center">{{particularData.coinName}}</div>
              </div>
              <div class="form-group row">
                <div class="col-md-4 text-right font-600">
                  Visible
                </div>
                <div class="col-md-4 text-center">
                  <input type="checkbox" formControlName="isVisible" [checked]="particularData.isVisible">
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-4 text-right font-600">
                  Withdrawal
                </div>
                <div class="col-md-4 text-center">
                  <input type="checkbox" formControlName="isWithdrawl" [checked]="particularData.isWithdrawl">
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-4 text-right font-600">
                  Deposit
                </div>
                <div class="col-md-4 text-center">
                  <input type="checkbox" formControlName="isDeposite" [checked]="particularData.isDeposite">
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-4 text-right font-600">
                  Remark
                </div>
                <div class="col-md-4 ">
                  <textarea formControlName="remark" name="" id="" cols="40" rows="6"
                    [value]="particularData.remark"></textarea>
                </div>
              </div>

              <div style="text-align: center;">
                <button class="btn btn-theme" (click)="manageCoinss()">Back</button>
                <button class="btn btn-theme" (click)="updateMnageCoins()">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

</main>