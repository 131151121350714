<div class="wrapper" *ngIf="isLoggedIn">
    <header class="header">
        <nav class="navbar clearfix">
            <div class="logo-box">
                <a class="navbar-brand logo" [routerLink]="['/dashboard']" routerLinkActive="router-link-active">
                    <!-- <img src="assets\img\crypto-logo.png" alt="logo" class="lg-logo"> -->
                    <img src="assets/img/dark logo.png" alt="logo" class="lg-logo">
                    <img src="assets/img/small logo.png" alt="logo" class="lg-logo1">
                </a>
            </div>
            <div class="header-right-part">
                <button class="btn btn-toggle" id="toggle_Menu" type="button"
                    style="color: black;background-color: #01A781;">
                    <em class="fas fa-bars" style="color:aliceblue"></em>
                </button>
                <button class="btn btn-outline-secondary btn-mobsearch" type="button"><em
                        class="fas fa-search"></em></button>
            </div>
        </nav>
    </header>

    <!-- side bar -->
    <aside class="sidebar" aria-labelledby="" >
        <div class="sidebar-scroller">
            <div id="accordion" class="sidebar-menu">
                <div [routerLink]="['/my-profile']" class="user-panel clearfix">
                    <div class="pull-left image">
                        <img [src]="userDetail?.imageUrl || 'assets/img/profile-img.jpg'" class="img-circle"
                            alt="User Image">
                        <a class="online-icon"><em class="fa fa-circle text-success"></em></a>
                    </div>
                    <div class="pull-left info mt10">
                        <a>
                            <p>Welcome</p>
                            <h4>{{userDetail?.firstName}}</h4>
                        </a>
                    </div>
                </div>
                
                <div class="menu-box" *ngIf="previlage?.includes('DASHBOARD_MANAGEMENT')"  [ngClass]="{'active':currUrl == 'dashboard'}">
                    <div class="card-header clearfix" id="headingOne">
                        <a class="side_menu" [routerLink]="['/dashboard']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/dashboard-icon.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Dashboard</span>
                        </a>
                    </div>
                </div>
                <div class="menu-box" *ngIf="previlage?.includes('USER_MANAGEMENT')" [ngClass]="{'active':currUrl == 'user-management-exchange'}">
                    <div class="card-header clearfix" id="headingTwo">
                        <a class="side_menu" [routerLink]="['/user-management-exchange']"
                            routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/user-manage.png" alt="icon" />
                            </span>
                            <span class="side_page_name">User Management</span>
                        </a>
                    </div>
                </div>


                <!-- <div class="menu-box" [ngClass]="{'active':currUrl == 'user-management'}">
                    <div class="card-header clearfix" id="headingTwo">
                        <a class="side_menu" [routerLink]="['/user-management']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/user-manage.png" alt="icon" />
                            </span>
                            <span class="side_page_name">User Management</span>
                        </a>
                    </div>
                </div> -->
                <div class="menu-box" *ngIf="previlage?.includes('SUB_ADMIN_MANAGEMENT')"
                    [ngClass]="{'active':currUrl == 'staff-management' || currUrl == 'add-new-staff'}">
                    <div class="card-header clearfix" id="headingTwo">
                        <a class="side_menu" [routerLink]="['/staff-management']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/user-manage.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Sub Admin Management</span>
                        </a>
                    </div>
                </div>
              

                <div class="menu-box" *ngIf="previlage?.includes('HOT_COLD_WALLET_MANAGEMENT')" [ngClass]="{'active':currUrl == 'hotcoldwallet-management'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/hotcoldwallet-management']"
                            routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/wallet.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Hot Wallet Management</span>
                        </a>
                    </div>
                </div>

                <div class="menu-box" *ngIf="previlage?.includes('KYC_MANAGEMENT')" [ngClass]="{'active':currUrl == 'kyc-management' || currUrl == 'kyc-action'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/kyc-management']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/user.png" alt="icon" />
                            </span>
                            <span class="side_page_name">KYC Management</span>
                        </a>
                    </div>
                </div>

                <div class="menu-box" *ngIf="previlage?.includes('WALLET_MANAGEMENT')" [ngClass]="{'active':currUrl == 'wallet-management'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/wallet-management']"
                            routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/setting-icon.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Wallet Management</span>
                        </a>
                    </div>
                </div>

                <!-- <div class="menu-box" [ngClass]="{'active':currUrl == 'bank-management'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/bank-management']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/setting-icon.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Bank Management</span>
                        </a>
                    </div>
                </div> -->

                <div class="menu-box" *ngIf="previlage?.includes('FEE_MANAGEMENT')" [ngClass]="{'active':currUrl == 'fee-management'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/fee-management']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/fees (1).png" alt="icon" />
                            </span>
                            <span class="side_page_name">Fee Management</span>
                        </a>
                    </div>
                </div>
          
                <div class="menu-box" [ngClass]="{'active':currUrl == 'ticket-management'}" >
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/ticket-management']"
                            routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/setting-icon.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Help Management</span>
                        </a>
                    </div>
                </div>

                <!-- 
                <div class="menu-box" [ngClass]="{'active':currUrl == 'advertisement-management'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/advertisement-management']"
                            routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/setting-icon.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Advertisement Management</span>
                        </a>
                    </div>
                </div> -->

                <div class="menu-box"  [ngClass]="{'active':currUrl == 'withdraw-limit'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/withdraw-limit']"
                            routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/setting-icon.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Withdraw Limit Management</span>
                        </a>
                    </div>
                </div>

                <div class="menu-box"  [ngClass]="{'active':currUrl == 'qte-price-management'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/qte-price-management']"
                            routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/setting-icon.png" alt="icon" />
                            </span>
                            <span class="side_page_name">QTE Price Management</span>
                        </a>
                    </div>
                </div>


                <div class="menu-box"  [ngClass]="{'active':currUrl == 'p2p-exchange'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/p2p-exchange']"
                            routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/setting-icon.png" alt="icon" />
                            </span>
                            <span class="side_page_name">P2P Exchange</span>
                        </a>
                    </div>
                </div>
                <div class="menu-box"  [ngClass]="{'active':currUrl == 'anouncement-management'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/anouncement-management']"
                            routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/setting-icon.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Anouncement Management</span>
                        </a>
                    </div>
                </div>
                <div class="menu-box" [ngClass]="{'active':currUrl == 'dispute-management'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/dispute-management']"
                            routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/setting-icon.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Banner Management</span>
                        </a>
                    </div>
                </div>
                <div class="menu-box" [ngClass]="{'active':currUrl == 'promotion-list' || currUrl?.includes ('view-promotion') ||currUrl?.includes ('edit-promotion')|| 
                currUrl == 'add-promotion'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/promotion-list']"
                            routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/setting-icon.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Promotion Management</span>
                        </a>
                    </div>
                </div>
                <div class="menu-box" [ngClass]="{'active':currUrl == 'institutional-list' || currUrl?.includes ('view-institutional') ||currUrl?.includes ('edit-institutional')|| 
                currUrl == 'add-promotion'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/institutional-list']"
                            routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/setting-icon.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Institutional Management</span>
                        </a>
                    </div>
                </div>
                <div class="menu-box" [ngClass]="{'active':currUrl == 'referal-managemnet' || currUrl?.includes('view-referal')}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/referal-managemnet']"
                            routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/setting-icon.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Referal Management</span>
                        </a>
                    </div>
                </div>

                <div class="menu-box" *ngIf="previlage?.includes('STATTIC_CONTENT_MANAGEMENT')"
                    [ngClass]="{'active':currUrl == 'statics-content' || currUrl == 'about-us' || currUrl == 'privacy' ||  currUrl == 'term-and-service'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/statics-content']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/static-content.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Static Content Management</span>
                        </a>
                    </div>
                </div>

                <div class="menu-box" *ngIf="previlage?.includes('USER_MANAGEMENT')" [ngClass]="{'active':currUrl == 'logs-management'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/logs-management']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/setting-icon.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Logs Management</span>
                        </a>
                    </div>
                </div>

                <div class="menu-box" *ngIf="previlage?.includes('USER_MANAGEMENT')"
                    [ngClass]="{'active':currUrl == 'setting' || currUrl == 'my-profile' || currUrl == 'daily-limit' || currUrl == 'banner-setting' || currUrl == 'website-content-setting'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/setting']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/setting-icon.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Settings</span>
                        </a>
                    </div>
                </div>

                <div class="menu-box">
                    <div class="card-header clearfix">
                        <a class="side_menu pointer" (click)="logoutModalOpen()">
                            <span class="side_menu_icon">
                                <img src="assets/img/logout.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Logout</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </aside>

    <!---------------------------------------------------------------SUB ADMIN----------------------------------------------------------------------------------------------------------------->
 
    <div class="modal fade global-modal reset-modal" id="logoutModal">
        <div class="modal-dialog max-WT-500">
            <div class="modal-content">
                <div>
                    <div class="modal-body  text-center">
                        <div class="row align-items-center modal_flax_height">
                            <div class="col">
                                <div class="box-title mb40 d-inline-block">
                                    <em class="fas fa-power-off off-icon"></em>
                                    <p class="mt40">Are you sure you want to logout?</p>
                                </div>
                                <div class="text-center">
                                    <button class="btn btn-theme" (click)="onLogout()">YES</button> &nbsp; &nbsp; &nbsp;
                                    &nbsp;
                                    <button type="button" class="btn btn-theme" data-dismiss="modal">CANCEL</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <footer>
        <div class="container-fluid">
            <p class="copyright">Copyright © 2020 <strong class="theme-text-color">Crypto Exchange</strong> All
                Rights Reserved.</p>
        </div>
    </footer>
</div>