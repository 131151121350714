
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">View Promotion</h1>
            <hr style="background: black; opacity: 0.5;">
        </div>
        <div class="content-section">
            <div class="order-view  max-WT-700  setting-page">
                <div class="  mb40">
                    <form>
                        <div class="add-store-block input-style mb20 mt20 ">
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-4 textalignright ">Promotion Title
                                    <span>:</span></label>
                                <div class="col-md-8 textaligncenter">
                                    <div class="form-group">

                                        <label style="font-weight: 400;">{{userData?.title}}</label>
                                    </div>
                                </div>
                            </div>


                            <div class="form-group row view-label-align">
                                <label class="col-md-4 textalignright">Promotion Image
                                    <span>:</span></label>
                                <div class="col-md-8 textaligncenter">
                                    <div class="form-group">
                                        <label for="fileInput" class="hoverable">
                                            <img [src]="userData?.image"
                                            alt="" style="height: 200px;
                                                width : 200px" />



                                        </label>


                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline view-label-align">
                              <label class="col-md-4 textalignright ">Promotion Status
                                  <span>:</span></label>
                              <div class="col-md-8 textaligncenter">
                                  <div class="form-group">

                                      <label style="font-weight: 400;">{{userData?.promotionStatus ? 'Active' : 'Deactive'}}</label>
                                  </div>
                              </div>
                          </div>
                            <div class="form-group row  view-label-align">
                                <label class="col-md-4 textalignright">Description 
                                    <span>:</span></label>
                                <div class="col-md-8 textaligncenter">
                                    <div class="form-group " [innerHTML]="userData?.description">
                                        <!-- <label style="font-weight: 400;">{{userData?.description}}</label> -->

                                    </div>
                                </div>
                            </div>
                            <!-- <div class="form-group row  view-label-align">
                                <div class="col-md-12">
                                    <div class="form-group " style="font-size: 13px;" [innerHTML]="description">
                                </div>
                            </div> -->
                        </div>

                        <div class="text-center mt40 mb40">
                            <button class="btn btn-large  max-WT-150 btn-secondary"
                                routerLink="/promotion-list">Back</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </main>



