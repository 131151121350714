<main class="middle-content" id="view">
    <div class="page_title_block">
        <h1 class="page_title">QTE Price Management</h1>
    </div>
    <div class="custom_tabs common-tabs">
        <div class="content-section">
            <div class="tab-pane active show">
                <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                    <div class="main-block-innner mb40 mt40">
                        <div class="add-store-block input-style">
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-6">QTE Current Price</label>
                                <div class="col-md-1">:</div>
                                <div class="col-md-5">
                                    <!-- <span>{{priceDeatil?.qtePrice || 'N/A'}}</span> -->
                                    <span>{{qtePrice || '--'}}</span>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-6">Updated At</label>
                                <div class="col-md-1">:</div>
                                <div class="col-md-5">
                                    <span>{{(priceDeatil?.updateTime | date : 'medium')|| 'N/A'}}</span>
                                </div>
                            </div>



                            <!-- <div class="form-group row align-items-baseline">
                                <label class="col-md-6">QTE Price</label>
                                <div class="col-md-1">:</div>
                                
                                <div class="col-md-5 d-flex">
                                   <button class="btn btn-primary" (click)="incrementDecrement()" style="height: 34px;" >{{isAdd}}</button> &nbsp;
                                  
                                   <input type="text" (input)="checkInput()" class="form-control" [(ngModel)]="price" #qteprice pattern="/(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/">
                                  <span class="percent">%</span>
                                </div>
                                
                            </div>
                            <div class="row"  *ngIf="isValid">
                                <div class="col-md-7"></div>
                                <div class="col-md-5"><p class="text-danger pull-right">Percentage should be between 0% to 100%</p></div>
                            </div>
                        
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-6">Updated Price</label>
                                <div class="col-md-1">:</div>
                                <div class="col-md-5">
                                    <input type="text" class="form-control" (input)="calculatePercent()"  [(ngModel)]="updatedPrice">
                                  
                                </div>
                            </div> -->

                            <div class="form-group row align-items-baseline">
                                <label class="col-md-6">QTE Last Updated Price</label>
                                <div class="col-md-1">:</div>
                                <div class="col-md-5">
                                    <input type="text" class="form-control" [(ngModel)]="updatedPrice">
                                </div>
                            </div>


                            <div class="form-group row align-items-baseline">
                                <label class="col-md-6">% Change</label>
                                <div class="col-md-1">:</div>

                                <div class="col-md-5 d-flex">
                                    <input type="text" (input)="checkInput()" class="form-control"
                                        [(ngModel)]="pricePercentage" #qteprice
                                        pattern="/(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/">
                                    <span class="percent">%</span>
                                </div>

                            </div>
                            <div class="row" *ngIf="isValid">
                                <div class="col-md-7"></div>
                                <div class="col-md-5">
                                    <p class="text-danger pull-right">Percentage should be between 0% to 100%</p>
                                </div>
                            </div>







                        </div>
                        <div class="text-left mt40 text-center">
                            <!-- <a (click)="set_update()" class="btn btn-large  max-WT-200 font-100 btn-green">Submit</a>  -->
                            <button [disabled]="isValid || !updatedPrice" (click)="setQtePrice()"
                                class="btn btn-large  max-WT-200 font-100 btn-green">Submit</button>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</main>