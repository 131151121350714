
<div class="wrapper">

    <!-- Aside End -->
    <main class="middle-content" >
        <!-- Page Title Start -->
        <div class="page_title_block">
            <h1 class="page_title">Terms & Conditions</h1>

        </div>
        <!-- Page Title End -->
        <div class="content-section">
            <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block1 input-style">
                        <div class="form-group row ">
                            <label class="col-md-3">Page Name</label>
                            <label class="col-md-1">:</label>
                            <div class="col-md-8" style="margin-top: -15px;">
                                <textarea class="form-control1 common-textarea" readonly [(ngModel)]="dataa.pageKey" ></textarea>
                            </div>
                        </div>
                        <div class="form-group row ">
                            <label class="col-md-4">Description</label>
                        </div>
                        <div class="row">
                            <div class="col-md-12" *ngIf="dataa.pageData">
                                <ck-editor name="editor1" [(ngModel)]="dataa.pageData" skin="moono-lisa" language="en" [fullPage]="true"></ck-editor>
                          </div>
                        </div>
                        <div class="text-center mt40">
                            <a href="javascript:void(0)" class="btn btn-large  max-WT-200 font-100 btn-green" (click)="saveTerms()">Update</a>
                            <a href="javascript:void(0)" class="btn btn-large  max-WT-200 font-100 btn-grey ml5" [routerLink]="['/statics-content']">Back</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <!-- Middle Content End -->
</div>
<!-- Wrapper End -->
<!--Modal Start-->
<div class="modal fade global-modal reset-modal" id="delet_farms_modal">
    <div class="modal-dialog max-WT-500">
        <div class="modal-content">
            <!-- Modal body -->
            <div class="modal-body  text-center">
                <div class="row align-items-center modal_flax_height">
                    <div class="col">
                        <div class="box-title mb40 d-inline-block">
                            <h2>Are you sure?</h2>
                            <p>You won’t be able to revert this!</p>
                        </div>
                        <div class="max-WT-300 d-inline-block border-top">
                            <button type="button" class="btn btn-gray btn-large radius0 btn-block">YES</button>
                            <button type="button" class="btn btn-red btn-large radius0 btn-block" data-dismiss="modal">CANCEL</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- signout_modal Start -->

<!-- Signout Modal -->
<!-- invite_modal Start -->
<div class="modal fade global-modal reset-modal" id="invite">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-header ">
                        <h4 class="modal-title text-center">Invite</h4>
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                    </div>
                    <div class="modal-body">
                        <div class="row align-items-center modal_flax_height">
                            <div class="col">
                                <div class="form-group">
                                    <label class="control-labe">Email*</label>
                                    <input class="form-control" placeholder="" required="" type="email">
                                </div>
                                <div class="form-group">
                                    <label class="control-labe">Subject</label>
                                    <input class="form-control" placeholder="" required="" type="text">
                                </div>
                                <div class="form-group">
                                    <label class="control-labe">Message</label>
                                    <textarea name="" id="" cols="20" rows="5" class="form-control">
                                </textarea>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <button type="submit" class="btn btn-gray btn-large radius0 btn-block">SUBMIT</button>
                                    </div>
                                    <div class="col-6">
                                        <button type="button" class="btn btn-red btn-large radius0 btn-block" data-dismiss="modal">CANCEL</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- invite_modal End -->
<!-- invite_modal Start -->
<div class="modal fade global-modal reset-modal" id="accept">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-header ">
                        <h4 class="modal-title text-center">Accept</h4>
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                    </div>
                    <div class="modal-body">
                        <div class="row align-items-center modal_flax_height">
                            <div class="col">
                                <div class="form-group">
                                    <div class="d-flex align-items-center text-center justify-content-center">
                                        <label class="switch">
                                        <input type="checkbox">
                                        <span class="slider round"></span>
                                    </label>
                                        <strong> R/W Accesbility</strong>
                                    </div>
                                </div>
                                <hr>
                                <div class="form-group">
                                    <div class="d-flex align-items-center">
                                        <div class="w-100">
                                            <h6>User Type</h6>

                                            <div class="radio_btn_cst d-flex">
                                                <label class="radio_style">End User
                                                <input type="radio" checked="checked" name="radio" />
                                                <span class="checkmark"></span>
                                            </label>
                                                <label class="radio_style">Service User
                                                <input type="radio" name="radio" />
                                                <span class="checkmark"></span>
                                            </label>
                                                <label class="radio_style">Admin User
                                                <input type="radio" name="radio" />
                                                <span class="checkmark"></span>
                                            </label>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="w-100">
                                        <h6>Role</h6>
                                        <select name="" class="form-control form-control2 select_opt">
                                        <option value="">Select</option>
                                        <option value="" selected>Abuja region</option>
                                        <option value="">Nigeria region</option>
                                    </select>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="d-flex align-items-center flex-wrap text-center justify-content-center">
                                        <div class="w-50 d-inline-flex align-items-center">
                                            <label class="switch">
                                            <input type="checkbox">
                                            <span class="slider round"></span>
                                        </label>
                                            <span> Admin Rights</span>
                                        </div>
                                        <div class="w-50 d-inline-flex align-items-center">
                                            <label class="switch">
                                            <input type="checkbox">
                                            <span class="slider round"></span>
                                        </label>
                                            <span> Approved</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt20 text-center">
                                    <button class="btn btn-small btn-info">Set</button>
                                    <button class="btn btn-small btn-danger" data-dismiss="modal">Cancel</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- invite_modal End -->

<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="delete">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>

                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure want to delete this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-info">Submit</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- delete_modal End -->
<!-- block_modal Start -->
<div class="modal fade global-modal reset-modal" id="block">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>

                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure want to block this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-success">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
