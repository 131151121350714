<main class="middle-content">
  <div class="page_title_block">
      <h1 class="page_title">ADD BANNER</h1>
     
  </div>
  <div class="content-section">
      <!-- <div class="page_title_block" style="padding-left: 21%;padding-top: 7%;">
          <h1 class="page_title">Add Banner</h1>
      </div> -->
      <div class="order-view setting-page">
          <div class="  mb40">
              <form [formGroup]="addBannerForm" >
                  <div class="input-style mb20 mt20 ">
                      <div class="form-group row align-items-baseline view-label-align">
                          <label class="col-md-4 textalignright">Banner Title
                              </label>
                              <div class="col-md-1">
                                <span>:</span>
                               </div>
                          <div class="col-md-7 textaligncenter">
                              <div class="form-group ">
                                  <input type="text" class="form-control" placeholder="Enter banner title"
                                      formControlName="title" (keypress)="mainService.preventSpace($event)"
                                      maxlength="50">
                                  <div class="text-danger">
                                      <span
                                          *ngIf="addBannerForm.get('title').hasError('required') && (addBannerForm.get('title').dirty || addBannerForm.get('title').touched )">
                                          *Please enter banner title.
                                      </span>

                                  </div>
                              </div>
                          </div>
                      </div>

                      <div class="form-group row view-label-align">
                          <label class="col-md-4 textalignright">Upload Image
                              </label>
                              <div class="col-md-1">
                                <span>:</span>
                               </div>
                          <div class="col-md-4 textaligncenter">
                              <div class="user-profile hoverable ">
                                  <div  *ngIf="imgSrc" class="image-boxs" > 
                                      <img  class="image-boxs" src= {{imgSrc}} id="sub-admin-image" alt=""  style="max-width: 60%;"> 
                                  </div>
                              <label class="upload-label">
                                  <input type="file" formControlName="image" name="image"
                                      accept="image/jpg,image/jpeg,image/png" id="image"
                                      (change)="ValidateFileUpload($event)" >
                              </label>
                          
                              </div>
                          </div>
                      </div>
                      <div class="form-group row align-items-baseline view-label-align" style="margin-top: 6%;">
                          <label class="col-md-4 textalignright">Activate
                             </label>
                              <div class="col-md-1">
                                <span>:</span>
                               </div>
                          <div class="col-md-7 textaligncenter">
                              <div>
                                  <input type="checkbox" formControlName = "isActive">

                              </div>
                          </div>
                      </div>

                      <div class="form-group row align-items-baseline view-label-align">
                          <label class="col-md-4 textalignright ">Description
                            
                          </label>
                         <div class="col-md-1">
                          <span>:</span>
                         </div>
                          <div class="col-md-7 textaligncenter">
                            <div class="form-group ">
                                <!-- <ck-editor  name="editor1" [(ngModel)]="editorValue" skin="moono-lisa" language="en" [fullPage]="true"></ck-editor> -->

                                <!-- <ck-editor formControlName="description"></ck-editor> -->
                                <textarea name="" id="" class="form-control" formControlName="description" cols="30" rows="10"></textarea>
                                <div
                                  *ngIf="addBannerForm.get('description').invalid && (addBannerForm.get('description').dirty || addBannerForm.get('description').touched)"
                                  class="text-danger">
                                  <span *ngIf="addBannerForm.get('description').hasError('required')">
                                    *Please enter description.</span>
                                </div>
                              </div>
                        </div>
                      </div>

                      <div class="form-group row  view-label-align">

                         
                      </div>
                  </div>

                  
                  <div class="mt40 mb40 text-center">
                      <button class="btn btn-large  max-WT-150 btn-secondary" routerLink="/dispute-management">Cancel</button>
                      <button class="btn btn-large  max-WT-150 btn-primary ml-2"
                      [disabled]="!addBannerForm.valid" (click)="addBanner()">Submit</button>
                  </div>
              </form>
          </div>
      </div>
  </div>
</main>