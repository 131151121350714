import { filter } from "minimatch";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ServiceService } from "../service.service";
import { DatePipe } from "@angular/common";
import { FormGroup, FormControl, Validators } from "@angular/forms";

declare var $: any;
@Component({
  selector: "app-dispute-management",
  templateUrl: "./dispute-management.component.html",
  styleUrls: ["./dispute-management.component.css"],
})
export class DisputeManagementComponent implements OnInit {
  searchForm: FormGroup;
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any = 0;
  bannerId: number;
  isActive: any = [];
  userDataList: any = [];
  bannerStatus: any;
  totalBlock = 0;
  constructor(private mainService: ServiceService, private route: Router) {}

  ngOnInit() {
    this.searchFormValidation();
    this.getBanner();
  }
  searchFormValidation() {
    this.searchForm = new FormGroup({
      fromDate: new FormControl(""),
      toDate: new FormControl(""),
      text: new FormControl(""),
    });
  }

  getBanner() {
    this.totalBlock = 0;
    let url = "static/admin/static-content/get-banner-list";
    let i = 0;

    this.mainService.showSpinner();
    this.mainService.get(url).subscribe((res) => {
      if (res["status"] == 200) {
        // this.userDataList = res['data'][0].categoryDetails
        this.userDataList = res["data"][0].categoryDetails.filter((res) => {
          return res.bannerStatus != "DELETED";
        });
        // console.log(this.userDataList);

        for (let i = 0; i < this.userDataList.length; i++) {
          if (this.userDataList[i].bannerStatus == "BLOCK") {
            this.totalBlock++;
          }
        }
        // console.log("------------------------------->", this.totalBlock);
        for (let item of this.userDataList) {
          if (item.bannerStatus == "ACTIVE") {
            this.isActive[i] = true;
          }
          if (item.bannerStatus == "BLOCK") {
            this.isActive[i] = false;
          }

          i++;
        }
        this.totalItems = this.userDataList.length;

        this.mainService.hideSpinner();
        this.mainService.toasterSucc(res["message"]);
      } else {
        this.mainService.hideSpinner();
        this.mainService.toasterSucc(res["message"]);
      }
    });
  }

  deleteBanner() {
    // $('#deleteUser').modal('hide');
  
    
    if (this.totalItems == 1) {
      this.mainService.toasterWarn(
        "Banner List cannot be empty. Alteast one banner is required"
      );
      return;
    }
    let url = "static/admin/delete-banner?bannerId=" + this.bannerId;
    const data = {};
    this.mainService.postApi(url, {}).subscribe((res) => {
      if (res.status == 200) {
        this.mainService.hideSpinner();
        $("#deleteModal").modal("hide");
        this.mainService.toasterSucc(res.message);
        this.getBanner();
      } else {
        this.mainService.hideSpinner();
        this.mainService.toasterErr(res.message);
      }
    });
  }

  // ------------------------------- delete user ----------------------------- //
  deleteUserModal(id) {
    this.bannerId = id;
    $("#deleteModal").modal("show");
  }

  hideModal() {
    // $('#deleteUser').modal('hide')
  }

  viewBanner(id) {
    this.route.navigate(["/view-banner"], {
      queryParams: {
        id: id,
      },
    });
  }
  editBanner(id) {
    this.route.navigate(["/edit-banner"], { queryParams: { id: id } });
  }

  changeBannerStatus(bannerStatus, bannerId) {
    let status = bannerStatus;
    if (bannerStatus) {
      status = "ACTIVE";
      this.getActive(status, bannerId);
    } else {
      status = "BLOCK";
      this.getBlock(status, bannerId);
    }
  }
  getActive(status, bannerId) {
    let url = "static/admin/active-banner-status?bannerId=" + bannerId;
    const data = {
      bannerId: bannerId,
      // "bannerStatus" : status,
    };
    // console.log(data.bannerStatus);
    this.mainService.postApi(url, data).subscribe((res) => {
      if (res.status == 200) {
        this.mainService.hideSpinner();
        this.mainService.toasterSucc(res.message);
        this.getBanner();
      } else {
        this.mainService.hideSpinner();
        this.mainService.toasterErr(res.message);
      }
    });
  }

  getBlock(status, bannerId) {
    console.log(this.userDataList.length - this.totalBlock);

    if (this.userDataList.length - this.totalBlock == 1) {
      this.mainService.toasterWarn(
        "Banner List cannot be empty. Alteast one banner is required"
      );
      return;
    }
    let url = "static/admin/block-banner?bannerId=" + bannerId;
    const data = {
      bannerId: bannerId,
      bannerStatus: status,
    };
    console.log(data.bannerStatus);
    this.mainService.postApi(url, data).subscribe((res) => {
      if (res.status == 200) {
        this.mainService.hideSpinner();
        this.mainService.toasterSucc(res.message);
        this.getBanner();
      } else {
        this.mainService.hideSpinner();
        this.mainService.toasterErr(res.message);
      }
    });
  }

  pagination(event) {
    this.currentPage = event;
  }
}
