import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-p2p-exchange',
  templateUrl: './p2p-exchange.component.html',
  styleUrls: ['./p2p-exchange.component.css']
})
export class P2pExchangeComponent implements OnInit {
  transferForm: FormGroup;
  minDate: any;
  itemsPerPage = 10;
  currentPage:number = 1;
  totalItems: any
  flip : string;
  p2pArray:any=[]
  count: any=0;
  fromDate: number;
  toDate: number;
  constructor(
    private router : Router,
    public service: ServiceService
  ) { }

  ngOnInit() {
    this.checkTransferFormValidations();
  
  this.getP2p()
  }
 
 //----------- form validation ------------// 

  checkTransferFormValidations() {
    this.transferForm = new FormGroup({
        'type': new FormControl(''),
        'fromDate' : new FormControl(''),
        'toDate' : new FormControl('')
    })
}

//----------  get list of p2p -----------//
getP2p(){
  this.service.showSpinner()
  let url=`p2p-exchange/admin/get-order-history?page=${this.currentPage-1}&pageSize=${this.itemsPerPage}`
  this.service.get(url).subscribe((res)=>{
    if(res['status']==200){
      this.service.hideSpinner()
      this.p2pArray=res['data'].getOrderHistoryDetails
      this.service.toasterSucc(res['message'])
      this.count=res['allCountDetails']
    }
    else{
      this.service.hideSpinner()
      this.service.toasterErr(res['message']) 
    }
  })
}

geer(ev){
  console.log(ev);
  
}
//------- search functionality ---------//
search(){
  this.p2pArray=[]
  let url=""
 if(this.transferForm.value.type=="BUY"){
  url=`p2p-exchange/admin/get-order-history?orderType=${this.transferForm.value.type}&page=${this.currentPage-1}&pageSize=${this.itemsPerPage}`
  this.service.showSpinner()
  this.service.get(url).subscribe((res)=>{
    if(res['status']==200){
      this.service.hideSpinner()
      this.p2pArray=res['data'].buyOrderHistoryDetails
      this.service.toasterSucc(res['message'])
      this.count=res['buyCount']
    }
    else{
      this.p2pArray=[]
      this.count=0
      this.service.hideSpinner()
      this.service.toasterErr(res['message'])
    }
  })
  }
  else if(this.transferForm.value.type=="SELL"){
    url=`p2p-exchange/admin/get-order-history?orderType=${this.transferForm.value.type}&page=${this.currentPage-1}&pageSize=${this.itemsPerPage}`

    this.service.showSpinner()
    this.service.get(url).subscribe((res)=>{
      if(res['status']==200){
        this.service.hideSpinner()
        this.p2pArray=res['data'].sellOrderHistoryDetails
        this.service.toasterSucc(res['message'])
        this.count=res['sellCount']
      }
      else{
        this.p2pArray=[]
        this.count=0
        this.service.hideSpinner()
        this.service.toasterErr(res['message'])
      }
    })
  }
  else if(this.transferForm.value.fromDate && this.transferForm.value.toDate){
    this.fromDate=Date.parse(this.transferForm.value.fromDate)
    this.toDate= Date.parse(this.transferForm.value.toDate)
   url=`p2p-exchange/admin/get-order-history?fromDate=${this.fromDate}&page=${this.currentPage-1}&pageSize=${this.itemsPerPage}&toDate=${this.toDate}`
   this.service.showSpinner()
    this.service.get(url).subscribe((res)=>{
      if(res['status']==200){
        this.service.hideSpinner()
        this.p2pArray=res['data'].sellOrderHistoryDetails
        this.service.toasterSucc(res['message'])
        this.count=res['sellCount']
      }
      else{
        this.p2pArray=[]
        this.count=0
        this.service.hideSpinner()
        this.service.toasterErr(res['message'])
      }
    })
  }
  // else{
  //   this.fromDate=Date.parse(this.transferForm.value.fromDate)
  //   this.toDate= Date.parse(this.transferForm.value.toDate)
  //   url=`p2p-exchange/admin/get-order-history?fromDate=${this.transferForm.value.fromDate}&orderType=${this.transferForm.value.type}&page=${this.currentPage-1}&pageSize=${this.itemsPerPage}&toDate=${this.transferForm.value.toDate}`
  // }

  
}


//------------ pagination -------//
pagination(event) {
  this.currentPage = event;
  this.getP2p();
}

reset(){
  this.transferForm.patchValue({
  toDate:"",
  fromDate:'',
  type:'Select'
  })
  this.getP2p()
}
}
