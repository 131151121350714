<main class="middle-content">
  <div class="page_title_block">
    <h1 class="page_title">Trade Details</h1>
  </div>
  <div class="content-section">
    <div class="outer-box">
      <div class="global-table no-radius p0">
        <div class="tab-content1">
          <div class="tab-pane1">
            <div class="row">
              <div class="col-md-6">
                <!-- chat section -->
                <div class="chat-box-main">
                  <div class="chat-box">
                    <div class="chat-head">
                      <h4>Chat</h4>
                    </div>
                    <div class="chat-div" #scrollMe style="overflow-y: auto; height: 350px;">
                      <div *ngFor="let item of chatHistory">
                        <div *ngIf="tradeDetails.fkUserId != item.fromUserId" class="left-chat">

                          <div class="d-flex">
                            <img [src]="item?.profileImageOfBuyer || 'https://dummyimage.com/50x50/234795/fff'"
                              class="profileChat img-circle" alt="sender">
                            <div class="chat">
                              <p *ngIf="item.messageFormat == 'TEXT' && item.message">
                                {{item?.message}}</p>
                              <img class="img_def" target="_blank" [src]="item.message"
                                *ngIf="item.messageFormat == 'IMAGE'" alt="">
                              <span>{{item?.creationTime | date}}</span>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="tradeDetails.fkUserId == item.fromUserId" class="right-chat">
                          <div class="d-flex">
                            <img [src]="item?.profileImageOfBuyer || 'https://dummyimage.com/50x50/234795/fff'"
                              class="profileChat img-circle" alt="sender" style="order: 1;">
                            <div class="chat2" style="order: 0;">
                              <p *ngIf="item.messageFormat == 'TEXT' && item.message">
                                {{item?.message}}</p>
                              <img class="img_def" target="_blank" [src]="item.message"
                                *ngIf="item.messageFormat == 'IMAGE'" alt="">
                              <span>{{item?.creationTime | date}}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end chat section -->
              </div>
              <div class="col-md-6">
                <div class="sec_head_new">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group row trafe-row">
                        <label class="col-sm-6 col-form-label headinds">Trade Type </label>
                        <div class="col-sm-5">
                          <span class=" col-form-label">{{tradeDetails?.type || 'N/A'}}</span>
                        </div>
                      </div>
                      <div class="form-group row trafe-row">
                        <label class="col-sm-6 col-form-label headinds">Advertisement</label>
                        <div class="col-sm-6">
                          <span class=" col-form-label">{{tradeDetails?.tradingPartner || 'N/A'}}</span>
                        </div>
                      </div>
                      <div class="form-group row trafe-row">
                        <label class="col-sm-6 col-form-label headinds">Ad Creator</label>
                        <div class="col-sm-6">
                          <span class=" col-form-label">{{tradeDetails?.tradingPartner || 'N/A'}}</span>
                        </div>
                      </div>
                      <div class="form-group row trafe-row">
                        <label class="col-sm-6 col-form-label headinds">Status</label>
                        <div class="col-sm-6">
                          <span class=" col-form-label">{{tradeDetails?.statusType || 'N/A'}}</span>
                        </div>
                      </div>
                      <div class="form-group row trafe-row">
                        <label class="col-sm-6 col-form-label headinds">Amount BTC</label>
                        <div class="col-sm-6">
                          <span class=" col-form-label">{{tradeDetails?.totalBTC || 'N/A'}} <span
                              *ngIf="tradeDetails?.totalBTC">{{tradeDetails?.cryptoCoin}}</span></span>
                        </div>
                      </div>
                      <div class="form-group row trafe-row">
                        <label class="col-sm-6 col-form-label headinds">For</label>
                        <div class="col-sm-6">
                          <span class=" col-form-label">{{tradeDetails?.tradeAmount || 'N/A'}} <span
                              *ngIf="tradeDetails?.tradeAmount">{{tradeDetails?.fiatCoin}}</span> </span>
                        </div>
                      </div>
                      <div class="form-group row trafe-row">
                        <label class="col-sm-6 col-form-label headinds">Exchange Rate</label>
                        <div class="col-sm-6">
                          <span class=" col-form-label">{{tradeDetails?.price || 'N/A'}} <span
                              *ngIf="tradeDetails?.price">{{tradeDetails?.cryptoCoin}}/{{tradeDetails?.fiatCoin}}</span>
                          </span>

                        </div>
                      </div>
                      <div class="form-group row trafe-row">
                        <label class="col-sm-6 col-form-label headinds">Payment Method</label>
                        <div class="col-sm-6">
                          <span class=" col-form-label">{{tradeDetails?.paymentType || 'N/A'}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- trade history section -->
            <!-- end trade history section -->
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mt40">
      <button class="btn btn-large  max-WT-200 font-100 btn-green mr-2" (click)="backNavi()">Back</button>

    </div>
  </div>
</main>