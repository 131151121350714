import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { ServiceService } from "src/app/service.service";
declare var $
@Component({
  selector: "app-institutional-history",
  templateUrl: "./institutional-history.component.html",
  styleUrls: ["./institutional-history.component.css"],
})
export class InstitutionalHistoryComponent implements OnInit {
  searchForm: FormGroup;
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any = 0;
  bannerId: number;
  isActive: any = [];
  userDataList: any = [];
  bannerStatus: any;
  promId: any;
  constructor(private mainService: ServiceService, private route: Router) {}

  ngOnInit() {
    this.searchFormValidation();
    this.getInstitute();
  }

  //----------- search form validation --------------//

  searchFormValidation() {
    this.searchForm = new FormGroup({
      fromDate: new FormControl(""),
      toDate: new FormControl(""),
      text: new FormControl(""),
    });
  }

  viewInstitute(id){
  
    
    this.route.navigate(['/view-promotion'],{queryParams:{id:id}})
  }
  editInstitute(id){
    this.route.navigate(['/edit-promotion'],{queryParams:{id:id}})
  }


//------------- get advertisement list --------------  //
getInstitute() {
  let url = `static/admin/get-promotion-Details-by-pagination?page=${this.currentPage-1}&pageSize=${this.itemPerPage}`;
  let i = 0;
  // this.mainService.showSpinner()
  this.mainService.postApi(url,{}).subscribe((res) => {
    if (res["status"] == 200) {
      this.userDataList = res["data"].PromotionDetails;
      console.log(res);
      for (let item of res["data"].PromotionDetails) {
        if (item.promotionStatus == "ACTIVE") {
          this.isActive[i] = true;
        }
        if (item.promotionStatus == "BLOCK") {
          this.isActive[i] = false;
        }
        i++;
      }
 
      this.totalItems = res["data"].totalPromotion;

      this.mainService.hideSpinner();
      this.mainService.toasterSucc(res["message"]);
    } else if(res["status"] == 205){
      this.mainService.hideSpinner();
      this.mainService.toasterSucc("No record found");
    }
  
    else {
      this.mainService.hideSpinner();
      this.mainService.toasterSucc(res["No record found"]);
    }
  });
}

deletePromotion(promotionId){
  this.promId=promotionId
  $('#deleteModal').modal('show');
}

// ----------------- delete institutional -------------- //

deleteInstitute() {
  
  let url = "static/admin/delete-promotion?promotionId=" + this.promId;
  const data = {};
  this.mainService.postApi(url, {}).subscribe((res) => {
    if (res.status == 200) {
      $('#deleteModal').modal('hide');
      this.mainService.hideSpinner();
      this.mainService.toasterSucc(res.message);
     
      this.getInstitute();
    } else {
      $('#deleteModal').modal('hide');
      this.mainService.hideSpinner();
      this.mainService.toasterErr(res.message);
    }
  });
}

//------------  change institutional status -------------//
changeInstituteStatus(bannerStatus, bannerId) {
  let status = bannerStatus;
  if (bannerStatus) {
    status = "ACTIVE";
    this.getActive(status, bannerId);
  } else {
    status = "BLOCK";
    this.getBlock(status, bannerId);
  }
}


//------------ active institutional --------------//
getActive(status, promotionId) {
  let url = "static/admin/active-promotion?promotionId=" + promotionId;  
  const data = {
    bannerId: promotionId,
    bannerStatus: status,
  };
  console.log(data.bannerStatus);
  this.mainService.postApi(url, data).subscribe((res) => {
    if (res.status == 200) {
      this.mainService.hideSpinner();
      this.mainService.toasterSucc(res.message);
    } else {
      this.mainService.hideSpinner();
      this.mainService.toasterErr(res.message);
    }
  });
}

//----------- block institutional --------------//
getBlock(status, promotionId) {
  let url = "static/admin/block-promotion?promotionId=" + promotionId;
  const data = {
    bannerId: promotionId,
    bannerStatus: status,
  };
  console.log(data.bannerStatus);
  this.mainService.postApi(url, data).subscribe((res) => {
    if (res.status == 200) {
      this.mainService.hideSpinner();
      this.mainService.toasterSucc(res.message);
    } else {
      this.mainService.hideSpinner();
      this.mainService.toasterErr(res.message);
    }
  });
}


// ---------- pagination -----------------//
  pagination(event) {
    this.currentPage = event;
    this.getInstitute();
  }
}
