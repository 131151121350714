<main class="middle-content" style="margin-top:-3%;">
    <!-- Page Title Start -->
    <div class="page_title_block">
        <h1 class="page_title">Banner Management</h1>
    </div>
    
    <!-- Page Title End -->
    <div class="content-section">
        
        <div class="outer-box">
            <div class="col-md-12">
                <div class="admin-btn pull-right">
                    <button type="button" class="btn  btn-theme" routerLink="/add-banner"
                      style="margin-right : -15px;margin-bottom : 8px"  >Add Banner</button>
                </div>
            </div>
            <div class="custom_tabs common-tabs">
                <div class="tab-content">
                    <div class="tab-pane active show" id="btc">
                        <div class="tab-pane1">

                            <div class="table-responsive">
                                <table class="table table-bordered" aria-describedby="">
                                    <thead>
                                        <tr class="no_wrap_th">
                                            <th id="">S.No</th>
                                            <th id="">Banner Title</th>
                                            <th id="">Banner Image</th>

                                            <th id="">Created Date & Time</th>

                                            <th id=""> Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="text-center"
                                            *ngFor="let item of userDataList | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage, totalItems: totalItems} ; let i = index">
                                            <td>{{itemPerPage * (currentPage-1)+ i+1}}</td>
                                            <td class="content">
                                                {{item?.title}}</td>

                                            <td class="content"><img src="{{item?.image}}" alt="Banner Image"
                                                    style="width: 66px;max-height: 66px;">
                                            </td>
                                            <td class="content"> {{item?.updateAt| date : 'dd/MM/yyyy, h:mm a'}}</td>
                                            <td>
                                                <div class="action-btn-alignment">
                                                    <a data-toggle="modal" class="pointer"
                                                        (click)="viewBanner(item?.bannerId)"><em class="fa fa-eye"
                                                            title="View"></em></a>
                                                    <a data-toggle="modal" class="pointer ml-2"
                                                        (click)="editBanner(item?.bannerId)"><em class="fa fa-edit"
                                                            title="View"></em></a>
                                                    <a data-toggle="modal" class="pointer ml-2"
                                                        (click)="deleteUserModal(item?.bannerId)"><em class="fa fa-trash"
                                                            title="View"></em></a>
                                                  


                                                    <label class="switch ml-2">
                                                        <input type="checkbox" [disabled]="userDataList.length - totalBlock == 1" [value]="isActive[i]"
                                                            [(ngModel)]="isActive[i]">
                                                        <span class="slider round"
                                                            (click)="changeBannerStatus(!isActive[i],item?.bannerId)"
                                                            [title]="(isActive[i]==true)?'Enabled':'Disabled'"></span>
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="text-center" *ngIf="totalItems == 0" >
                                            <td colspan="7" vertical-align="middle">
                                                <div class="no-record">
                                                    <div class="no-recordin">
                                                        <H5>No record found</H5>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <div *ngIf="totalItems > itemPerPage" class="custom-pagination mt20 " style="display: flex; justify-content:flex-end">
                                <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- Table Responsive End -->
    </div>
</main>

<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <div class="modal-body">
                        <h5 style="text-align: center; ">Delete banner</h5>
                        <div class="modal-inner-content">
                            <div class="modal-body">
                                <div
                                    class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                                    <div class="w-100">
                                        <p>Are you sure you want to delete this banner?</p>
                                        <div>
                                            <button type="submit" class="btn btn-info mr-2" (click)="deleteBanner()">Yes</button>
                                            <button type="button" class="btn btn-danger"
                                                data-dismiss="modal">No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- delete_modal End -->
<!-- block_modal Start -->
<div class="modal fade global-modal reset-modal" id="block">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <div class="modal-body">
                        <h5 style="text-align: center; ">Block Staff</h5>
                        <div class="modal-inner-content">
                            <div class="modal-body">
                                <div
                                    class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                                    <div class="w-100">
                                        <p>Are you sure you want to block this staff?</p>
                                        <div class="modal-btn-box">
                                            <button type="submit" class="btn btn-success mr-2">Yes</button>
                                            <button type="button" class="btn btn-danger"
                                                data-dismiss="modal">No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- active Start -->
<div class="modal fade global-modal reset-modal" id="active">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <div class="modal-body">
                        <h5 style="text-align: center; ">Active Staff </h5>
                        <div class="modal-inner-content">
                            <div class="modal-body">
                                <div
                                    class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                                    <div class="w-100">
                                        <p>Are you sure you want to active this staff?</p>
                                        <div class="modal-btn-box">
                                            <button type="submit" class="btn btn-success mr-2">Yes</button>
                                            <button type="button" class="btn btn-danger"
                                                data-dismiss="modal">No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>