
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">EDIT BANNER</h1>
            <hr style="background: black; opacity: 0.5;">
        </div>
        <div class="content-section">
            <!-- <div class="page_title_block" style="padding-left: 21%;padding-top: 7%;">
                <h1 class="page_title">Add Banner</h1>
            </div> -->
            <div class="order-view  setting-page">
                <div class="  mb40">
                    <form  [formGroup]="addBannerForm">
                        <div class="input-style mb20 mt20 ">
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-4 texy-left">Banner Title
                                    </label>
                                    <div class="col-md-1">
                                        <span>:</span>
                                    </div>
                                <div class="col-md-7 text-left">
                                    <div class="form-group ">
                                        <input type="text" class="form-control" placeholder="Enter banner title"
                                            formControlName="title" (keypress)="mainService.preventSpace($event)"
                                            maxlength="50">
                                        <div class="text-danger">
                                            <span
                                                *ngIf="addBannerForm.get('title').hasError('required') && (addBannerForm.get('title').dirty || addBannerForm.get('title').touched )">
                                                *Please enter banner title.
                                            </span>

                                        </div> 
                                    </div>
                                </div> 
                            </div>

                            <div class="form-group row view-label-align">
                              <label class="col-md-4 texy-left">Upload Image
                                  </label>
                                  <div class="col-md-1">
                                    <span>:</span>
                                </div>
                              <div class="col-md-4 text-left">
                                  <div class="user-profile hoverable ">
                                      <div class="image-boxs" >
                                          <img class="image-boxs" src={{imgSrc}} id="sub-admin-image" alt="">

                                  <label class="upload-label">
                                      <input type="file" formControlName="image" name="image"
                                          accept="image/jpg,image/jpeg,image/png" id="image"
                                          (change)="ValidateFileUpload($event)" maxlength="56">
                                     <!--  <em class="fa fa-pencil" style="color: #86559f!important;font-size: 12px"></em> -->
                                  </label>
                                      </div>
                                  </div>
                              </div>
                          </div>
                            <div class="form-group row align-items-baseline view-label-align" style="margin-top: 6%;">
                                <label class="col-md-4 texy-left">Activate
                                </label>
                                    <div class="col-md-1">
                                        <span>:</span>
                                    </div>
                                <div class="col-md-7 text-left">
                                    <div>

                                        <input type="checkbox"
                                            data-md-icheck
                                            formControlName = "isActive"  (click)="toggleVisibility($event)">

                                    </div>
                                </div>
                            </div>

                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-4 texy-left ">Description
                                </label>
                                <div class="col-md-1">
                                    <span>:</span>
                                </div>
                            </div>

                            <div class="form-group row  view-label-align">

                                <div class="col-md-12 text-left">
                                    <div class="form-group " *ngIf="this.addBannerForm.value.description">
                                        <!-- <ck-editor formControlName="description"></ck-editor> -->
                                        <textarea name="" id="" class="form-control" formControlName="description" cols="30" rows="10"></textarea>
                                        <div
                                          *ngIf="addBannerForm.get('description').invalid && (addBannerForm.get('description').dirty || addBannerForm.get('description').touched)"
                                          class="text-danger">
                                          <span *ngIf="addBannerForm.get('description').hasError('required')">
                                            *Please enter description.</span>
                                        </div>
                                      </div>
                                </div>
                            </div>
                        </div>

                        
                        <div class="mt40 mb40 text-center">
                            <button class="btn btn-large  max-WT-150 btn-secondary" routerLink="/dispute-management">Cancel</button>
                            <button class="btn btn-large  max-WT-150 btn-primary ml-2"
                            [disabled]="!addBannerForm.valid" (click)="updateBanner()">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </main>

 
