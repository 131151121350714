<div class="wrapper">
    <!-- Aside End -->
    <main class="middle-content">
        <!-- Page Title Start -->
        <div class="page_title_block">
            <h1 class="page_title">P2P Exchange</h1>
  
        </div>
        <!-- Page Title End -->
        <div class="content-section">
            <div class="outer-box">
                <div class="custom_tabs common-tabs">
  
                    <div class="tab-content">
                        <div [formGroup]="transferForm" class="tab-pane active show" id="btc"  >
                            <div class="tab-pane1 global-table">
                                <div class="sec_head_new">
                                    <div class="row">
                 
                                        <div class="col-md-2 col-xl-2 col-lg-4">
                                            <div class="filter_fields">
                                                <div class="head_flt_select">
                                                    <div class="head_flt_select input-label input-label1">
                                                        <span class="d-flex align-items-center">Order Type</span>
                                                        <select  formControlName="type" class="form-control" >
                                                            <option [selected]="true" value="Select" >Select</option>
                                                            <option value="BUY">BUY</option>
                                                            <option value="SELL">SELL</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-md-3 col-xl-2 col-lg-4 pr-0">
                                            <div class="filter_fields">
                                                <div class="head_flt_select">
                                                    <div class="head_flt_select input-label input-label1">
                                                        <span class="d-flex align-items-center">From Date</span>
                                                        <input formControlName="fromDate" [max]="service.getToday()" type="date" name="" class="form-control datepicker" placeholder="Search by date">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2 col-xl-2 col-lg-4 pr-0">
                                            <div class="filter_fields">
                                                <div class="head_flt_select">
                                                    <div class="head_flt_select input-label input-label1">
                                                        <span class="d-flex align-items-center">To Date</span>
                                                        <input formControlName="toDate" [min]="this.transferForm.controls['fromDate'].value" type="date" name="" class="form-control datepicker" placeholder="Search by date">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-xl-2 col-lg-4"  style="display: flex; margin-top: 18px;margin-left:10% ;" >
  
  
                                              <div class="text-left">
                                                  <div class="head_flt_select input-label input-label1">
                                                  <span class="d-flex align-items-center"></span>
                                                  <button type="submit" class="btn  btn-theme" (click)="search()">Search</button>
  
                                                  </div>
                                              </div>
                                            <div class="text-left">
                                              <span class="d-flex align-items-center"></span>
                                                <button type="submit" class="btn  btn-theme" (click)="reset()">Reset</button>
  
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="table-responsive">
                                    <table class="table table-bordered" aria-describedby="">
                                        <thead>
                                            <tr class="no_wrap_th">
                                                <th id="">S.No.</th>
                                                <th id="">User Name</th>
                                                <th id="">User Email</th>
                                                <th id="">Quatity</th>
                                                <th id="">Total Amount</th>
                                                <th id="">Order Side</th>
                                                <th id="">Order Status </th>
                                                <th id="">Order Type</th>
                                                <th id="">Payment Method</th>
                                                <th id="">Payment Status</th>
                                                <th id="">Created Date & Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let data of p2pArray | paginate:{itemsPerPage: itemsPerPage, currentPage: currentPage,totalItems: count}; let i=index;">
                                              <td>{{itemsPerPage * (currentPage - 1) + i+1}}</td>
                                                <td>{{data?.userName || 'N/A'}}</td>
                                                <td>{{data?.userEmail || 'N/A'}}</td>
                                                <td>{{data?.quatity || 'N/A'}}</td>
                                                <td >  {{data?.totalAmount}}
  </td>
                                                <td>{{data?.orderSide || 'N/A'}}</td>
                                                <td>{{data?.orderStatus || 'N/A'}}</td>
                                                <td>{{data?.orderType || 'N/A'}}</td>
                                                <td>{{data?.paymentMethod || 'N/A'}}</td>
                                                <td>{{data?.paymentStatus || 'N/A'}}</td>
                                                <td>{{data?.createTime | date : 'medium' || 'N/A'}}</td>
                                            </tr>
                                            <tr *ngIf="count==0">
                                                  <td colspan="11" > No record found</td>
                                              </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="custom-pagination mt20 text-center" style="float: right;"  >
                                      <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                                  </div>
                            </div>
                        </div>
  
                    </div>
                </div>
            </div>
            <!-- Table Responsive End -->
        </div>
    </main>
    <!-- Middle Content End -->
  </div>
  <!-- Wrapper End -->
