
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">VIEW BANNER</h1>
            <hr style="background: black; opacity: 0.5;">
        </div>
        <div class="content-section">
            <div class="order-view  setting-page text-center">
                <div class="  mb40">
                    <form>
                        <div class="input-style mb20 mt20 ">
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-4 text-left ">Banner Title
                                    </label>
                                    <div class="col-md-1">
                                        <span>:</span>
                                    </div>
                                    
                                <div class="col-md-7 text-left">
                                    <div class="form-group">

                                        <label style="font-weight: 400;">{{bannerData?.title}}</label>
                                    </div>
                                </div>
                            </div>


                            <div class="form-group row view-label-align">
                                <label class="col-md-4 text-left ">Banner Image
                                   </label>
                                    <div class="col-md-1">
                                        <span>:</span>
                                    </div>
                                <div class="col-md-7 text-left">
                                    <div class="form-group">
                                        <label for="fileInput" class="hoverable">
                                            <img [src]="bannerData?.image"
                                            alt="" style="
                                                width : 280px" />



                                        </label>


                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline view-label-align">
                              <label class="col-md-4 text-left ">Banner Status
                                 </label>
                                 <div class="col-md-1">
                                    <span>:</span>
                                </div>
                              <div class="col-md-7 text-left">
                                  <div class="form-group">

                                      <label style="font-weight: 400;">{{bannerData?.bannerStatus ? 'Active' : 'Deactive'}}</label>
                                  </div>
                              </div>
                          </div>
                            <div class="form-group row  view-label-align">
                                <label class="col-md-4 text-left">Description 
                                    </label>
                                    <div class="col-md-1">
                                        <span>:</span>
                                    </div>
                                <div class="col-md-7 text-left">
                                    <div class="form-group " [innerHTML]="description">
                                        <!-- <label style="font-weight: 400;">{{bannerData?.description}}</label> -->

                                    </div>
                                </div>
                            </div>
                            <!-- <div class="form-group row  view-label-align">
                                <div class="col-md-12">
                                    <div class="form-group " style="font-size: 13px;" [innerHTML]="description">
                                </div>
                            </div> -->
                        </div>

                        <div class="text-center mt40 mb40">
                            <button class="btn btn-large  max-WT-150 btn-secondary"
                                routerLink="/dispute-management">Back</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </main>



