<main class="middle-content">
    <!-- Page Title Start -->
    <div class="page_title_block">
        <h1 class="page_title">Bank Management</h1>

    </div>
    <!-- Page Title End -->
    <div class="content-section" [formGroup]="staffForm">
        <div class="outer-box">
            <div class="custom_tabs common-tabs">

                <div class="tab-content">
                    <div class="tab-pane active show" id="btc">
                        <div class="tab-pane1">
                            <div class="row trassction-mainbox">
                                <div class="col-md-3 col-lg-2">
                                    <div class="head_flt_select">
                                        <div class="head_flt_select input-label input-label1">
                                            <span class="d-flex align-items-center">Status</span>
                                            <select class="form-control" formControlName="status">
                                                <option [selected]='true' value="All">ALL</option>
                                                <option value="APPROVED">APPROVED</option>
                                                <option value="REJECTED">REJECTED</option>
                                                <option value="PENDING">PENDING</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-lg-2 date-padding">
                                    <div class="head_flt_select">
                                        <div class="head_flt_select input-label input-label1">
                                            <span class="d-flex align-items-center">From Date</span>
                                            <input type="date" max="{{minAge | date:'yyyy-MM-dd'}}"
                                                formControlName="fromDate" name="formdate"
                                                class="form-control datepicker" placeholder="Search by date">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-lg-2 date-padding">
                                    <div class="head_flt_select">
                                        <div class="head_flt_select input-label input-label1">
                                            <span class="d-flex align-items-center">To Date</span>
                                            <input type="date" name="todate" min="{{fromDate | date:'yyyy-MM-dd'}}"
                                                formControlName="toDate" class="form-control datepicker"
                                                [min]="this.staffForm.controls['fromDate'].value"
                                                placeholder="Search by date">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-6">
                                    <div class="tras-threebtn">
                                        <input type="text" placeholder="Search by bank name/customer name"
                                            class="form-control" formControlName="search">
                                        <div class="comman-btn">
                                            <button type="submit" class="btn  btn-theme"
                                                (click)="search()">Search</button>
                                        </div>
                                        <div class="comman-btn">
                                            <button type="submit" class="btn  btn-theme"
                                                (click)="reset()">Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-bordered" aria-describedby="">
                                    <thead>
                                        <tr class="no_wrap_th">
                                            <th id="">S.No.</th>
                                            <th id="">Bank Name</th>
                                            <th id="">Customer Name</th>
                                            <th id="">Created Date & Time</th>
                                            <th id="">Created By</th>
                                            <th id="">Updated At</th>
                                            <th id="">Status</th>
                                            <th id="">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let data of staffList | paginate:{itemsPerPage: 10, currentPage: pageNumber,totalItems: staffLength}; let i=index; ">
                                            <td>{{10 * (pageNumber - 1) + i+1}}</td>
                                            <td>{{data?.bankName || 'N/A'}}</td>
                                            <td>{{data?.accountHolderName || 'N/A'}}</td>
                                            <td>{{data?.creationTime | date:"medium"}}</td>
                                            <td>{{data?.accountHolderName || 'N/A'}}</td>
                                            <td>{{data?.updationTime | date:"medium"}}</td>
                                            <td>{{data?.bankStatus || 'N/A'}}</td>
                                            <td class="action_td_btn3">
                                                <div *ngIf="data?.bankStatus == 'PENDING'">
                                                    <button class="btn btn-theme pointer" 
                                                        *ngIf="data?.bankStatus == 'PENDING'"
                                                        (click)="openModal('ACTIVE', data?.bankDetailId)">Approve</button>
                                                </div>
                                                <div *ngIf="data?.bankStatus == 'APPROVED' || 'REJECTED'">
                                                    <button class="btn btn-theme pointer" 
                                                        *ngIf="data?.bankStatus == 'REJECTED'"
                                                        (click)="openModal('ACTIVE', data?.bankDetailId)">Approve</button>
                                                    <button class="btn btn-theme pointer" 
                                                        *ngIf="data?.bankStatus == 'APPROVED'"
                                                        (click)="openModal('BLOCK', data?.bankDetailId)">Reject</button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="staffList?.length==0">
                                            <td colspan="8" vertical-align="middle" style="    text-align: center;">
                                                <div class="no-record">
                                                    <div class="no-recordin">
                                                        <H5>No record found</H5>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            <div class="custom-pagination mt20 text-center" style="float: right;">

                                <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- Table Responsive End -->
    </div>
</main>

<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h5 style="text-align: center; ">Remove Staff </h5>

                        <div class="modal-inner-content">
                            <div class="modal-body">
                                <div
                                    class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                                    <div class="w-100">
                                        <p>Are you sure you want to delete this staff?</p>
                                        <div>
                                            <button type="submit" class="btn btn-info mr-2"
                                                (click)="deleteFunction()">Yes</button>
                                            <button type="button" class="btn btn-danger"
                                                data-dismiss="modal">No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- delete_modal End -->
<!-- block_modal Start -->
<div class="modal fade global-modal reset-modal" id="block">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h5 style="text-align: center; ">Reject Bank </h5>
                        <div class="modal-inner-content">
                            <div class="modal-body">
                                <div
                                    class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                                    <div class="w-100">
                                        <p>Are you sure you want to reject this bank?</p>
                                        <div>
                                            <button type="submit" class="btn btn-success mr-2"
                                                (click)="performAction()">Yes</button>
                                            <button type="button" class="btn btn-danger"
                                                data-dismiss="modal">No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- active Start -->
<div class="modal fade global-modal reset-modal" id="active">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h5 style="text-align: center; ">Approve Bank</h5>

                        <div class="modal-inner-content">
                            <div class="modal-body">
                                <div
                                    class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                                    <div class="w-100">
                                        <p>Are you sure you want to approve this bank?</p>
                                        <div>
                                            <button type="submit" class="btn btn-success mr-2"
                                                (click)="performActionActive()">Yes</button>
                                            <button type="button" class="btn btn-danger"
                                                data-dismiss="modal">No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </form>
    </div>
</div>