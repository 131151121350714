<main class="middle-content" style="margin-top:-3%;">
    <!-- Page Title Start -->
    <div class="page_title_block">
        <h1 class="page_title">Edit Sub Admin</h1>
    </div>
    <!-- Page Title End -->

    <div class="content-section" [formGroup]="editStaffForm">
        <div class="outer-box">
            <div class="custom_tabs common-tabs">
                <div class="tab-content">
                    <div class="staff row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label class="col-md-4">First Name </label>
                                <label class="col-md-1"> :</label>
                                <div class="col-md-7">
                                    <input class="form-control" type="text" formControlName="firstName" maxlength="15"
                                        placeholder="Please enter firstname" (keypress)="service.preventSpace($event)">
                                    <div class="text-danger"
                                        *ngIf="editStaffForm.get('firstName').invalid && (editStaffForm.get('firstName').dirty  || editStaffForm.get('firstName').touched)">
                                        <span *ngIf="editStaffForm.get('firstName').hasError('required')">*First name is
                                            required.</span>
                                        <span *ngIf="editStaffForm.get('firstName').hasError('pattern')">*Please enter
                                            valid name.</span>
                                    </div>
                                </div>

                            </div>
                            <div class="form-group row">
                                <label class="col-md-4">Last Name </label>
                                <label class="col-md-1"> :</label>
                                <div class="col-md-7">
                                    <input class="form-control" type="text" formControlName="lastName" maxlength="15"
                                        placeholder="Please enter lastname" (keypress)="service.preventSpace($event)">
                                    <div class="text-danger"
                                        *ngIf="editStaffForm.get('lastName').invalid && (editStaffForm.get('lastName').dirty  || editStaffForm.get('lastName').touched)">
                                        <span *ngIf="editStaffForm.get('lastName').hasError('required')">*Last name is
                                            required.</span>
                                        <span *ngIf="editStaffForm.get('lastName').hasError('pattern')">*Please enter
                                            valid name.</span>
                                    </div>
                                </div>

                            </div>
                            <div class="form-group row">
                                <label class="col-md-4">Email </label>
                                <label class="col-md-1"> :</label>
                                <div class="col-md-7">
                                    <input class="form-control" type="text" formControlName="email"
                                        (keypress)="service.preventSpace($event)" readonly />
                                    <div class="text-danger"
                                        *ngIf="editStaffForm.get('email').invalid && (editStaffForm.get('email').dirty  || editStaffForm.get('email').touched)">
                                        <span *ngIf="editStaffForm.get('email').hasError('required')">*Email is
                                            required.</span>
                                        <span *ngIf="editStaffForm.get('email').hasError('pattern')">*Please enter
                                            valid email.</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label class="col-md-4">Mobile Number </label>
                                <label class="col-md-1"> :</label>
                                <div class="col-md-7">
                                    <input maxlength="10" class="form-control" type="text"
                                        placeholder="Please enter email" formControlName="phoneNumber"
                                        (keypress)="service.preventSpace($event)">
                                    <div class="text-danger"
                                        *ngIf="editStaffForm.get('phoneNumber').invalid && (editStaffForm.get('phoneNumber').dirty  || editStaffForm.get('phoneNumber').touched)">
                                        <span *ngIf="editStaffForm.get('phoneNumber').hasError('required')">*Number is
                                            required.</span>
                                        <span *ngIf="editStaffForm.get('phoneNumber').hasError('pattern')">*Please enter
                                            valid number.</span>
                                    </div>
                                </div>

                            </div>
                            <div class="form-group row">
                                <label for="GNDR" class="col-md-4">Gender </label>
                                <label class="col-md-1"> :</label>
                                <div class="col-md-7">
                                    <select class="form-control" id="GNDR" formControlName="gender">
                                        <option value="">Gender</option>
                                        <option value="MALE">Male</option>
                                        <option value="FEMALE">Female</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="ROLE" class="col-md-4">Role </label>
                                <label class="col-md-1"> :</label>
                                <div class="col-md-7">
                                    <select id="ROLE" class="form-control" formControlName="role">
                                        <option value="">Options</option>
                                        <option value="SUBADMIN">Sub Admin</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="text-center" style="margin-top: 3%;">
                        <h1 class="page_title">Permissions</h1>
                    </div>

                    <div class="row" style="margin-top: 3%;">

                        <div class="col-md-4" *ngFor="let item of permissionArr ; let i = index">
                            <label class="globalCheckBox" style="text-transform:capitalize;"> {{name[i] | lowercase}}
                                <input type="checkbox" [(ngModel)]="permArray[i]" [ngModelOptions]="{standalone: true}"
                                    (click)="checkPerm(item,!permArray[i])">
                                <span class="checkmark"></span>
                            </label>
                        </div>

                    </div>
                    <br>
                    <div style="text-align: center; margin-bottom: 2%;">

                        <button class="btn  btn-theme" type="button" [disabled]="editStaffForm.invalid"
                            (click)="editStaff()">Submit</button>
                        <button class="btn  btn-theme" type="button" routerLink="/staff-management">Back</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Table Responsive End -->
    </div>
</main>