import { Component, OnInit } from "@angular/core";
import { ServiceService } from "../service.service";

@Component({
  selector: "app-qte-price-management",
  templateUrl: "./qte-price-management.component.html",
  styleUrls: ["./qte-price-management.component.css"],
})
export class QtePriceMAnagementComponent implements OnInit {
  priceDeatil: any;
  updatedPrice: any
  isAdd: string = "+"

  connection: any;
  myInterval: any;
  qtePrice: any;
  pricePercentage: any;

  percentRegx = new RegExp(
    /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,7})?$)/
  );
  isValid: boolean = false;
  isSet: boolean = false
  price: any = 0;

  constructor(public service: ServiceService) {
    /** Listener for web socket connection */
    var self = this;
    service.connectionQte.subscribe(connection => {
      this.connection = connection;
      if (this.connection == 'online') {
        this.manageSubscribeAndUnsubscribeFunc(1);
      }
    });
    this.service.wsQTEPrice.addEventListener('message', function (event) {
      console.log(event)
      if (!event.data.includes('symbol not supported')) {
        let data = JSON.parse(event.data);
        // console.log(data)
        self.qtePrice = data.data
      }
    });
  }

  ngOnInit(): void {
    this.getQtePrice()
  }

  // ger qte price
  getQtePrice() {
    let url = "wallet/admin/view-setup-price-by-id?priceId=1"
    this.service.showSpinner()
    this.service.get(url).subscribe((res) => {
      // console.log(res);
      if (res["status"] == 200) {
        this.priceDeatil = res["data"]
        this.updatedPrice = res['data'].qtePrice
        this.pricePercentage = res['data'].setupPercentage
        this.service.hideSpinner()
        // this.service.toasterSucc(res["message"])

      } else {
        this.service.hideSpinner()
        this.service.toasterSucc(res["message"])
        // this.isSet = true
      }
    })
  }


  // checkInput() {
  //   if (this.percentRegx.test(this.price)) {
  //     if(this.isAdd == "+"){
  //       this.updatedPrice = this.priceDeatil.qtePrice + this.priceDeatil.qtePrice*(this.price/100)
  //     }
  //     else{

  //       this.updatedPrice = this.priceDeatil.qtePrice - this.priceDeatil.qtePrice*(this.price/100)
  //     }
  //    this.updatedPrice = (this.updatedPrice).toFixed(5)
  //    console.log("=-=-=-=-=-=-=-=-=-=-=-",this.updatedPrice);

  //     this.isValid = false;
  //   } else {
  //     this.isValid = true;
  //   }
  // }

  checkInput() {
    if (this.percentRegx.test(this.pricePercentage)) {
      this.isValid = false;
    } else {
      this.isValid = true;
    }
  }

  // set qte price
  setQtePrice() {
    const data = {
      "qtePrice": this.updatedPrice,
      "setupPercentage": this.pricePercentage
    }
    // console.log(data)
    let url = "wallet/admin/setup-price"
    this.service.showSpinner()
    this.manageSubscribeAndUnsubscribeFunc(2)
    this.service.post(url, data).subscribe((res) => {
      // console.log(res);
      if (res["status"] == 200) {
        this.getQtePrice()
        this.manageSubscribeAndUnsubscribeFunc(1)
        this.service.hideSpinner()
        this.service.toasterSucc(res["message"])

      } else {
        this.service.hideSpinner()
        this.service.toasterSucc(res["message"])
      }
    })
  }


  // calculatePercent(){
  //   this.price  = (this.updatedPrice/this.priceDeatil.qtePrice - 1)*100
  //   if(this.price < 0 ){
  //     this.isAdd = "-"
  //     this.price = this.price* (-1)
  //   }
  //   else{
  //     this.isAdd = "+"
  //   }
  //   this.price = this.price.toFixed(5)
  // }


  // set_update(){
  //   if (this.isSet) {
  //     this.setQtePrice()
  //   } else {
  //     this.updateQtePrice()
  //   }
  // }
  // updateQtePrice(){
  //   let url = "wallet/admin/update-setup-price"
  //   const data = {


  //       "priceId": 1,
  //       "qtePrice": this.updatedPrice,
  //       "setupPercentage": this.pricePercentage

  //   }
  //   this.service.showSpinner()
  //   this.service.post(url,data).subscribe((res)=>{
  //     console.log(res);
  //     if (res["status"] == 200) {
  //       this.getQtePrice()
  //       this.service.hideSpinner()
  //       this.service.toasterSucc(res["message"])

  //     } else {
  //       this.service.hideSpinner()
  //       this.service.toasterSucc(res["message"])
  //     }

  //   })
  // }
  
  // update(val) {
  //   console.log(val);

  //   console.log(this.percentRegx.test(val));
  // }

  // incrementDecrement() {
  //   if (this.isAdd == "+") {
  //     this.isAdd = "-"
  //   }
  //   else {
  //     this.isAdd = "+"
  //   }
  //   this.checkInput()
  // }

  // 

  // Manage Socket Subscribe And Unsubscribe Functionality 
  manageSubscribeAndUnsubscribeFunc(mode) {
    let data = {
      "messageType": "",
      "params": {
        "symbol": ""
      }
    }
    switch (mode) {
      case 1:
        /** Code for subscribe ticker data */
        if (this.service.wsQTEPrice.readyState) {
          data.messageType = "SUBSCRIBE_QTE_PRICE";
          data.params.symbol = 'QTE_PRICE';
          this.service.wsQTEPrice.send(JSON.stringify(data));
          return;
        } else {
          this.myInterval = setInterval(x => {
            this.manageSubscribeAndUnsubscribeFunc(1);
          }, 10000);
        }
        break;
      case 2:
        /** Code for unsubscribe ticker data */
        if (this.service.wsQTEPrice.readyState) {
          data.messageType = "UNSUBSCRIBE_QTE_PRICE";
          data.params.symbol = 'QTE_PRICE';
          this.service.wsQTEPrice.send(JSON.stringify(data));
          break;
        } else {
          console.log("vshfbd")
          this.myInterval = setInterval(x => {
            this.manageSubscribeAndUnsubscribeFunc(2);
          }, 10000);
        }
    }
  }

  ngOnDestroy() {
    this.manageSubscribeAndUnsubscribeFunc(2)
  }

}

