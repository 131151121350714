<main class="middle-content" style="margin-top:-3%;">
    <!-- Page Title Start -->
    <div class="page_title_block">
        <h1 class="page_title">Escrow Management</h1>
    </div>
    <!-- Page Title End -->
    <div class="content-section">
        <div class="outer-box">
            <!-- Gloabl Table Box Start -->
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">
                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th id="">Random ID</th>
                                        <th id="">Coin</th>
                                        <th id="">Amount</th>
                                        <th id="">Status</th>
                                        <th id="">Created Date & Time</th>
                                        <th class="action_td_btn3" id="">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let data of escrowList | paginate:{itemsPerPage: pageSize, currentPage: pageNumber,totalItems:totalItems}; let i=index; ">
                                        <td>{{data?.toRandomId || 'N/A'}}</td>
                                        <td>{{data?.baseCoin || 'N/A'}}</td>
                                        <td>{{data?.amount || 'N/A'}}</td>
                                        <td>{{data?.user1Status || 'N/A'}}</td>
                                        <td>{{(data?.creationTime | date:"medium") || "---"}}</td>
                                        <td><button *ngIf="data?.user1Status=='DISPUTED'" type="submit"
                                                class="btn  btn-green mr-2"
                                                (click)="release(data.userToAdminTransferId)">Release
                                            </button>
                                            <button *ngIf="data?.user1Status=='DISPUTED'" type="submit"
                                                class="btn  btn-green"
                                                (click)="cancel(data.userToAdminTransferId)">Cancel
                                            </button></td>
                                    </tr>
                                    <tr *ngIf="escrowList == ''">
                                        <td colspan="8"> No escrow found</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="custom-pagination mt20 text-right" *ngIf="totalItems > 10 ">
                            <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Table Responsive End -->
</main>
<!-- Middle Content End -->
<!-- Wrapper End -->


<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="ReleaseModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h5 style="text-align: center; ">Release Coin </h5>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to release coin?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2"
                                        (click)="releaseCoins()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- delete_modal End -->
<!-- block_modal Start -->
<div class="modal fade global-modal reset-modal" id="CancelModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h5 style="text-align: center; ">Return Coin </h5>
                        <div class="modal-inner-content">
                            <div class="modal-body">
                                <div
                                    class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                                    <div class="w-100">
                                        <p>Are you sure you want to return coin to the user in wallet?</p>
                                        <div>
                                            <button type="submit" class="btn btn-success mr-2"
                                                (click)="returnCoins()">Yes</button>
                                            <button type="button" class="btn btn-danger"
                                                data-dismiss="modal">No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- active Start -->
<div class="modal fade global-modal reset-modal" id="active">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h5 style="text-align: center; ">Active User </h5>
                        <div class="modal-inner-content">
                            <div class="modal-body">
                                <div
                                    class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                                    <div class="w-100">
                                        <p>Are you sure you want to active this user?</p>
                                        <div>
                                            <button type="submit" class="btn btn-success mr-2"
                                           >Yes</button>
                                            <button type="button" class="btn btn-danger"
                                                data-dismiss="modal">No</button>
                                                <!-- <p>(click)="performAction()"</p> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>