import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
declare var $:any
@Component({
  selector: 'app-escrow-management',
  templateUrl: './escrow-management.component.html',
  styleUrls: ['./escrow-management.component.css']
})
export class EscrowManagementComponent implements OnInit {
  escrowList: any=[];
  pageNumber: number = 1;
  pageSize:number=10
  constructor(private router: Router, public service: ServiceService) { }

  ngOnInit() {
    this.getEscrowList()
  }

  totalItems:any
  // Get List of User
  getEscrowList() {
    var url = "wallet/admin/get-escrow-list?page=" +(this.pageNumber-1)+ '&pageSize=' + this.pageSize;

    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {
      //console.log("UserLIST", res)
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.escrowList = res['data']['list']['content'];
        this.totalItems = res['data']['count']
        // this.totalItems = res.data.totalCount;
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
disputedId:any
cancelId:any
  release(id){
    $('#ReleaseModal').modal('show')
    this.disputedId = id
  }
  cancel(id){
    $('#CancelModal').modal('show')
    this.cancelId = id
  }

  //return coins in wallet of user1
  returnCoins(){
    this.service.get('wallet/wallet/request-cancel-ESCROW?userToAdminTransferId='+this.cancelId).subscribe(res=>{
      if(res['status']==200){
        this.service.toasterSucc(res['message'])
        $('#CancelModal').modal('hide')
        this.getEscrowList()

      }else{
         this.service.toasterErr(res['message'])
      }
    })
  }

// release coins to user2
  releaseCoins(){
  var url = "wallet/admin/approvedBy-user1?referenceId=" +this.disputedId;

    this.service.showSpinner();
    this.service.post(url,1).subscribe((res: any) => {
      //console.log("UserLIST", res)
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.service.toasterSucc(res['message'])
        $('#ReleaseModal').modal('hide')
        this.getEscrowList()
        //this.escrowList = res['data']['list']['content'];
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  getProfile() {
    this.service.showSpinner();
    let dataa = {
      "primaryIdCommonPerRequest": 1
    }
    this.service.post('account/admin/user-management/get-staff-user-profile', dataa).subscribe((res) => {
      if(res["status"] == 561){
      
        this.service.hideSpinner()
        this.service.toasterSucc(res["message"])
      }
      else{
       
        this.service.hideSpinner()
        this.service.toasterSucc(res["message"])
      }
    })
  }
}
