import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-add-institutional',
  templateUrl: './add-institutional.component.html',
  styleUrls: ['./add-institutional.component.css']
})
export class AddInstitutionalComponent implements OnInit {
  addAdvertisementForm: FormGroup;
  imgSrc : any 
  imageUrl: any;
  marked = false;
  constructor(public mainService: ServiceService, private router: Router) { }

  ngOnInit(): void {
    this.addAdvertisementForm = new FormGroup({
      'title': new FormControl('', [Validators.required]),
      'image' : new FormControl(''),
      // "description": new FormControl('', Validators.required),
      "isActive" : new FormControl(true)
    });
  }
  addPromotion(){
    if(this.imgSrc == "assets/loader/1488 (2).gif"){
      this.mainService.toasterWarn("Please wait while promotion image is uploading.")
      return
    }
    let status = ""
    if (this.addAdvertisementForm.value.isActive) {
      status = "ACTIVE"
    } else {
      status = "DISACTIVE"
    }
    let url = "static/admin/save-promotion"
    const data = {
      "description": '',

      "image": this.imgSrc,

      "title": this.addAdvertisementForm.value.title,
      "promotionStatus" : status
    }
    console.log("isActive",data.promotionStatus);

    this.mainService.showSpinner()
      this.mainService.postApi(url,data).subscribe((res)=>{
        if(res.status == 200){
          this.mainService.hideSpinner()
          this.mainService.toasterSucc(res.message)
          this.router.navigate(['/promotion-list'])
        }
        else{
          this.mainService.hideSpinner()
          this.mainService.toasterErr(res.message)
        }
      })
    }


  ValidateFileUpload(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
     this.imageUrl =  file
    }
    console.log(this.imageUrl);

    this.uploadProfilePic()
  }
  uploadProfilePic(){
    let url = "static/upload-file"
    this.imgSrc = "assets/loader/1488 (2).gif"
    const formData = new FormData();
    formData.append('file', this.imageUrl)
    this.mainService.showSpinner();
    this.mainService.postApi('account/upload-file', formData).subscribe(res => {
      // this.mainService.hideSpinner();
      if (res['status'] == '200') {
        this.imgSrc = res['data'];
        this.mainService.hideSpinner();
      }
    }, err => {
      this.mainService.hideSpinner();
      if (err['status'] == '401') {
        this.mainService.onLogout();
        this.mainService.toasterErr('Unauthorized Access');
      } else {
        this.mainService.toasterErr('Something Went Wrong');
      }
    })

  }

  toggleVisibility(e) {
    this.marked = e.target.checked;
  } 
}